export function waitUntil(
    cond: () => boolean,
    refreshRate = 10,
): Promise<void> {
    return new Promise((res) => {
        const id = setInterval(() => {
            if (cond()) {
                res();
                clearInterval(id);
            }
        }, refreshRate);
    });
}
