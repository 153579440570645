import { Injectable } from "@angular/core";
import { ApiService } from "../shared/api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Lexicon, LexiconGrapheme } from "@models/lexicon/lexicon.model";
import { ResponseFromApi } from "@models/textToSpeech/textToSpeech.model";

@Injectable({
  providedIn: "root",
})
export class LexiconService {
  constructor(private api: ApiService, private http: HttpClient) {}

  getAll(): Observable<Lexicon[]> {
    return this.api.get("lexicon/getAll");
  }

  add(lexicon: Lexicon): Observable<boolean> {
    return this.api.post("lexicon/add", lexicon);
  }

  update(lexicon: Lexicon): Observable<boolean> {
    return this.api.post("lexicon/update", lexicon);
  }

  delete(lexicon: Lexicon): Observable<boolean> {
    return this.api.post("lexicon/delete", lexicon);
  }

  getDetails(id: number): Observable<Lexicon> {
    return this.api.get("lexicon/getDetails?id=" + id);
  }

  search(key: string): Observable<Lexicon[]> {
    return this.api.get("lexicon/search?key=" + key);
  }

  addOrUpdateGrapheme(
    lexiconGrapheme: LexiconGrapheme
  ): Observable<ResponseFromApi> {
    return this.api.post("lexicon/addOrUpdateGrapheme", lexiconGrapheme);
  }
}
