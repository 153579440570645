export interface ListItemAction {
    label: string;
    icon: string;
    disabled?: boolean;
    callback?: Function;
    warning?: boolean;
    delete?: boolean;
    src?: string;
}

export interface Pagination {
    from: number;
    pageSize: number;
    startIndex: number;
    endIndex: number;
    isFirstDisabled: boolean;
    isBackDisabled: boolean;
    isNextDisabled: boolean;
    isEndDisabled: boolean;
}

export enum PaginationButton {
    First = 'first',
    Back = 'back',
    Next = 'next',
    Last = 'last',
}
