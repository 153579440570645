import {
  Component,
  OnInit,
  DoCheck,
  Inject,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Asset } from "~/models/asset/asset.model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fromEvent } from "rxjs";
import { map, takeWhile } from "rxjs/operators";

export class DialogConfirmData {
  title: string;
  assets: Asset[];
  confirmLabel: string;
  cancelLabel: string;
}
@Component({
  selector: "app-dialog-confirm-cancel-delete",
  templateUrl: "./dialog-confirm-cancel-delete.component.html",
  styleUrls: ["./dialog-confirm-cancel-delete.component.scss"],
})
export class DialogConfirmCancelDeleteComponent implements OnInit, DoCheck {
  public scrolledToTheEnd = false;
  public noScroll = true;
  public name = "dialog-confirm-cancel-delete";
  public scrollHeight = 0;
  public height = 0;
  @ViewChild("componentHeight", { static: true })
  componentHeight: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmCancelDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData
  ) {}

  ngOnInit() {
    fromEvent(this.componentHeight.nativeElement, "scroll")
      .pipe(
        map(({ target }) => this.calculateScrollPercentage(target)),
        takeWhile((value) => value < 100)
      )
      .subscribe((value) => {
        value > 80
          ? (this.scrolledToTheEnd = true)
          : (this.scrolledToTheEnd = false);
      });
  }

  ngDoCheck() {
    this.scrollHeight = this.componentHeight.nativeElement.scrollHeight;
    this.height = this.componentHeight.nativeElement.offsetHeight;
    this.scrollHeight <= this.height
      ? (this.noScroll = false)
      : (this.noScroll = true);
  }

  public calculateScrollPercentage({
    scrollTop,
    scrollHeight,
    clientHeight,
  }: Element) {
    return (scrollTop / (scrollHeight - clientHeight)) * 100;
  }

  get assets(): Asset[] {
    return [...this.data.assets];
  }
}
