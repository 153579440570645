import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { FormPageService } from "~/app/base/formPage.service";
import { PropertyView } from "./propertyView.model";

@Component({
  selector: "property-display",
  templateUrl: "./property-display.component.html",
  styleUrls: ["./property-display.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyDisplayComponent {
  @Input() prop: PropertyView;
  @Input() format?: string;
  @Output() action = new EventEmitter<string>();
  @Input() labelClosingInTranslation = false;
  @Input() boldFontLabel = true;
  @Output() contextmenuNavigate = new EventEmitter<string>();

  constructor(
    protected formPageService: FormPageService,
    protected router: Router
  ) {}

  public propagateAction() {
    if (this.prop.options.action) {
      this.action.next(this.prop.options.action);
    }
  }

  public get dateFormat() {
    return (this.format && this.format) || "YYYY-MM-DD";
  }

  public getValueFromLabelKey(): string {
    return this.prop.labelKey;
  }

  public get dateTimeFormat() {
    return (this.format && this.format) || "YYYY-MM-DD - HH:mm";
  }

  public navigate(url: string, backlabel?: string) {
    this.formPageService.setAutoRestore(true);
    this.router.navigate([url, backlabel ? { backLabel: backlabel } : {}]);
  }

  public navigateInNewTab(event: MouseEvent, url: string) {
    if (url) {
      event.preventDefault();
      this.contextmenuNavigate.emit(url);
    }
  }
}
