import { Injectable } from "@angular/core";
import { ApiService } from "../shared/api.service";
import { Observable } from "rxjs";
import { User } from "@models/user/user.model";
import { Client, ProjectTemplate } from "@models/template/template.model";
import { Team } from "@models/team/team.model";
import { ResponseFromApi } from "@models/textToSpeech/textToSpeech.model";
import { Tenant } from "@models/tenant/tenant.model";

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  constructor(private api: ApiService) {}

  public getUsers(): Observable<User[]> {
    return this.api.get("user/getAll");
  }

  public getUsersToNotify(): Observable<User[]> {
    return this.api.get("user/getNotificationList");
  }

  public getUserById(userId: number): Observable<User> {
    return this.api.get("user/getById?userId=" + userId);
  }

  public addNewUser(user: User) {
    user.host = document.location.origin;
    return this.api.post("user/add", user);
  }

  public updateUser(user: User): Observable<User> {
    return this.api.post("user/update", user);
  }

  public deleteUser(userId: number): Observable<any> {
    return this.api.get("user/deleteById?userId=" + userId);
  }

  public getCurrentUserType(): Observable<boolean> {
    return this.api.get("user/isLoggedInUserAdmin");
  }

  // template section
  public getAllTemplates(): Observable<ProjectTemplate[]> {
    return this.api.get("template/getAll");
  }

  public getTemplateById(id: number): Observable<ProjectTemplate> {
    return this.api.get("template/getById/" + id);
  }

  public addTemplate(template: ProjectTemplate): Observable<ResponseFromApi> {
    return this.api.post("template/create", template);
  }

  public deleteTemplate(id: number): Observable<void> {
    return this.api.delete("template/delete/" + id);
  }

  public updateTemplate(
    template: ProjectTemplate
  ): Observable<ResponseFromApi> {
    return this.api.post("template/update", template);
  }

  public getClients(): Observable<Client[]> {
    return this.api.get("template/getClients");
  }

  // Teams section

  public getTeams(): Observable<Team[]> {
    return this.api.get("team/getAll");
  }

  public getTeamsByTenant(): Observable<Team[]> {
    return this.api.get("team/getAllByTenant");
  }

  public getTeamById(id: number): Observable<Team> {
    return this.api.get(`team/getById/${id}`);
  }

  public createTeam(team: Team): Observable<ResponseFromApi> {
    return this.api.post(`team/create`, team);
  }

  public updateTeam(team: Team): Observable<ResponseFromApi> {
    return this.api.post(`team/update`, team);
  }

  public deleteTeam(id: number): Observable<ResponseFromApi> {
    return this.api.get(`team/delete/${id}`);
  }

  public getTeamForProjects(): Observable<Team[]> {
    return this.api.get(`team/getForProjects`);
  }

  // Tenants section

  public getTenants(): Observable<Tenant[]> {
    return this.api.get("tenant/getAll");
  }

  public getTenantsByUser(id: number): Observable<Tenant[]> {
    return this.api.get(`tenant/getByUserId/${id}`);
  }

  public getTenantById(id: string): Observable<Tenant> {
    return this.api.get(`tenant/getById/${id}`);
  }

  public createTenant(tenant: Tenant): Observable<ResponseFromApi> {
    return this.api.post(`tenant/create`, tenant);
  }

  public updateTenant(tenant: Tenant): Observable<ResponseFromApi> {
    return this.api.post(`tenant/update`, tenant);
  }

  public deleteTenant(id: string): Observable<ResponseFromApi> {
    return this.api.get(`tenant/delete/${id}`);
  }

  // Session section
  public updateSessionTenantId(tenantId: string) {
    return this.api.post(`user/updateSessionTenantId`, tenantId);
  }
}
