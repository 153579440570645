import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment-timezone";

export class DialogConfirmData {
  title: string;
  message: string;
  secondMessage?: string;
  confirmLabel: string;
  cancelLabel?: string;
  backLabel?: string;
  replacement?: string | string[];

  /**
   * Time before auto reject, in seconds
   */
  autoRejectTimeout?: number;
}

@Component({
  selector: "shared-dialog-confirm",
  templateUrl: "./dialog-confirm.component.html",
  styleUrls: ["./dialog-confirm.component.scss"],
})
export class DialogConfirmComponent implements OnInit {
  interval: number;
  timeoutTime: moment.Moment;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData
  ) {}

  ngOnInit() {
    if (this.data.autoRejectTimeout) {
      this.startAutoRejectTimeout();
    }
  }

  startAutoRejectTimeout() {
    this.timeoutTime = moment().add(this.data.autoRejectTimeout, "seconds");

    // Each second, we check against the timeout time for closing the popup and decrement the displayed seconds.
    this.interval = window.setInterval(() => {
      if (this.timeoutTime.isBefore()) {
        this.dialogRef.close();
      } else {
        this.data.autoRejectTimeout--;
        this.data.replacement = [this.data.autoRejectTimeout.toString()];
      }
    }, 1000);

    this.dialogRef.beforeClosed().subscribe(() => {
      clearInterval(this.interval);
    });
  }
}
