import { Injectable } from "@angular/core";
import { ApiService } from "~/app/shared/api.service";
import {
  AzureVoice,
  CreateAndStartProduction,
  ResponseFromApi,
} from "~/models/textToSpeech/textToSpeech.model";
import { Observable } from "rxjs";
import { ProjectsDto } from "@models/textToSpeech/project.model";

@Injectable({
  providedIn: "root",
})
export class TextToSpeechService {
  constructor(private api: ApiService) {}

  public convertTtmlIntoOutputAudioFile(
    projectsDto: ProjectsDto
  ): Observable<ResponseFromApi> {
    return this.api.post("textToSpeech/ttml/convertIntoAudioFile", projectsDto);
  }

  public getVoicesByLanguageAndRegion(
    languageRegion: string
  ): Observable<AzureVoice[]> {
    return this.api.get(
      "textToSpeech/getVoicesByLanguageAndRegion?languageRegion=" +
        languageRegion
    );
  }

  public generateVdFileAndProcessMix(
    projectDto: ProjectsDto
  ): Observable<string> {
    return this.api.post(
      "textToSpeech/generateVdFileAndProcessMix",
      projectDto
    );
  }

  public generateVdFile(projectDto: ProjectsDto): Observable<string> {
    return this.api.post("textToSpeech/generateVdFile", projectDto);
  }

  public startMix(
    createAndStartProduction: CreateAndStartProduction
  ): Observable<string> {
    return this.api.post(
      "textToSpeech/createAndStartProduction",
      createAndStartProduction
    );
  }
}
