import { Injectable } from "@angular/core";
import { Project } from "@models/textToSpeech/project.model";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { WebSocketService } from "../shared/websocket.service";

@Injectable({
  providedIn: "root",
})
export class ConnectWebSocketService {
  public socketMessages: Subject<Project | string>;
  public userSocketMessages: Subject<Project | string>;
  constructor(private webSocketService: WebSocketService) {}

  // Connect to websocket service.
  public connect(initialData?: any) {
    this.socketMessages = <Subject<Project>>(
      this.webSocketService.connect("project", initialData).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  // Connect to websocket service.
  public connectToUserSocket(initialData?: any) {
    this.userSocketMessages = <Subject<Project>>(
      this.webSocketService.connect("user", initialData).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  public sendUserMessage(project: Project) {
    this.userSocketMessages.next(project);
  }
}
