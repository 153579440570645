import { Observable, Subject } from "rxjs";
import * as io from "socket.io-client";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class WebSocketService {
  private socket: SocketIOClient.Socket;

  constructor() {}

  connect(socketNamespace: string, initialData: any = {}): Subject<string> {
    const serverUrl = environment.phoenixSocketUrl + socketNamespace; // While testing in local change phoenixSocketUrl to 'http://localhost:3000/' in environment.local.ts file.
    initialData.reconnect = true;
    initialData.transports = ["websocket", "polling"];
    this.socket = io(serverUrl, initialData);
    // Observable which will observe any incoming messages from our socket.io server
    const observable = new Observable((currentObserver) => {
      this.socket.on("message", (data: string) => {
        currentObserver.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });

    // Observer which will listen to messages from our other components
    // and send messages back to the socket server whenever the `next()` method is called
    const observer = {
      next: (data: any) => {
        this.socket.emit("message", JSON.stringify(data));
      },
    };

    // Return our Rx.Subject which is a combination of both an observer and observable.
    return Subject.create(observer, observable);
  }
}
