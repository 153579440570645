import { Component } from "@angular/core";
import { AuthenticationService } from "../../authentication.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-logout-confirm-dialog",
  templateUrl: "./logout-confirm-dialog.component.html",
  styleUrls: ["./logout-confirm-dialog.component.scss"],
})
export class LogoutConfirmDialogComponent {
  constructor(
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<LogoutConfirmDialogComponent>
  ) {}

  noClick(): void {
    this.dialogRef.close();
  }

  logout(): void {
    this.authService.logout();
    this.noClick();
  }
}
