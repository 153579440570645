import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment-timezone";

@Pipe({
  name: "moment",
})
export class MomentPipe implements PipeTransform {
  transform(value: any, format: string = ""): string {
    // Try and parse the passed value.
    const momentDate = moment.utc(value).local(); // workaround for moment(value), which raise error in unit tests

    // If moment didn't understand the value, return it unformatted.
    if (!momentDate.isValid()) {
      return value;
    }

    // Otherwise, return the date formatted as requested.
    return momentDate.format(format);
  }
}
