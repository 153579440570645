import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-suite-case",
  templateUrl: "./suite-case.component.html",
  styleUrls: ["./suite-case.component.scss"],
})
export class SuiteCaseComponent implements OnInit {
  // The total item count. Required for pagination component.
  public responseResults: number | undefined = undefined;
  // The default index number.
  public defaultIndexFrom = 0;
  // The start index of the items. Required for pagination component.
  public indexFrom = 0;
  // Holds the items per page dropdown value.
  public defaultPerPageOptionsValue = 25;
  // Items per page dropdown list.
  public resultsPerPageOptions = [25, 50, 100, 200];
  // Holds the current page data.
  public currentPageRecords: any[] = [];
  // Required to scroll to the id #titleResults
  @ViewChild("titleResults", { static: true })
  protected titleResults: ElementRef;

  constructor() {}

  ngOnInit() {}
}
