import { FormControl, Validators } from "@angular/forms";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "dialog-confirm-share-project",
  templateUrl: "./dialog-confirm-share-project.component.html",
  styleUrls: ["./dialog-confirm-share-project.component.scss"],
})
export class DialogConfirmShareProjectComponent implements OnInit {
  defaultValue: string;
  dataSource: any[] = [];
  form = new FormControl("", Validators.required);
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmShareProjectComponent>,
    protected router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dataSource = this.data?.list || [];
    this.defaultValue = this.data?.defaultValue || null;
    this.form.setValue(this.defaultValue);
  }

  share() {
    this.dialogRef.close({
      value: this.form.value,
    });
  }

  public clearPreSelection(): void {
    this.data.componentRef.unAssignGroupedTask();
  }
}
