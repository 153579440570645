export interface ForgetPasswordRequest {
    email: string;
    host: string;
}

export interface TokenInformation {
    token: string;
    email: string;
    expiration: number;
    userId: string;
}

export interface TokenInvitation {
    id: string;
    startDate: Date;
}

/**
 * Credential of type user/password
 */
export interface PasswordCredential {
    email: string;
    passwordHash: string;
    salt: string;
    wrongPasswordCount: number;
    /**
     * Time of the last password reset
     */
    passwordResetTime?: Date;
    creationTime?: Date;
    lastSignIn?: Date;
    tokenInvitationId?: string;
    tokenInvitationStartDate?: Date;
}

export enum InvalidLoginCause {
    InvalidRequest = 'invalid_request',
    InvalidClient = 'invalid_client',
    InvalidGrant = 'invalid_grant',
    InvalidScope = 'invalid_scope',
    UnauthorizedClient = 'unauthorized_client',
    UnsupportedGrantType = 'unsupported_grant_type'
}

export interface LoginToken {
    username: string;
    password: string;
}

export interface AuthenticationResponse {
    access_token: string;
    token_type: string; // The type of token this is, typically just the string “bearer”.
    expire_in?: number; // (recommended but no use for now) If the access token expires, the server should reply with the duration of time the access token is granted for.
    scope?: string; // (optional) If the scope the user granted is identical to the scope the app requested, this parameter is optional.
                    // If the granted scope is different from the requested scope, such as if the user modified the scope, then this parameter is required.
    days_until_password_expiration: number;
    username: string;
    userId?: number;
    tenantId?: string;
}

export interface AuthenticationErrorResponse {
    error: InvalidLoginCause;
    error_description: string;
    error_uri: string;
    remaining_attempts?: number;
    days_until_password_expiration: number;
    no_tenant?: boolean;
}

export interface LoginValidity {
    isSuccess: boolean;
    response: AuthenticationResponse | AuthenticationErrorResponse;
}

/**
 * Backend only data, the UI should add a call to /me endpoints to retrieve user information
 * The session object should contain all required information to avoid additional database call for user/tenant information.
 */
export interface SessionData {
    id: string;
    creationTime: Date;
    /**
     * Expiration time, represented in milliseconds for DynamoDB
     */
    expirationTime: number;
    userId: number; // Must stay first level to be able to create index.
    email: string;
    fullName: string;
    /**
     * Flat list of permissions based on roles
     */
    permissions: string[];
    isSuperAdmin: boolean;
    isRepresentative: boolean;
    /**
     * Exception in addition of clientId, allowing additional filtering of assets.
     */
    contentOwnerId?: string;
    tenantId?: string;
}

export interface SignUpInfosSummary {
    email: string;
    fullName: string;
}

export interface SignUpInfos extends SignUpInfosSummary {
    password: string;
}

export interface SubscriptionInfos extends SignUpInfosSummary {
    tokenInvitation: TokenInvitation;
}
