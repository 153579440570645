import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ConnectionService, ConnectionState } from "ng-connection-service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  public customIcons: { [key: string]: string } = {
    action_play: "assets/icons/icon_action_play.svg",
    delete: "assets/icons/icon_action_delete.svg",
    cancel: "assets/icons/icon_action_cancel.svg",
    download: "assets/icons/icon_action_download.svg",
    duplicate: "assets/icons/icon_action_duplicate.svg",
    details_page: "assets/icons/icon_action_info.svg",
    plus: "assets/icons/icon_action_plus.svg",
    opened_briefcase: "assets/icons/sharp-business_center-OPEN-24px.svg",
    remove: "assets/icons/icon_action_minus.svg",
    arrow_left: "assets/icons/icon_arrow_left.svg",
    arrow_right: "assets/icons/icon_arrow_right.svg",
    edit: "assets/icons/icon_action_edit.svg",
    warning: "assets/icons/icon_warning.svg",
    greyWarning: "assets/icons/icon_action_grey_warning.svg",
    center_focus_weak: "assets/icons/center_focus_weak.svg",
    center_focus_strong: "assets/icons/center_focus_strong.svg",
    fullscreen: "assets/icons/icon_fullscreen.svg",
    keyboard_voice: "assets/icons/keyboard_voice.svg",
    play_recording: "assets/icons/play_recording.svg",
    arrow_down: "assets/icons/arrow_down.svg",
    arrow_up: "assets/icons/arrow_up.svg",
    group_recording: "assets/icons/group_recording.svg",
    pause: "assets/icons/pause.svg",
    mic: "assets/icons/icon_mic.svg",
    mic_filled: "assets/icons/icon_mic_filled.svg",
    play: "assets/icons/icon_play.svg",
    playList: "assets/icons/icon_playlist.svg",
    play_black: "assets/icons/icon_action_play_black.svg",
    video_title: "assets/icons/icon_video_title.svg",
    video_title_right: "assets/icons/icon_video_title_right.svg",
    calendar: "assets/icons/icon_action_calendar.svg",
    play_circle: "assets/icons/play_circle.svg",
    filled_left_arrow: "assets/icons/filled_left_arrow.svg",
    filled_right_arrow: "assets/icons/filled_right_arrow.svg",
    noaction_alert: "assets/icons/icon-noaction-alert.svg",
    auto_saved: "assets/icons/cloud.svg",
    redo: "assets/icons/redo.svg",
    undo: "assets/icons/undo.svg",
    move_up: "assets/icons/move_up.svg",
    move_down: "assets/icons/move_down.svg",
    find_replace: "assets/icons/find_replace.svg",
    more_time: "assets/icons/icon_more_time.svg",
    take_original: "assets/icons/icon_take_original.svg",
    take_alternate: "assets/icons/icon_take_alternate.svg",
    scissor: "assets/icons/content_cut.svg",
    check_mark: "assets/icons/check_mark.svg",
    xml_file: "assets/icons/xml-file.svg",
  };
  isConnectedToInternet: ConnectionState;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private connectionService: ConnectionService,
    private translateService: TranslateService
  ) {
    this.initCustomAppIcons();
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnectedToInternet = isConnected;
      if (!this.isConnectedToInternet) {
        alert(this.translateService.instant("connectionLost"));
      } else {
        // alert(this.translateService.instant("connectionEstablished"));
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {}

  private initCustomAppIcons() {
    Object.keys(this.customIcons).forEach((key) => {
      // Injection of svg in Material
      this.iconRegistry.addSvgIcon(
        key,
        this.sanitizer.bypassSecurityTrustResourceUrl(this.customIcons[key])
      );
    });
  }
}
