import { Injectable } from "@angular/core";
import { ApiService } from "../shared/api.service";
import {
  LoginToken,
  AuthenticationResponse,
  ForgetPasswordRequest,
} from "@models/account/account.model";
import { Observable, of, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { User } from "@models/user/user.model";
import { TokenNotFound } from "@models/shared/errors/TokenNotFound";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(private api: ApiService, private http: HttpClient) {}

  signIn(email: string, password: string): Observable<AuthenticationResponse> {
    const loginToken: LoginToken = {
      password: password,
      username: email,
    };

    return this.api.guestPost("account/token", loginToken);
  }

  /**
   * request a reset password to the Phoenix api (this will send email to the user if exists)
   * @param email user email
   * @returns Observable should return true if the user exist
   */

  requestResetPassword(email: string): Observable<boolean> {
    return this.api.guestPost("resetPasswordToken", <ForgetPasswordRequest>{
      email,
      host: document.location.origin,
    });
  }

  getTokenInformation(token: string): Observable<User | null> {
    return this.api
      .guestGet(`resetPasswordToken/${token}`)
      .pipe(
        catchError((err: any) =>
          err.error.code === TokenNotFound.codeConst
            ? of(null)
            : throwError(err)
        )
      );
  }

  validateToken(token: string): Observable<boolean> {
    return this.getTokenInformation(token).pipe(
      map((user) => {
        if (user) {
          return true;
        }
        return false;
      })
    );
  }

  resetPassword(password: string, token: string): Observable<any> {
    return this.api.guestPut(password, token);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<void> {
    return this.api.post("account/changePassword", {
      oldPassword,
      newPassword,
    });
  }

  public getMicrophoneDeviceId(): Observable<any> {
    return this.api.get("user/getMicrophoneDeviceId");
  }

  public updateMicrophoneDeviceId(deviceId: string): Observable<void> {
    return this.api.put("user/updateMicrophoneDeviceId", { deviceId });
  }
}
