import { Asset } from "@models/asset/asset.model";
import {
  AssetView,
  PropertyCard,
} from "~/app/shared/components/propertiesCard/assetView.model";
import {
  PropertyView,
  DisplayType,
} from "~/app/shared/components/property-display/propertyView.model";

export abstract class AbstractAssetViewService {
  protected constructor() {}

  protected abstract headGroup(asset: Asset): PropertyView[];

  protected abstract cards(asset: Asset): PropertyCard[];

  public buildAssetView(asset: Asset): AssetView {
    return {
      headLine: asset.title,
      headGroup: this.headGroup(asset),
      cards: this.cards(asset),
    };
  }

  public abstract onAction(asset: Asset, action: string): void;
}
