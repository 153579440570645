// webpack-translate-loader.ts
import { TranslateLoader } from "@ngx-translate/core";
import { Observable, from, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

export class WebpackTranslateLoader implements TranslateLoader {
  constructor(private module: string | null = null) {}

  getTranslation(lang: string): Observable<any> {
    const sources = [
      from(import(`./i18n/${lang}.json`)),
      from(import(`./i18n/values.${lang}.json`)),
      from(import(`./i18n/account/${lang}.json`)),
      from(import(`./i18n/textToSpeech/${lang}.json`)),
      from(import(`./i18n/management/${lang}.json`)),
      from(import(`./i18n/asset/${lang}.json`)),
    ];

    return combineLatest(...sources).pipe(
      map((parts) => {
        // We merge all translation parts (global, module, values) together.
        const reduced = parts.reduce(
          (combined, part) => ({ ...combined, ...part }),
          {}
        );

        function jsonTextReplace(objJson: any, pattern: RegExp) {
          for (const key in objJson) {
            if (typeof objJson[key] === "string") {
              objJson[key] = objJson[key].replace(pattern, "");
            } else {
              jsonTextReplace(objJson[key], pattern);
            }
          }
        }

        const patt = /\s?((\[fr\])+|(\[en\])+)\s?/gi;
        jsonTextReplace(reduced, patt); // Removing all "[FR]" or "[EN]" that indicate needs of translation review in json file

        return reduced;
      })
    );
  }
}
