import { Injectable } from "@angular/core";
import * as Mousetrap from "mousetrap";
import { Observable, Subject } from "rxjs";
import { ApiService } from "./api.service";
import {
  Action,
  ComputerType,
  Module,
  ShortcutAction,
  ShortcutCategorie,
} from "@models/shortcut/shortcut.model";
import { UserHelperService } from "./userHelper.service";

@Injectable({
  providedIn: "root",
})
export class ShortcutService {
  private shortcutsSubject = new Subject<string>();
  private userShortcuts: { [categorie: string]: { [key: string]: string } } =
    {};

  get shortcuts$(): Observable<string> {
    return this.shortcutsSubject.asObservable();
  }

  constructor(private api: ApiService) {
    this.loadUserShortcuts();
    this.setupUserShortcuts();
  }

  public setupUserShortcuts(): void {
    if (Object.keys(this.userShortcuts).length !== 0) {
      Object.keys(this.userShortcuts).forEach((categorie) => {
        Object.keys(this.userShortcuts[categorie]).forEach((key) => {
          Mousetrap.bind(key, (event) => {
            event.preventDefault();
            this.triggerShortcut(this.userShortcuts[categorie][key]);
          });
        });
      });
    }
  }

  public setupEventsShortcuts(): void {
    if (Object.keys(this.userShortcuts).length !== 0) {
      Object.keys(this.userShortcuts).forEach((categorie) => {
        Object.keys(this.userShortcuts[categorie]).forEach((key) => {
          if (
            this.userShortcuts[categorie][key] === Action.InTimecode ||
            this.userShortcuts[categorie][key] === Action.OutTimecode
          ) {
            Mousetrap.bind(key, (event) => {
              event.preventDefault();
              this.triggerShortcut(this.userShortcuts[categorie][key]);
            });
          }
        });
      });
    }
  }

  public destroyShortcutsListener(): void {
    Mousetrap.reset();
  }

  public loadUserShortcuts(): void {
    const storedShortcuts = localStorage.getItem("userShortcuts");
    if (storedShortcuts) {
      this.userShortcuts = JSON.parse(storedShortcuts);
    }
  }

  private triggerShortcut(shortcut: string): void {
    this.shortcutsSubject.next(shortcut);
  }

  setUserShortcut(
    key: string,
    action: ShortcutAction,
    modude: string
  ): Observable<any> {
    if (key.includes("arrow")) {
      key = key.replace("arrow", "");
    }
    if (action.categorie === ShortcutCategorie.VideoPlayer) {
      this.setShortcutCategorie(ShortcutCategorie.VideoPlayer, action, key);
    } else if (modude === Module.Writing) {
      this.setShortcutCategorie(Module.Writing, action, key);
    } else if (modude === Module.Recording) {
      this.setShortcutCategorie(Module.Recording, action, key);
    }

    Mousetrap.unbind(key);
    Mousetrap.bind(key, (event) => {
      event.preventDefault();
      this.triggerShortcut(action.action);
    });

    const body = {
      actionId: action.id,
      userId: UserHelperService.getUserIdFromLocalStorage(),
      computerType: ComputerType.PC,
      shortcutKeys: key,
    };
    return this.api.post("shortcut/upsert/", body);
  }

  bindShortcutInOut() {
    if (
      this.userShortcuts[Module.Writing] &&
      Object.keys(this.userShortcuts[Module.Writing]).length !== 0
    ) {
      Object.keys(this.userShortcuts[Module.Writing]).forEach((key) => {
        if (this.userShortcuts[Module.Writing][key] === Action.OutTimecode) {
          Mousetrap.bind(key, (event) => {
            event.preventDefault();
            this.triggerShortcut(Action.OutTimecode);
          });
        }

        if (this.userShortcuts[Module.Writing][key] === Action.InTimecode) {
          Mousetrap.bind(key, (event) => {
            event.preventDefault();
            this.triggerShortcut(Action.InTimecode);
          });
        }
      });
    }
  }

  setShortcutCategorie(
    categorie: string,
    action: ShortcutAction,
    keys: string
  ) {
    if (
      this.userShortcuts[categorie] &&
      Object.keys(this.userShortcuts[categorie]).length !== 0
    ) {
      Object.keys(this.userShortcuts[categorie]).forEach((key) => {
        if (this.userShortcuts[categorie][key] === action.action) {
          delete this.userShortcuts[categorie][key];
        }
      });
      Object.keys(this.userShortcuts).forEach((key) => {
        if (this.userShortcuts[key] && this.userShortcuts[key][keys]) {
          delete this.userShortcuts[key][keys];
        }
      });
      this.userShortcuts[categorie][keys] = action.action;
    } else {
      this.userShortcuts[categorie] = { [keys]: action.action };
    }

    localStorage.setItem("userShortcuts", JSON.stringify(this.userShortcuts));
  }

  public getAllActionsByModule(module: string): Observable<any[]> {
    return this.api.get(`shortcut/getAllActionsByModule/${module}`);
  }

  public getAllActionsByUser(): Observable<any[]> {
    return this.api.get(`shortcut/getAllActionsByUser`);
  }
}
