import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

/**
 * Allow replacement of {n} placeholder with values
 */
@Pipe({
  name: "time",
})
export class TimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(duration: number): string {
    if (isNaN(duration)) {
      return "NaN";
    }
    if (!duration) {
      return "00:00:00";
    }

    duration = duration * 1000;

    return this.datePipe.transform(duration, "HH:mm:ss", "-0000");
  }
}
