import { Pipe, PipeTransform } from "@angular/core";
import { I18nPluralPipe } from "@angular/common";

/**
 * Reverse the native i18nPluralPipe for used with our translation system
 */
@Pipe({
  name: "pluralize",
})
export class PluralizePipe implements PipeTransform {
  constructor(private i18nPluralPipe: I18nPluralPipe) {}

  transform(mapping: { [k: string]: string }, count: number): string {
    if (mapping && typeof mapping === "string") {
      return mapping;
    } else if (mapping && (count || count === 0)) {
      return this.i18nPluralPipe.transform(count, mapping);
    }
    return "";
  }
}
