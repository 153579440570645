/// <reference path='../../../typings.d.ts' />
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AuthenticationService } from "../../shared/authentication.service";
import { environment } from "../../../environments/environment";
import { AccountService } from "../account.service";
import {
  AuthenticationResponse,
  AuthenticationErrorResponse,
  InvalidLoginCause,
} from "../../../models/account/account.model";
import { DialogConfirmComponent } from "../../shared/components/dialog-confirm/dialog-confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { UserHelperService } from "~/app/shared/userHelper.service";
import { ManagementService } from "~/app/management/management.service";
import { ShortcutService } from "~/app/shared/shortcut.service";
import {
  UserShortcut,
  Module,
  ShortcutCategorie,
} from "@models/shortcut/shortcut.model";

@Component({
  selector: "account-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public remainingAttempts: number;
  public showExpireWarning = false;
  public daysUntilExpiration: number | undefined;
  public disableLogin = false;
  public autoFilled = false;
  public badEmail = false;
  public currentUser: string;
  public isAdmin = false;
  private key = "session";

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    private account: AccountService,
    public dialog: MatDialog,
    private userHelperService: UserHelperService,
    private userService: ManagementService,
    private keyboardShortcutService: ShortcutService,
    private route: ActivatedRoute
  ) {
    this.userHelperService.sharedUserName.subscribe(
      (username) => (this.currentUser = username)
    );
    this.userHelperService.sharedUserType.subscribe(
      (isAdmin) => (this.isAdmin = isAdmin)
    );
  }

  login() {
    this.disableLogin = true;
    const email = this.formGroup.get("email").value;
    const password = this.formGroup.get("password").value;
    this.account.signIn(email, password).subscribe(
      (answer: AuthenticationResponse) => {
        this.daysUntilExpiration = answer.days_until_password_expiration;
        if (answer.access_token) {
          this.userHelperService.setUserName(answer.username);
          this.auth.storeAuthenticationResponse(answer);
          this.userService.getCurrentUserType().subscribe((data) => {
            if (data) {
              this.isAdmin = true;
            } else {
              this.isAdmin = false;
            }
            this.userHelperService.setUserType(this.isAdmin);
          });
          if (
            this.daysUntilExpiration <
            environment.account.daysBeforeExpirationForWarning
          ) {
            this.auth.validateSessionOrRedirect().subscribe(() => {
              this.showExpireWarning = true;
            });
          } else {
            this.keyboardShortcutService
              .getAllActionsByUser()
              .subscribe((actions: UserShortcut[]) => {
                const userShortcuts = actions.reduce((acc: any, cur: any) => {
                  if (
                    cur.shortcutAction.categorie ===
                    ShortcutCategorie.VideoPlayer
                  ) {
                    if (!acc.videoPlayer) {
                      acc.videoPlayer = {};
                    }
                    acc.videoPlayer[cur.shortcutKeys] =
                      cur.shortcutAction.action;
                  } else if (
                    cur.shortcutAction.module === Module.Writing ||
                    cur.shortcutAction.module === Module.Any
                  ) {
                    if (!acc.Writing) {
                      acc.Writing = {};
                    }
                    acc.Writing[cur.shortcutKeys] = cur.shortcutAction.action;
                  } else if (
                    cur.shortcutAction.module === Module.Recording ||
                    cur.shortcutAction.module === Module.Any
                  ) {
                    if (!acc.Recording) {
                      acc.Recording = {};
                    }
                    acc.Recording[cur.shortcutKeys] = cur.shortcutAction.action;
                  }
                  return acc;
                }, {});
                localStorage.setItem(
                  "userShortcuts",
                  JSON.stringify(userShortcuts)
                );
              });
            this.userHelperService.setTenantId(answer.tenantId);
            const returnUrl =
              this.route.snapshot.queryParams["backUrl"] || "/projects";
            this.router.navigateByUrl(returnUrl);
          }
        }
      },
      (error) => {
        const authError: AuthenticationErrorResponse = error.error;
        this.badEmail = false;
        this.disableLogin = false;
        if (authError.remaining_attempts) {
          // Invalid password
          this.remainingAttempts = authError.remaining_attempts;
        } else if (authError.remaining_attempts === 0) {
          // Blocked
          this.processBlockedAccount();
        } else if (authError.days_until_password_expiration === 0) {
          // Password expired
          this.router.navigateByUrl("/account/expiredPassword");
        } else if (authError.error === InvalidLoginCause.InvalidGrant) {
          // email doesn't exists
          this.badEmail = true;
        }
      }
    );
  }

  private processBlockedAccount(): void {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: "600px",
      height: "260px",
    });
    dialogRef.componentInstance.data = {
      message: "signIn.dialogBoxLocked.message",
      title: "signIn.dialogBoxLocked.title",
      confirmLabel: "signIn.dialogBoxLocked.confirm",
    };
  }

  passwordKeyDown(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  ngOnInit(): void {
    this.isAdmin = false;
    this.userHelperService.setUserType(this.isAdmin);
    this.formGroup = this.formBuilder.group({
      email: ["", Validators.compose([Validators.email, Validators.required])],
      password: ["", Validators.required],
    });

    this.formGroup.valueChanges.subscribe(($event) => {
      this.manageAutofill($event);
    });

    const token = localStorage.getItem(this.key);
    if (!!token) {
      this.auth.logout();
    }
  }

  public manageAutofill($event: FormValueChangesEvent): void {
    if (
      $event.isAutofilled &&
      this.formGroup.pristine &&
      this.formGroup.untouched
    ) {
      this.autoFilled = true;
      return;
    }
    this.autoFilled = false;
  }
}
