import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment-timezone";
import { DialogChangePasswordComponent } from "../dialog-change-password/dialog-change-password.component";
import { Router } from "@angular/router";
// import { NavigationService } from '~/app/shared/navigation.service';

@Component({
  selector: "account-password-expire-warning",
  templateUrl: "./password-expire-warning.component.html",
  styleUrls: ["./password-expire-warning.component.scss"],
})
export class PasswordExpireWarningComponent implements OnInit {
  @Input() daysUntilExpiration: number;

  constructor(
    private dialog: MatDialog,
    private router: Router // public navigation: NavigationService
  ) {}

  ngOnInit(): void {}

  goToChangePassword(): void {
    this.dialog.open(DialogChangePasswordComponent, {
      data: { title: "changePassword.title" },
    });
    // We don't wait for password change before redirecting to the application.
    this.router.navigateByUrl("/dashboard");
  }

  expireDate(): string {
    return moment(new Date())
      .add(this.daysUntilExpiration, "days")
      .format("YYYY-MM-DD");
  }
}
