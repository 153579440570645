import { FormPageService } from "./../base/formPage.service";
import { NgModule } from "@angular/core";

// translation specific
import {
  TranslateModule,
  TranslateLoader,
  TranslatePipe,
} from "@ngx-translate/core"; // https://github.com/ngx-translate/core
import { WebpackTranslateLoader } from "../../webpack-translate.loader";
import { ApiService } from "./api.service";
import { ToolsService } from "./tools.service";
import { TextToSpeechService } from "../textToSpeech/textToSpeech.service";
import { ProjectService } from "../textToSpeech/project.service";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { RouterModule } from "@angular/router";
import { CommonModule, DecimalPipe, DatePipe } from "@angular/common";
import { LogoutConfirmDialogComponent } from "./header/logout-confirm-dialog/logout-confirm-dialog.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { ReplyComponent } from "./components/reply/reply.component";
import { VideoJSComponent } from "./video-js/video-js.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FnxIconComponent } from "./components/fnx-icon/fnx-icon.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { WebSocketService } from "./websocket.service";
import { StackedSnackbarComponent } from "./components/stacked-snackbar/stacked-snackbar.component";
import { HighlightSearchPipe } from "./pipes/highlight-search.pipe";
import { FnxTabsComponent } from "./components/fnx-tabs/fnx-tabs.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { SuiteCaseComponent } from "./components/suite-case/suite-case.component";
import { KeysPipe } from "./pipes/keys.pipe";
import { AudioJsComponent } from "./components/audio-js/audio-js.component";
import { NewPaginationComponent } from "./components/new-pagination/new-pagination.component";
import { ReplacePipe } from "./pipes/replace.pipe";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { AssetListElementComponent } from "./components/asset-list-element/asset-list-element.component";
import { ActionListItemComponent } from "./components/action-list-item/action-list-item.component";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_FORMATS,
  OwlDateTimeIntl,
} from "ng-pick-datetime";
import { CUSTOM_FORMATS, CustomOwlDateTimeIntl } from "./ng-pick-datetime";
import { PluralizePipe } from "./pipes/pluralize.pipe";
import { ReplyEventComponent } from "./components/reply-event/reply-event.component";
import { TextMaskModule } from "angular2-text-mask";
import { AutosizeModule } from "ngx-autosize";
import { CatalogAssetSelectionService } from "./catalog-assets-selection.service";
import { CapitalizeFirstPipe } from "./pipes/capitalizeFirst.pipe";
import { PropertyDisplayComponent } from "./components/property-display/property-display.component";
import { PropertiesCardComponent } from "./components/propertiesCard/propertiesCard.component";
import { ValueTranslatePipe } from "./pipes/valueTranslate.pipe";
import { UnitPipe } from "./pipes/unit.pipe";
import { MomentPipe } from "./pipes/moment.pipe";
import { ShortcutsTabsComponent } from "./components/shortcuts-tabs/shortcuts-tabs.component";
import { MatTabsModule } from "@angular/material/tabs";
import { TimePipe } from "./pipes/time.pipe";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DialogConfirmShareProjectComponent } from "./components/dialog-confirm-share-project/dialog-confirm-share-project.component";
import { FnxSelectChipsComponent } from "./components/fnx-select-chips/fnx-select-chips.component";
import { MatSelectSearchComponent } from "./components/mat-select-search/mat-select-search.component";
import { MatDividerModule } from "@angular/material/divider";
import { MatChipsModule } from "@angular/material/chips";
import { VideoPlayerComponent } from "./components/video-player/video-player.component";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { DialogConfirmComponent } from "./components/dialog-confirm/dialog-confirm.component";
@NgModule({
  imports: [
    MatChipsModule,
    CommonModule,
    MatDialogModule,
    MatTooltipModule,
    MatToolbarModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    FontAwesomeModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: () => new WebpackTranslateLoader(),
      },
    }),
    FormsModule,
    MatProgressSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    TextMaskModule,
    AutosizeModule,
    MatTabsModule,
    DragDropModule,
    MatDividerModule,
  ],
  declarations: [
    // Pipes
    // Components
    // Directive
    HeaderComponent,
    FooterComponent,
    LogoutConfirmDialogComponent,
    SpinnerComponent,
    ReplyComponent,
    ReplyEventComponent,
    VideoJSComponent,
    FnxIconComponent,
    StackedSnackbarComponent,
    HighlightSearchPipe,
    FnxTabsComponent,
    PaginationComponent,
    SuiteCaseComponent,
    KeysPipe,
    AudioJsComponent,
    NewPaginationComponent,
    ReplacePipe,
    UploadFileComponent,
    AssetListElementComponent,
    ActionListItemComponent,
    VideoPlayerComponent,
    PluralizePipe,
    ReplyEventComponent,
    CapitalizeFirstPipe,
    PropertyDisplayComponent,
    PropertiesCardComponent,
    ValueTranslatePipe,
    UnitPipe,
    MomentPipe,
    ShortcutsTabsComponent,
    TimePipe,
    SafeHtmlPipe,
    FnxSelectChipsComponent,
    DialogConfirmShareProjectComponent,
    DialogConfirmComponent,
    MatSelectSearchComponent,
  ],
  exports: [
    // Pipes
    // Components
    // Directive
    TranslateModule,
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    ReplyComponent,
    ReplyEventComponent,
    VideoJSComponent,
    FnxIconComponent,
    StackedSnackbarComponent,
    FnxTabsComponent,
    PaginationComponent,
    NewPaginationComponent,
    KeysPipe,
    AudioJsComponent,
    ReplacePipe,
    UploadFileComponent,
    AssetListElementComponent,
    ActionListItemComponent,
    VideoPlayerComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PluralizePipe,
    CapitalizeFirstPipe,
    PropertyDisplayComponent,
    PropertiesCardComponent,
    ShortcutsTabsComponent,
    TimePipe,
    DragDropModule,
  ],
  providers: [
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: CUSTOM_FORMATS,
    },
    {
      provide: OwlDateTimeIntl,
      useClass: CustomOwlDateTimeIntl,
    },
    ToolsService,
    ApiService,
    TextToSpeechService,
    ProjectService,
    WebSocketService,
    TranslatePipe,
    HighlightSearchPipe,
    FormPageService,
    CatalogAssetSelectionService,
    CapitalizeFirstPipe,
    ValueTranslatePipe,
    UnitPipe,
    MomentPipe,
    DecimalPipe,
    DatePipe,
    SafeHtmlPipe,
  ],
  entryComponents: [DialogConfirmComponent],
})
export class SharedModule {}
