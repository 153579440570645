export enum IconType {
  svg, // custom svg icon added to Material in app.component.ts
  iTag, // Material Icons that only display in <i> instead of <matFilled-icon>
  matFilled = "material-icons", // Material Angular icon 'filled' version (default)
  matSharp = "material-icons-sharp", // Material Angular icon 'sharp" version
  matOutlined = "material-icons-outlined", // Material Angular icon 'outlined" version
  fab = "fab", // FortAwesome free-brands-svg-icons => added one-by-one in shared.module.ts
  fas = "fas", // FortAwesome free-regular-svg-icons => added one-by-one in shared.module.ts
}

export interface IconConfig {
  type: IconType;
  key: string;
  rotate?: number;
}

// We don't export it directly because this allow us to get the list of keys before we retype the object
const untypedIconsConfigs = {
  // Actions, generally blue and clickable
  delete: { key: "delete", type: IconType.svg },
  detailsPage: { key: "details_page", type: IconType.svg },
  play: { key: "action_play", type: IconType.svg },
  download: { key: "download", type: IconType.svg },
  cancel: { key: "cancel", type: IconType.svg },
  duplicate: { key: "duplicate", type: IconType.svg },
  plus: { key: "plus", type: IconType.svg },
  remove: { key: "remove", type: IconType.svg },
  gear: { key: "settings", type: IconType.matOutlined },
  share: { key: "group_add", type: IconType.matOutlined },
  delete2: { key: "delete", type: IconType.matOutlined },
  keyboard: { key: "keyboard", type: IconType.matOutlined },
  edit2: { key: "edit", type: IconType.matOutlined },
  script: { key: "description", type: IconType.matOutlined },
  record: { key: "record_voice_over", type: IconType.matOutlined },

  closeList: { key: "opened_briefcase", type: IconType.svg },
  openList: { key: "business_center", type: IconType.matFilled },
  advanceFilter: { key: "tune", type: IconType.matFilled },
  cancelSearch: { key: "cancel", type: IconType.matFilled },
  search: { key: "search", type: IconType.matFilled },

  mic: { key: "mic", type: IconType.matSharp },
  mutedMic: { key: "mic_off", type: IconType.matSharp },
  hearing: { key: "hearing", type: IconType.matSharp },

  focusWeak: { key: "center_focus_weak", type: IconType.svg },
  focusStrong: { key: "center_focus_strong", type: IconType.svg },
  fullscreen: { key: "fullscreen", type: IconType.svg },
  voicer: { key: "keyboard_voice", type: IconType.svg },
  playRecording: { key: "play_recording", type: IconType.svg },
  arrow_down: { key: "arrow_down", type: IconType.svg },
  arrow_up: { key: "arrow_up", type: IconType.svg },
  group_recording: { key: "group_recording", type: IconType.svg },
  pause: { key: "pause", type: IconType.svg },
  calendar: { key: "calendar", type: IconType.svg },
  noActionAlert: { key: "noaction_alert", type: IconType.svg },
  auto_saved: { key: "auto_saved", type: IconType.svg },

  redo: { key: "redo", type: IconType.svg },
  undo: { key: "undo", type: IconType.svg },
  move_up: { key: "move_up", type: IconType.svg },
  move_down: { key: "move_down", type: IconType.svg },
  find_replace: { key: "find_replace", type: IconType.svg },
  more_time: { key: "more_time", type: IconType.svg },
  cut_scissor: { key: "scissor", type: IconType.svg },
  check_mark: { key: "check_mark", type: IconType.svg },
  xml_file: { key: "xml_file", type: IconType.svg },

  // Browsers
  chrome: { key: "chrome", type: IconType.fab },
  edge: { key: "edge", type: IconType.fab },
  firefox: { key: "firefox", type: IconType.fab },
  ie: { key: "internet-explorer", type: IconType.fab },
  safari: { key: "safari", type: IconType.fab },

  arrowRight: { key: "arrow_right", type: IconType.svg },
  arrowLeft: { key: "arrow_left", type: IconType.svg },

  edit: { key: "edit", type: IconType.svg },
  warning: { key: "warning", type: IconType.svg },
  greyWarning: { key: "greyWarning", type: IconType.svg },

  video: { key: "videocam_sharp", type: IconType.matSharp },

  mic_black: { key: "mic", type: IconType.svg },
  mic_filled: { key: "mic_filled", type: IconType.svg },
  play_svg: { key: "play", type: IconType.svg },
  playList: { key: "playList", type: IconType.svg },
  play_black: { key: "play_black", type: IconType.svg },
  video_title: { key: "video_title", type: IconType.svg },
  video_title_right: { key: "video_title_right", type: IconType.svg },
  play_circle: { key: "play_circle", type: IconType.svg },
  filled_left_arrow: { key: "filled_left_arrow", type: IconType.svg },
  filled_right_arrow: { key: "filled_right_arrow", type: IconType.svg },
  take_original: { key: "take_original", type: IconType.svg },
  take_alternate: { key: "take_alternate", type: IconType.svg },
};

export type IconKey = keyof typeof untypedIconsConfigs;

export const iconsConfigs: { [key: string]: IconConfig } = untypedIconsConfigs;
