// import { Angulartics2 } from 'angulartics2';
import { Observable, ReplaySubject, of } from "rxjs";
import { tap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationResponse } from "@models/account/account.model";
import { environment } from "~/environments/environment";
import { HttpMethod } from "@models/enum/httpMethod";
import { UserHelperService } from "./userHelper.service";

/**
 * OAuth service.
 * It just check if the session is valid and provide the session token.
 * Offer quick access to tenantId, userId, admin status and session token. And that's all
 * In no way the service should call REST APIs.
 */
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private isAuth: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private key = "session";

  /**
   * Returns the access token used to do authenticated calls to APIs
   * If not available, trigger a redirection to the login page
   */
  public get accessToken(): string | null {
    const token = this.safeAccessToken;
    if (!token) {
      this.handleUnauthorized();
    }
    return token;
  }

  // Returns the access token. Will NOT redirect to the login page if not available
  public get safeAccessToken(): string | null {
    const session = this.authenticationResponse;
    if (!session || !session.access_token) {
      return null;
    }
    return session.access_token;
  }

  /**
   * Stream of authentication status.
   */
  public get isAuthenticated(): Observable<boolean> {
    return this.isAuth.asObservable();
  }

  constructor(
    private http: HttpClient,
    // private angulartics2: Angulartics2,
    private router: Router,
    private userHelperService: UserHelperService
  ) {
    this.validateSessionToken().subscribe((connected) => {
      this.isAuth.next(connected);
    });
  }

  /**
   * Calls the backend to check if the session is still valid.
   */
  public validateSessionOrRedirect(): Observable<boolean> {
    return this.validateSessionToken().pipe(
      tap((hasSession) => {
        if (!hasSession) {
          this.handleUnauthorized();
        }
      })
    );
  }

  public validateSessionToken(): Observable<boolean> {
    if (!this.authenticationResponse) {
      return of(false);
    }

    const options = {
      headers: new HttpHeaders(),
      body: this.authenticationResponse.access_token,
    };

    return this.http
      .request<boolean>(
        HttpMethod.Post,
        environment.phoenixApiUrl + "account/refreshToken",
        options
      )
      .pipe(
        catchError((e) => {
          return of(false);
        })
      );
  }

  /**
   * Clear data related to the session
   */
  private flush() {
    localStorage.removeItem(this.key);
    this.isAuth.next(false);
    this.userHelperService.setUserName(undefined);
  }

  /**
   * Signal to the authentication service that the current session is now invalid.
   * Contrarily to a user logout, this preserve the current URL
   */
  public handleUnauthorized() {
    this.flush();
    let currentUrl = document.location.pathname + document.location.search;
    if (currentUrl.indexOf("signIn") !== -1) {
      currentUrl = "/";
    }
    this.router.navigateByUrl(
      "/signIn?backUrl=" + encodeURIComponent(currentUrl)
    );
  }

  public storeAuthenticationResponse(
    authentication: AuthenticationResponse
  ): void {
    localStorage.setItem(this.key, JSON.stringify(authentication));
    this.isAuth.next(!!authentication);
  }

  public get authenticationResponse(): AuthenticationResponse | undefined {
    const token = localStorage.getItem(this.key);
    if (!!token) {
      return JSON.parse(token) as AuthenticationResponse;
    }
    return undefined;
  }

  /**
   * The user voluntarily logout through menu.
   */
  logout(): void {
    this.flush();
    this.router.navigate(["/account/signin"]);
  }

  resendAuth(): void {
    this.isAuth.next(false);
    this.isAuth.next(true);
  }

  getSetUserNameFromLocalStorage() {
    const token = localStorage.getItem("session");
    if (!!token) {
      const authResponseFromStorage = JSON.parse(
        token
      ) as AuthenticationResponse;
      if (authResponseFromStorage) {
        this.userHelperService.setUserName(authResponseFromStorage.username);
      }
    }
  }

  getSetTenantIdFromLocalStorage() {
    const token = localStorage.getItem("session");
    if (!!token) {
      const authResponseFromStorage = JSON.parse(
        token
      ) as AuthenticationResponse;
      if (authResponseFromStorage) {
        this.userHelperService.setTenantId(authResponseFromStorage.tenantId);
      }
    }
  }
}
