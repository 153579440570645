import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
// import { PageComponent } from '../../base/pageComponent';
// import { AccountService } from '../account.service';
import { MatDialog } from "@angular/material/dialog";
// import { hasMixedCase, hasNumber, hasSpecialChars, isEqualTo, strictMinLength } from '~/app/shared/validators';
import { SignUpInfosSummary } from "@models/account/account.model";

@Component({
  selector: "account-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public showExpireWarning = false;
  public disableLogin = false;
  public autoFilled = false;
  public token: string;
  public infosSummary: SignUpInfosSummary;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    // public accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  // field must be populated with data retrieved (as query params) from token stored in back-end
  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      password: [
        "",
        Validators.compose([
          // strictMinLength(8),
          // hasMixedCase,
          // hasNumber,
          // hasSpecialChars
        ]),
      ],
      confirmPassword: [
        "",
        Validators.compose([
          Validators.required,
          // isEqualTo('password')
        ]),
      ],
    });

    // this.route.queryParams.pipe(
    //     switchMap(params => {
    //         this.token = params && params.token as string;
    //         return this.accountService.getEmailFromToken(this.token);
    //     })
    // ).subscribe((signUpInfosSummary) => {
    //     this.infosSummary = signUpInfosSummary;
    // });
    // this.formGroup.valueChanges.subscribe(($event) => {
    //     this.manageAutofill($event);
    // });
  }

  public manageAutofill($event: FormValueChangesEvent): void {
    if (
      $event.isAutofilled &&
      this.formGroup.pristine &&
      this.formGroup.untouched
    ) {
      this.autoFilled = true;
      return;
    }
    this.autoFilled = false;
  }

  public signUp() {
    // this.accountService.signUp(this.formGroup.value, this.token).subscribe(() => {
    //   this.goToLogin();
    // });
  }

  public signIn() {
    this.goToLogin();
  }

  public goToLogin() {
    this.router.navigateByUrl("/account/signin?fromSignup");
  }

  public isOK(fieldName: string, errorKey: string) {
    return (
      !!this.formGroup.get(fieldName).value &&
      !this.formGroup.get(fieldName).hasError(errorKey)
    );
  }
}
