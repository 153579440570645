import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "account-forget-password-confirmation",
  templateUrl: "./forget-password-confirmation.component.html",
  styleUrls: ["./forget-password-confirmation.component.scss"],
})
export class ForgetPasswordConfirmationComponent implements OnInit {
  public email = "";
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params) => (this.email = params["email"])
    );
  }
}
