import { Injectable } from "@angular/core";
import { Asset } from "@models/asset/asset.model";
export interface Selection {
  assets: Asset[];
  index?: number; // in case of multiple possible selection like in archive the is the index of the object targeted by the selection
}

/**
 * Handle selection of multiple assets in an asset list (i.e. library)
 * and saving it this selection to the session storage
 */
@Injectable()
export class AssetsSelectionService {
  protected key = "";
  constructor() {}

  setSelection(data: Selection): void {
    return sessionStorage.setItem(
      this.key,
      JSON.stringify({
        ...data,
      })
    );
  }

  getSelection(customKey?: string): Selection {
    this.key = customKey ? customKey : this.key;
    const json = sessionStorage.getItem(this.key);
    const parsedJson = json ? JSON.parse(json) : undefined;
    if (parsedJson) {
      parsedJson.unRemovableAssetIds = new Set(parsedJson.unRemovableAssetIds);
    }
    return parsedJson;
  }

  isFinalizedSelection(): boolean {
    return !!JSON.parse(sessionStorage.getItem(this.key)).isCompleted;
  }

  removeSelection(): void {
    sessionStorage.removeItem(this.key);
    return;
  }

  finalizeSelection(): void {
    const storage = JSON.parse(sessionStorage.getItem(this.key));
    storage.isCompleted = true;
    return sessionStorage.setItem(this.key, JSON.stringify(storage));
  }
}
