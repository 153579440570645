import { Injectable } from "@angular/core";
import { AssetsSelectionService } from "./assets-selection.service";

@Injectable()
export class CatalogAssetSelectionService extends AssetsSelectionService {
  protected key = "voxVideoAssetSelection";
  constructor() {
    super();
  }

  public setKey(key: string) {
    this.key = key;
  }
}
