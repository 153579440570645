export interface FileUploadEntity {
    rawFileName: string;
    guid: string;
    size: number;
}

export enum FileUploadEventType {
    RemovedAsFile,
    RemovedByName,
    Uploaded,
    Added
}

export interface ExtendedFileUploadEntity extends FileUploadEntity {
    isUploading: boolean;
    isUploaded: boolean;
}

export enum UploadStepFunctionStatus {
    Success = 'Success',
    UploadFailed = 'Upload Failed',
    InProgress = 'In Progress'
}

export enum VideoStandard {
    Sd = 'SD',
    Hd = 'HD',
    Uhd = 'UHD'
}

export enum Status {
    Checksuming = 'checksumIng',
    ChecksumSuccess = 'checksumSuccess',
    CreatingSignedUrl = 'creatingSignedUrl',
    FileSizeExceedingFailure = 'fileSizeExceedingFailure',
    New = 'new',
    SignedUrlSuccess = 'signedUrlSuccess',
    Uploading = 'Uploading',
    UploadFailure = 'Upload Fail',
    UploadSuccess = 'Upload Success',
    UploadCancelled = 'Upload Cancelled',
    Warning = 'Warning'
}

export interface UploadAuthorizationBody {
    assetId: string;
    fileExtension: string;
    fileSize: number;
    numberOfFiles: number;
    uploadType: string;
    projectData: string;
}
