import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-upload-file",
  templateUrl: "./dialog-upload-file.component.html",
  styleUrls: ["./dialog-upload-file.component.scss"],
})
export class DialogUploadFileComponent implements OnInit {
  fileData: any;
  constructor(public dialogRef: MatDialogRef<DialogUploadFileComponent>) {}

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }

  onFileSelection(event: any) {
    this.fileData = {
      type: "selection",
      fileData: event,
    };
  }

  onDropFile(event: any) {
    this.fileData = {
      type: "drop",
      fileData: event,
    };
  }

  replace(): void {
    if (this.fileData) {
      this.dialogRef.close(this.fileData);
    }
  }
}
