export enum DisplayType {
  Auto = "auto",
  Duration = "duration",
  Date = "date",
  DateTime = "dateTime",
  Text = "text",
  Number = "number",
  NumberAndUnit = "numberAndUnit",
  ArrayOfText = "arrayOfText",
  Link = "link",
  BlueText = "blueText",
  RedText = "redText",
  Comments = "comments",
  Tag = "tag",
  Thumbnail = "thumbnal",
}

export interface PropertyViewOptions {
  /**
   * For the case where the value is actionable
   */
  icon?: string;
  action?: string;
  /**
   * Can enforce the way the value is displayed
   */
  displayType?: DisplayType;
}

export class PropertyView {
  public options: PropertyViewOptions;

  constructor(
    /**
     * Translation key for the field label
     */
    public labelKey: string,
    public value: any,
    options?: PropertyViewOptions,
    public actions?: any
  ) {
    this.options = { displayType: DisplayType.Auto, ...options };
    if (value && this.options.displayType === DisplayType.Auto) {
      this.options.displayType = this.guessDisplayType();
    }
  }

  private guessDisplayType(): DisplayType {
    if (typeof this.value === "number") {
      return DisplayType.Number;
    }

    // If the Value is URL, validating URL.
    if (
      typeof this.value === "object" &&
      this.value.url &&
      new URL(this.value.url).protocol === "https:"
    ) {
      return DisplayType.Link;
    }

    if (typeof this.value === "string" && this.value.match(/^[\d.]+$/)) {
      return DisplayType.Number;
    }

    if (
      typeof this.value === "string" &&
      this.value.match(/^[\d\.]+ [a-zA-Z]+$/)
    ) {
      return DisplayType.NumberAndUnit;
    }

    if (this.value instanceof Date) {
      if (
        this.value.getHours() ||
        this.value.getMinutes() ||
        this.value.getSeconds()
      ) {
        return DisplayType.DateTime;
      } else {
        return DisplayType.Date;
      }
    }

    if (Array.isArray(this.value)) {
      return DisplayType.ArrayOfText;
    }

    try {
      const parsedDate = Date.parse(this.value);
      if (parsedDate) {
        const date = new Date(parsedDate);
        if (date.getHours() || date.getMinutes() || date.getSeconds()) {
          return DisplayType.DateTime;
        } else {
          return DisplayType.Date;
        }
      }
    } catch {}

    return DisplayType.Text;
  }
}
