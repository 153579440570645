import { Pipe, PipeTransform } from "@angular/core";

/**
 * Allow replacement of {n} placeholder with values
 */
@Pipe({
  name: "replace",
})
export class ReplacePipe implements PipeTransform {
  transform(phrase: string, ...replaces: any[]): string {
    // when passing string[] value to 'replaces' param, the result was [string[]] and needed to be flatten
    const flatReplaces = [].concat(...replaces);
    // const flatReplaces = replaces.reduce((flat, value) => flat.concat(value), []);
    flatReplaces.forEach((replace, index) => {
      const search = "\\{" + index + "\\}";
      phrase = phrase.replace(new RegExp(search, "g"), replace);
    });
    return phrase;
  }
}
