import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from "@angular/core";
import { IconType, iconsConfigs, IconConfig, IconKey } from "./fnx-icon.model";
@Component({
  selector: "fnx-icon",
  templateUrl: "./fnx-icon.component.html",
  styleUrls: ["./fnx-icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FnxIconComponent implements OnInit {
  @Input() icon: IconKey;
  @Input() classes: string;

  public style: any = {};
  public config: IconConfig;
  public IconType = IconType;

  constructor() {}

  ngOnInit() {
    this.config = iconsConfigs[this.icon] || iconsConfigs["notAvailable"];
    if (
      [IconType.matFilled, IconType.matSharp, IconType.matOutlined].includes(
        this.config.type
      )
    ) {
      this.classes += ` ${this.config.type}`;
    }

    if (this.config.rotate) {
      this.style["transform"] = `rotate(${this.config.rotate}deg)`;
    }
  }
}
