export function encodeS3URI(name: string): string {
  const encodings: any = {
    '\+': '%2B',
    '\!': '%21',
    '\""': '%22',
    '\#': '%23',
    '\$': '%24',
    '\&': '%26',
    '\'': '%27',
    '\(': '%28',
    '\)': '%29',
    '\*': '%2A',
    '\,': '%2C',
    '\:': '%3A',
    '\;': '%3B',
    '\=': '%3D',
    '\?': '%3F',
    '\@': '%40',
  };
  return encodeURI(name) // Do the standard url encoding
    .replace(
      /(\+|!|"|#|\$|&|'|\(|\)|\*|\+|,|:|;|=|\?|@)/img,
      function (match): string { return encodings[match]; }
    );
}
