import { Observable, of, throwError } from "rxjs";
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  tap,
  delay,
  finalize,
} from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { ToolsService } from "./tools.service";
import { HttpMethod } from "@models/enum/httpMethod";

@Injectable()
export class ApiService {
  pendingRequest: { [url: string]: Observable<any> } = {};

  /*  public get browserId() {
          let browserId = localStorage.getItem('browserToken');
          if (!browserId) {
              browserId = this.toolsService.newUuid();
              this.browserId = browserId;
          }
          return browserId;
      }

      public set browserId(value) {
          localStorage.setItem('browserToken', value);
      } */

  constructor(
    private auth: AuthenticationService,
    private http: HttpClient,
    protected toolsService: ToolsService
  ) {}

  private get headers(): HttpHeaders | null {
    const accessToken = this.auth.accessToken;
    return new HttpHeaders({ Authorization: "Bearer " + accessToken });
  }

  private httpWrapperMethod(
    method: HttpMethod,
    url: string,
    params: HttpParams | { [key: string]: any },
    retry: boolean
  ): Observable<any> {
    const headers = this.headers;

    const options: any = { headers };
    if (method === HttpMethod.Get) {
      options.params = params || {};
    } else {
      options.body = params;
    }

    return this.http.request(method, url, options).pipe(
      catchError((error) => {
        // Authentication issue
        // Redirect only if user has been logged before so it won't redirect on support page
        if (error.status === 401 && this.auth.authenticationResponse) {
          this.auth.handleUnauthorized();
          return of();
        }
        // Rate limitation reached, need to throttle requests
        if (error.status === 429) {
          const delayTime = Math.random() * 5000;
          return of(true).pipe(
            delay(delayTime),
            switchMap((_) => this.httpWrapperMethod(method, url, params, false))
          );
        }
        // Call cancelled by user (ex: on redirected on Safari) don't want to raise error.
        if (error.status === 0) {
          return of({});
        }
        return throwError(error);
      })
    );
  }

  private httpWrapperMethodWithToken(
    method: HttpMethod.Get,
    accessToken: any,
    url: string,
    params: any
  ): Observable<any> {
    let getToken: string;
    if (accessToken) {
      getToken = accessToken;
    } else {
      getToken = this.auth.safeAccessToken;
      if (!getToken) {
        return new Observable();
      }
    }

    const headers = new HttpHeaders({ Authorization: "Bearer " + getToken });
    return this.http[method](url, { params, headers }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public guestGet(endpoint: string, params?: HttpParams): Observable<any> {
    const url = environment.phoenixApiUrl + endpoint;

    return this.http.get(url, { params }).pipe(
      catchError((error, caught) => {
        return throwError(error);
      })
    );
  }

  public guestPost(endpoint: string, params?: any): Observable<any> {
    const url = environment.phoenixApiUrl + endpoint;

    return this.http.post(url, params).pipe(
      catchError((error, caught) => {
        return throwError(error);
      })
    );
  }

  public guestPut(password: string, token: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Authorization", `Bearer ${token}`);
    return this.http.put(
      `${environment.phoenixApiUrl}resetPasswordToken/${token}/resetPassword`,
      password,
      { headers }
    );
  }

  public get(
    endpoint: string,
    params?: HttpParams | { [key: string]: any },
    retry = false
  ): Observable<any> {
    // If we already have a request to this endpoint, just share the same response.
    const requestKey = endpoint + JSON.stringify(params);
    if (this.pendingRequest[requestKey]) {
      return this.pendingRequest[requestKey];
    }

    const request = this.httpWrapperMethod(
      HttpMethod.Get,
      environment.phoenixApiUrl + endpoint,
      params,
      retry
    ).pipe(
      finalize(() => (this.pendingRequest[requestKey] = undefined)),
      shareReplay(1)
    );

    this.pendingRequest[requestKey] = request;
    return request;
  }

  public post(endpoint: string, body?: any, retry = false): Observable<any> {
    return this.httpWrapperMethod(
      HttpMethod.Post,
      environment.phoenixApiUrl + endpoint,
      body,
      retry
    );
  }

  public patch(endpoint: string, body: any, retry = false): Observable<any> {
    return this.httpWrapperMethod(
      HttpMethod.Patch,
      environment.phoenixApiUrl + endpoint,
      body,
      retry
    );
  }

  public put(endpoint: string, body: any, retry = false): Observable<any> {
    return this.httpWrapperMethod(
      HttpMethod.Put,
      environment.phoenixApiUrl + endpoint,
      body,
      retry
    );
  }

  public delete(endpoint: string, body?: any, retry = false): Observable<any> {
    return this.httpWrapperMethod(
      HttpMethod.Delete,
      environment.phoenixApiUrl + endpoint,
      body,
      retry
    );
  }

  public getWithAccessToken(
    accessToken: string,
    endpoint: string,
    params?: HttpParams
  ): Observable<any> {
    return this.httpWrapperMethodWithToken(
      HttpMethod.Get,
      accessToken,
      environment.phoenixApiUrl + endpoint,
      params
    );
  }
}
