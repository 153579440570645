import { Injectable } from "@angular/core";
import { ProjectReply, ProjectsDto } from "@models/textToSpeech/project.model";
import { Observable } from "rxjs";
import { ApiService } from "../shared/api.service";

@Injectable({
  providedIn: "root",
})
export class ReplyService {
  constructor(private api: ApiService) {}

  public update(projectDto: ProjectsDto): Observable<ProjectReply> {
    return this.api.post("reply/update", projectDto);
  }

  public save(reply: ProjectReply): Observable<ProjectReply> {
    return this.api.post("reply/save", reply);
  }

  public edit(reply: ProjectReply): Observable<ProjectReply> {
    return this.api.post("reply/edit", reply);
  }

  public delete(projectId: number, id: number): Observable<ProjectReply> {
    return this.api.delete(`reply/delete/${projectId}/${id}`);
  }

  public updateReply(projectReply: ProjectReply): Observable<ProjectReply> {
    return this.api.post("reply/updateReply", projectReply);
  }

  public updateLongAudio(reply: ProjectReply): Observable<any> {
    return this.api.post("reply/updateLongAudio", reply);
  }

  public removeEvent(id: number, projectsDto: ProjectsDto): Observable<any> {
    return this.api.post(`reply/removeEvent/${id}`, projectsDto);
  }

  public saveVoiceRecording(projectsDto: FormData): Observable<any> {
    return this.api.post(`reply/uploadVoiceRecord`, projectsDto);
  }

  public deleteRetake(
    projectId: number,
    replyId: number,
    index: number
  ): Observable<any> {
    return this.api.delete(
      `reply/deleteRetake/${projectId}/${replyId}/${index}`
    );
  }
}
