import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FileUploadContext } from "~/app/textToSpeech/pages/create-project/create-project.component";
import { environment } from "~/environments/environment";

@Component({
  selector: "app-stacked-snackbar",
  templateUrl: "./stacked-snackbar.component.html",
  styleUrls: ["./stacked-snackbar.component.scss"],
})
export class StackedSnackbarComponent implements OnInit, OnChanges {
  @Output() undoDeleteOperation = new EventEmitter<FileUploadContext>();
  @Input() recentlyDeletedFile: FileUploadContext;
  public notificationList: FileUploadContext[] = [];
  constructor() {}

  ngOnInit() {}

  // remove file explicitly/on-demand, when user click on the (X) button
  removeItem(file: FileUploadContext) {
    this.searchAndDelete(file);
  }

  // trigger this event when undo is clicked
  undo(file: FileUploadContext) {
    this.undoDeleteOperation.emit(file);
    this.searchAndDelete(file);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ("recentlyDeletedFile" in changes) {
      if (this.recentlyDeletedFile) {
        this.pushItemToArray(this.recentlyDeletedFile);
      }
    }
  }

  private pushItemToArray(file: FileUploadContext) {
    this.notificationList.unshift(file);
    // timed event, file/record should be deleted automatically after <x> seconds
    setTimeout(() => {
      this.searchAndDelete(file);
    }, environment.snackBarDurationLong);
  }

  private searchAndDelete(file: FileUploadContext) {
    const index = this.notificationList.findIndex(
      (fuc) => fuc.file.name === file.file.name
    );
    this.notificationList.splice(index, 1);
  }
}
