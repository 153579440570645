import { compareBy } from "../../utils/sort";

// === RX

// === sort
declare global {
  interface Array<T> {
    sortBy(key: string): Array<T>;
  }
}

Array.prototype.sortBy = function (key: string) {
  return this.sort(compareBy(key));
};

export {};
