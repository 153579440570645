import { Injectable } from "@angular/core";
import version from "../../assets/version.json";

@Injectable()
export class VersionService {
  public get data(): { [key: string]: string } {
    return version;
  }

  public get dataArray(): { [key: string]: {} }[] {
    return Object.keys(this.data).map(function (key) {
      return { [key]: version[key] };
    });
  }
}
