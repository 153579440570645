import { PipeTransform, Pipe } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/**
 * Translate pipe that add a `value.` prefix to the translation key, but default to original value if the translation is missing.
 */
@Pipe({ name: "valueTranslate" })
export class ValueTranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(key: string): string {
    const prefixed = "values." + key;
    const value = this.translate.instant(prefixed);
    return value === prefixed ? key : value;
  }
}
