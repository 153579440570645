import { ShowcaseError } from './showcaseError';

export class TokenNotFound extends Error implements ShowcaseError {
    public static codeConst = 'TokenNotFound';
    status = 403;
    code = TokenNotFound.codeConst;
    constructor(token: string) {
        super(`Token not found: ${token}`);
    }
}
