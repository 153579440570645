/**
 * Use recursivity for finding the value of a property. Allow descending properties.
 * @param source The object on which you want to find the property value
 * @param propertyPath The property name, optionally a chain of descending properties separated with a dot.
 * @param throwErrorOnBrokenPath If `true`, throws errors if one of the properties in the path return `undefined`, preventing to get the next property. Otherwise, return `undefined`.
 * @param separator `.` by default, can be changed to support weird path case like `[extendedMetadata]['Tape.Format']` from difuzeam.
 */
export function getValueByPropertyPath(
    source: any,
    propertyPath: string,
    throwErrorOnBrokenPath = false,
    separator = '.'
): any {
    if (propertyPath.indexOf(separator) !== - 1) {
        const propertyName = propertyPath.substring(0, propertyPath.indexOf(separator));
        const followingPath = propertyPath.substring(propertyPath.indexOf(separator) + 1);
        const newObj = source[propertyName];
        if (newObj) {
            return getValueByPropertyPath(newObj, followingPath, throwErrorOnBrokenPath, separator);
        } else {
            if (throwErrorOnBrokenPath) {
                throw new Error(`Can't get property ${propertyName}`);
            }
        }
        return undefined;
    }

    return source[propertyPath];
}

/**
 * Use recursivity to set the value of a property, allow descending properties path.
 * Notice that this can't support `[ExtendedMetata]['Tape.Format']`
 * @param source The object on which you want to set the property value
 * @param propertyPath The property name, optionally a chain of descending properties separated with a dot.
 * @param value The value to be used
 * @param createPathIfNotExists If `true`, create the path if does not exist. Otherwise, throws an error.
 * @param separator `.` by default, can be changed to support weird path case like `[extendedMetadata]['Tape.Format']` from difuzeam.
 */
export function setValueByPropertyPath(
    source: any,
    propertyPath: string,
    value: any,
    createPathIfNotExists = true,
    separator = '.'
): void {
    if (propertyPath.indexOf(separator) !== - 1) {
        const propertyName = propertyPath.substring(0, propertyPath.indexOf(separator));
        const followingPath = propertyPath.substring(propertyPath.indexOf(separator) + 1);
        let newObj = source[propertyName];

        if (!newObj) {
            if (createPathIfNotExists) {
                newObj = {};
                source[propertyName] = newObj;
            } else {
                throw new Error(`Path broken on ${propertyName}`);
            }
        }

        setValueByPropertyPath(newObj, followingPath, value, createPathIfNotExists, separator);
    } else {
        source[propertyPath] = value;
    }
}

/** createNestedObject('my>nested>object', 'someValue') ===
*  {
*      my: {
*          nested: {
*              object: 'someValue'
*          }
*      }
*  }
*
*  If you need to wrap something in an array: createNestedObject('my>nested[]>object', 'someValue') ===
*  {
*      my: {
*          nested: [
*              {
*                  object: 'someValue'
*              }
*          ]
*      }
*   }
*/
export function createNestedObject(path: string, value: any): any {
    const parts = path.split('>');

    const result: any = {};
    let position: any = result;

    parts.forEach((part, index) => {
        const splited = part.split('[]');

        if (splited.length === 2) {
            if (index === parts.length - 1) {
                position[splited[0]] = [value];
            } else {
                position[splited[0]] = [{}];
                position = position[splited[0]][0];
            }
        } else {
            if (index === parts.length - 1) {
                position[splited[0]] = value;
            } else {
                position[splited[0]] = {};
                position = position[splited[0]];
            }
        }
    });

    return result;
}

export type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

type Undefined<T> = {
    [P in keyof T]: undefined;
};

/**
 * Take a type, and transform all the property typing to be either an array or a single value
 * Could be useful in search context to defined parameter for search across multiple values.
 * ex: { prop1: string, prop2: number, prop3: number[] } => { prop1: string | string[], prop2: number | number[], prop3: number | number[] }
 */
export type Arrayed<T> = {
    [P in keyof T]: T[P] extends Array<infer U> ? T[P] | U : T[P][] | T[P];
};

/**
 * Given a types T1 and T2, and an object `base` of type `T1 & T2`,
 * force you to pass a toRemove value that will lead to a pure T2 type object.
 * Warning: Works of first level only.
 * @example
 * removePropToType<PrivateUser, PublicUser>(privateUser, { sensibleProp: undefined, hiddenProp: undefined })
 * @param base The object to cleanup, which should be T1 & T2
 * @param toRemove An object containing undefined value, for each properties of T1 that is not in T2
 * @returns The base object, but with all property from `toRemove` deleted, therefore a T2 object.
 */
export function removePropToType<T1, T2>(base: T1 & T2, toRemove: Undefined<Omit<T1, keyof T2>>): T2 {
    const copy = { ...base };
    Object.keys(toRemove).forEach(key => {
        delete copy[key as keyof T1];
    });
    return copy;
}

/**
 * Kind of `original = replacement` but while preserving the reference.
 * Obviously, `original` property is transformed by the function.
 * @param original The original object, which we want to keep the reference.
 * @param replacement The object from which we want to take all property values.
 */

export function overrideAllProperty<T extends {}>(original: T, replacement: T): void {
    // Remove all properties of the original object.
    Object.keys(original).forEach(key => {
        delete original[key as keyof T];
    });

    // Fill original object with all the values from replacement
    Object.assign(original, replacement);
}
