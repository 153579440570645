import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  DoCheck,
} from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { Action } from "@models/shortcut/shortcut.model";
import { ProjectReply, ReplyStatus } from "@models/textToSpeech/project.model";
import { Subscription } from "rxjs";
import WaveSurfer from "wavesurfer.js";
import Recordings from "wavesurfer.js/dist/plugins/record";
import Region from "wavesurfer.js/dist/plugins/regions";
import { ShortcutService } from "~/app/shared/shortcut.service";
import {
  bufferToWave,
  calculateMinuteDifference,
  getFrames,
  getTimeCodeDifference,
  getTimeInSecondsFromFrameRate,
  splitTextIntoLines,
} from "~/utils/project";
import { AudioAnalyzer } from "./audioAnalyser";
import { RecordingRegion } from "@models/shared/recordingRegion.model";
import { ProjectService } from "../../project.service";
import { appConfig } from "~/environments/app.config";
import { ReplyService } from "~/app/textToSpeech/reply.service";
import { TranslateService } from "@ngx-translate/core";
import { Asset } from "@models/asset/asset.model";

@Component({
  selector: "recording-workspace",
  templateUrl: "./recording-workspace.html",
  styleUrls: ["./recording-workspace.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingWorkspaceComponent
  implements OnInit, AfterViewInit, DoCheck
{
  @ViewChild("record", { static: false })
  private recordBtn: ElementRef;

  @ViewChild("micSelect", { static: false })
  private micSelect: ElementRef;

  @ViewChild("timer", { static: false })
  private timer: ElementRef;

  @ViewChild("waveform", { static: false })
  private waveform: ElementRef;

  @Input() reply: ProjectReply;
  @Input() currentSelectedReply = 0;
  @Input() totalReplies = 0;
  @Input() microphoneDeviceId: string;
  @Input() frameRate: number;
  @Input() isVideoSectionVisible = false;
  @Input() uniqueProjectName = "";
  @Input() latestAudioFilePath = "";
  @Input() player: any;
  @Input() selectedVideoAsset: Asset;
  @Output() showHideRetakes = new EventEmitter<any>();
  @Output() recordedReply = new EventEmitter<ProjectReply>();
  @Output() emitSelectedRetakeChange = new EventEmitter<number>();
  @Output() emitSetupGuideChange = new EventEmitter<any>();
  @Output() emitShowShortcutsTabs = new EventEmitter<any>();
  @Output() cutContentEnabled = new EventEmitter<boolean>();
  @Output() selectedRetakeIndex = new EventEmitter<number>();

  private prevReply: ProjectReply;
  public wavesurfer: any;
  private record: any;
  private wsRegions: any;
  private scrollingWaveform = false;
  private timerInterval: any;
  private isFullScreen = false;
  private isRecordingStoppedManually = false;
  private audioAnalyzer: AudioAnalyzer;
  private lastSavedTimeInterval: any;
  private isLoading = false;
  public isRecordingBeingPlayed = false;
  public isRecordingStarted = false;
  public countdown: number;
  public countdownTimeInSec = 3;
  public isContinuousModeEnabled = false;
  public descendingDurationCountdown: string = "";
  public lastSavedDateTime = "";
  public isCutModeEnabled = false;
  private maxRetake = appConfig.maxRetake;

  private shortcutTriggeredSubscription: Subscription;
  private retakeIndex: number = 0;
  private prevIsVideoSectionVisible: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private keyboardShortcutService: ShortcutService,
    private projectService: ProjectService,
    private replyService: ReplyService,
    private translateService: TranslateService
  ) {}

  ngDoCheck(): void {
    if (this.prevReply && this.prevReply?.id !== this.reply?.id) {
      this.retakeIndex = 0;
      this.prevReply = this.reply;
      const audio = this.reply?.multipleRetakes?.length
        ? this.reply?.multipleRetakes[this.retakeIndex]
        : this.reply?.audioFilePath;
      setTimeout(() => {
        this.basicWaveSetup(audio);
      }, 50);
    }
    if (
      this.prevIsVideoSectionVisible !== undefined &&
      this.prevIsVideoSectionVisible !== this.isVideoSectionVisible
    ) {
      this.retakeIndex = 0;
      this.prevIsVideoSectionVisible = this.isVideoSectionVisible;
      const audio = this.reply?.multipleRetakes?.length
        ? this.reply?.multipleRetakes[this.retakeIndex]
        : this.reply?.audioFilePath;

      setTimeout(() => {
        this.basicWaveSetup(audio);
      }, 50);
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.getMicOptions();
    setTimeout(() => {
      this.basicWaveSetup(this.reply?.audioFilePath);
    }, 100);
    this.prevReply = this.reply;
    this.prevIsVideoSectionVisible = this.isVideoSectionVisible;
  }

  ngOnInit(): void {
    this.shortcutTriggeredSubscription =
      this.keyboardShortcutService.shortcuts$.subscribe((shortcut) => {
        if (shortcut === Action.ShowShortcuts) {
          this.showShortcutsTabs();
        } else if (shortcut === Action.ActivateContinuousMode) {
          this.toggleContinuousMode();
        } else if (shortcut === Action.ExitContinuousMode) {
          this.toggleContinuousMode();
        } else if (shortcut === Action.StartStopRecording) {
          this.recordVoice(true);
        } else if (shortcut === Action.FullscreenStudio) {
          this.fullScreen();
        } else if (shortcut === Action.ExitFullscreenStudio) {
          this.fullScreen();
        }
      });
  }

  public ngOnDestroy(): void {
    this.shortcutTriggeredSubscription.unsubscribe();
    this.stopTimerAndRecordVoice();
  }

  private initializeRecordPlugin(): void {
    this.record = this.wavesurfer.registerPlugin(
      Recordings.create({
        scrollingWaveform: this.scrollingWaveform,
        renderRecordedAudio: false,
      })
    );
  }

  private getMicOptions(): void {
    // Mic selection
    Recordings.getAvailableAudioDevices().then((devices) => {
      devices.forEach((device) => {
        const option = document.createElement("option");
        option.value = device.deviceId;
        option.text = device.label || device.deviceId;
        this.micSelect?.nativeElement.appendChild(option);
      });
    });
  }

  private basicWaveSetup(audioFilePath: string = null): void {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
    this.createWaveSurfer(audioFilePath);
    this.initializeRecordPlugin();
    this.renderRecordedAudio();
  }

  private createWaveSurfer(recordedUrl: string = null): void {
    // Create an instance of WaveSurfer
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
    this.wavesurfer = WaveSurfer?.create({
      container: this.waveform?.nativeElement,
      waveColor: "#a2cbe0",
      progressColor: "#5DA5D9",
      minPxPerSec: 100,
      barWidth: 2,
      barGap: 3,
      cursorColor: "#3CA8DE",
      cursorWidth: 2,
      height: this.isVideoSectionVisible ? 80 : 107,
      url: recordedUrl,
      sampleRate: 24000,
    });
  }

  public async applySampleRate(blob: Blob): Promise<void> {
    // this.recordedAudio.nativeElement.playbackRate = this.reply.speedRate;
    const audioData = await blob.arrayBuffer();

    const audioCtx = new AudioContext();

    audioCtx.decodeAudioData(audioData, (buffer) => {
      const offlineContext = new OfflineAudioContext(
        1,
        buffer.duration * 24000,
        24000
      );
      const offlineSource = offlineContext.createBufferSource();
      offlineSource.buffer = buffer;
      // offlineSource.playbackRate.value = this.reply.speedRate;
      offlineSource.connect(offlineContext.destination);
      offlineSource.start();
      offlineContext.startRendering().then(async (renderedBuffer) => {
        const newBlob = bufferToWave(renderedBuffer, offlineContext.length);
        const recordedUrl = URL.createObjectURL(newBlob);
        this.afterAudioConversion(recordedUrl, newBlob);
      });
    });
  }

  public afterAudioConversion(recordedUrl: string, blob: Blob): void {
    this.createWaveSurfer(recordedUrl);

    const formData = new FormData();
    formData.append("audio-file", blob);
    this.reply.voiceRecording = formData;
    this.reply.status = ReplyStatus.Creating;
    this.reply.isLongAudio = this.reply.isFarTooLongAudio = false;
    if (this.retakeIndex === 0) {
      this.reply.audioFilePath = recordedUrl;
    }
    if (
      this.reply &&
      Array.isArray(this.reply.multipleRetakes) &&
      this.reply?.multipleRetakes?.length > this.retakeIndex
    ) {
      this.reply.multipleRetakes[this.retakeIndex] = recordedUrl;
    }
    if (
      this.isContinuousModeEnabled &&
      this.currentSelectedReply <= this.totalReplies
    ) {
      this.reply.isSelected = this.isRecordingStoppedManually
        ? true
        : !this.isContinuousModeEnabled;
    }
    this.recordedReply.emit(this.reply);
    if (
      this.isContinuousModeEnabled &&
      this.currentSelectedReply !== this.totalReplies &&
      !this.isRecordingStoppedManually
    ) {
      this.nextEvent();
      if (this.currentSelectedReply <= this.totalReplies) {
        this.recordVoice(); // start recoding again..
      }
    }
    this.audioAnalyzer.stopListening();
    this.updateLastSavedTime();
  }

  private updateLastSavedTime(): void {
    if (this.lastSavedTimeInterval !== undefined) {
      clearInterval(this.lastSavedTimeInterval);
      this.lastSavedTimeInterval = false;
    }
    const date = new Date();
    this.lastSavedTimeInterval = setInterval(() => {
      this.lastSavedDateTime = calculateMinuteDifference(date, new Date());
      this.cdr.detectChanges();
    }, 100);
  }

  private renderRecordedAudio(): void {
    this.record.on("record-end", (blob: any) => {
      this.applySampleRate(blob);
    });
  }

  public recordVoice(isRecordingStoppedManually = false): void {
    if (
      this.reply.multipleRetakes &&
      this.reply.multipleRetakes?.length === 3 &&
      this.reply.multipleRetakes[2] !== ""
    ) {
      alert(this.translateService.instant(`recording.retakeLimitExceeded`));
      return;
    }

    if (this.countdown) {
      return;
    }
    if (this.record.isRecording()) {
      this.stopRecording(isRecordingStoppedManually);
      return;
    }
    setTimeout(() => {
      this.isRecordingStoppedManually = !isRecordingStoppedManually;
      const { time } = this.getSeekParameters(
        this.reply.tcIn,
        this.selectedVideoAsset?.duration
      );
      if (this.player) {
        this.player._player.currentTime(time);
        this.player._player.on("seeked", () => {
          this.player._player.off("seeked");
          this.countdownTimer(this.countdownTimeInSec);
        });
      } else {
        this.countdownTimer(this.countdownTimeInSec);
      }
    }, 200);
  }

  private stopRecording(isRecordingStoppedManually: boolean): void {
    this.isRecordingStoppedManually = isRecordingStoppedManually;
    clearInterval(this.timerInterval);
    this.timerInterval = false;
    if (this.player) {
      this.player._player.pause();
    }
    this.record?.stopRecording();
    this.audioAnalyzer?.stopListening;
    this.isRecordingStarted = !this.isRecordingStarted;
  }

  private setupRecordingAndWave(): void {
    const deviceId = this.microphoneDeviceId;
    this.createWaveSurfer();
    this.initializeRecordPlugin();
    this.renderRecordedAudio();
    this.isRecordingStarted = !this.isRecordingStarted;

    if (this.player) {
      this.player._player.play();
      this.player._player.on("playing", () => {
        this.player._player.off("playing");
        this.record.startRecording({ deviceId }).then(() => {
          this.audioAnalyzer = new AudioAnalyzer();
          this.audioAnalyzer.listenDecibels();
        });
      });
    } else {
      this.record.startRecording({ deviceId }).then(() => {
        this.audioAnalyzer = new AudioAnalyzer();
        this.audioAnalyzer.listenDecibels();
      });
    }
  }

  public playPauseRecording(): void {
    if (!this.wavesurfer.decodedData) {
      return;
    }

    this.isRecordingBeingPlayed = !this.isRecordingBeingPlayed;
    this.wavesurfer.playPause();
    this.wavesurfer.on("pause", () => (this.isRecordingBeingPlayed = false));
    this.wavesurfer.on("play", () => (this.isRecordingBeingPlayed = true));
    this.wavesurfer.on("finish", this.setRecordingPlaybackToFalse);
  }

  public get isRecordingAvailable(): boolean {
    return !!this.wavesurfer?.decodedData;
  }

  private setRecordingPlaybackToFalse = (): void => {
    this.isRecordingBeingPlayed = false;
    this.cdr.detectChanges();
  };

  private countdownTimer(countdownDuration: number): void {
    this.countdown = countdownDuration;
    this.timerInterval = setInterval(() => {
      this.countdown -= 1;
      if (this.countdown === 0) {
        clearInterval(this.timerInterval);
        this.timerInterval = false;
        this.record.stopRecording();
        const totalFrames = getFrames(
          getTimeCodeDifference(
            this.reply.tcIn,
            this.reply.tcOut,
            this.frameRate
          ),
          this.frameRate
        );
        this.recodingDurationCountDown(totalFrames, this.frameRate);
      }
      this.cdr.detectChanges();
    }, 1000);
  }

  get hasInterval(): boolean {
    return this.timerInterval;
  }

  // Function to subtract timecodes
  public getTimeCodeDifference(): string {
    if (
      (this.isRecordingStarted || this.isRecordingAvailable) &&
      this.countdown === 0
    ) {
      return this.descendingDurationCountdown;
    }
    return getTimeCodeDifference(
      this.reply.tcIn,
      this.reply.tcOut,
      this.frameRate
    );
  }

  splitEvent(event: string) {
    return event?.length > 75 ? splitTextIntoLines(event, 75) : [event];
  }

  public previousEvent(): void {
    if (this.currentSelectedReply === 1 || this.isCutModeEnabled) {
      return;
    }
    this.emitSelectedRetakeChange.emit(this.currentSelectedReply - 2);
  }

  public nextEvent(): void {
    if (
      this.currentSelectedReply === this.totalReplies ||
      this.isCutModeEnabled
    ) {
      return;
    }
    this.emitSelectedRetakeChange.emit(this.currentSelectedReply);
  }

  public fullScreen(): void {
    this.isFullScreen = !this.isFullScreen;
    this.showHideRetakes.emit(!this.isFullScreen);
  }

  get isRecordingWorkspaceInFullScreen(): boolean {
    return this.isFullScreen;
  }

  // HostListener for keydown event on the document
  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case "Escape":
        if (this.isContinuousModeEnabled) {
          this.toggleContinuousMode();
          return;
        }
        if (this.isFullScreen) this.fullScreen();
        break;
      case "ArrowUp":
        this.previousEvent();
        break;
      case "ArrowDown":
        this.nextEvent();
        break;
      default:
        break;
    }
  }

  public openSetupGuide(): void {
    this.emitSetupGuideChange.emit({ deviceId: this.microphoneDeviceId });
  }

  public toggleContinuousMode(): void {
    if (this.isCutModeEnabled) {
      return;
    }
    this.isContinuousModeEnabled = !this.isContinuousModeEnabled;
    this.countdownTimeInSec = this.isContinuousModeEnabled ? 1 : 3;
  }

  private recodingDurationCountDown(
    countdownDurationFrames: number,
    frameRate: number
  ): void {
    this.setupRecordingAndWave();
    const totalFrames = countdownDurationFrames;
    let countdownDuration = totalFrames / frameRate;
    this.timerInterval = setInterval(() => {
      const { hours, minutes, seconds, frames } = this.calculateTimeComponents(
        countdownDuration,
        frameRate
      );
      if (countdownDuration >= 0) {
        this.descendingDurationCountdown = this.formatTime(
          hours,
          minutes,
          seconds,
          frames
        );
      }
      if (countdownDuration <= 0) {
        this.stopTimerAndRecordVoice();
      }
      this.cdr.detectChanges();
      countdownDuration -= 1 / frameRate;
    }, 1000 / frameRate);
  }

  private calculateTimeComponents(
    countdownDuration: number,
    frameRate: number
  ): { hours: number; minutes: number; seconds: number; frames: number } {
    const hours = Math.floor(countdownDuration / 3600);
    const minutes = Math.floor((countdownDuration % 3600) / 60);
    const seconds = Math.floor(countdownDuration % 60);
    const frames = Math.floor((countdownDuration % 1) * frameRate);
    return { hours, minutes, seconds, frames };
  }

  private formatTime(
    hours: number,
    minutes: number,
    seconds: number,
    frames: number
  ): string {
    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(
      seconds
    )}:${this.padZero(frames)}`;
  }

  private stopTimerAndRecordVoice(): void {
    this.stopRecording(false);
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  get decibelSignalStrength(): string {
    return this.audioAnalyzer?.getDecibelQualitySignal();
  }

  private showShortcutsTabs() {
    this.emitShowShortcutsTabs.emit();
  }

  public toggleCut(): void {
    if (this.isDisableCutOperation) {
      return;
    }
    this.isContinuousModeEnabled = false;
    this.isCutModeEnabled = !this.isCutModeEnabled;
    this.cutContentEnabled.emit(this.isCutModeEnabled);
    if (this.isCutModeEnabled) {
      this.registerRegionEvents();
    } else {
      this.processRegions();
    }
  }

  private updateRegionHandles = () => {
    const hostElement = document.querySelector(".wav-section div");
    const shadowRoot = hostElement.shadowRoot;

    const targetDivLeft = shadowRoot.querySelectorAll(
      'div[data-resize="left"]'
    );
    targetDivLeft.forEach((targetDiv: HTMLElement) => {
      targetDiv.style.setProperty("border-left", "5px solid #3CA8DE");
    });

    const targetDivRight = shadowRoot.querySelectorAll(
      'div[data-resize="right"]'
    );
    targetDivRight.forEach((targetDiv: HTMLElement) => {
      targetDiv.style.setProperty("border-right", "5px solid #3CA8DE");
    });
  };

  private registerRegionEvents(): void {
    this.wsRegions = this.wavesurfer.registerPlugin(Region.create());

    this.wsRegions.enableDragSelection({
      color: "rgba(226, 71, 35, 0.3)",
    });

    this.wsRegions.on("region-created", (region: any) => {
      this.wsRegions.getRegions().forEach((r: any) => {
        if (r.id !== region.id) {
          r.remove();
        }
      });
      this.updateRegionHandles();
    });

    this.wsRegions.on("region-clicked", (region: any, e: MouseEvent) => {
      e.stopPropagation();
      region.play();
    });
  }

  get isDisableCutOperation(): boolean {
    return (
      this.isLoading ||
      !this.reply?.audioFilePath ||
      !this.reply?.multipleRetakes?.length
    );
  }

  private processRegions(): void {
    if (this.isDisableCutOperation) {
      return;
    }
    const regions: any[] = this.wsRegions.getRegions();
    if (this.wsRegions.getRegions().length) {
      this.isLoading = true;
      const recordingRegionDetails: RecordingRegion = {
        uniqueProjectName: this.uniqueProjectName,
        eventId: this.reply.id,
        audioFilePath:
          this.reply?.multipleRetakes[this.retakeIndex] ||
          this.reply?.audioFilePath ||
          "",
        region: regions.map(({ start, end, numberOfChannels, id }) => ({
          start,
          end,
          numberOfChannels,

          id,
        }))[0],
        totalDuration: regions[0].totalDuration,
      };
      const selectedAudio = this.reply.multipleRetakes[this.retakeIndex]
        ? this.reply?.multipleRetakes[this.retakeIndex]
        : this.reply?.audioFilePath;
      this.projectService
        .clipRecordingRegions(recordingRegionDetails)
        .subscribe(() => {})
        .add(() => {
          this.basicWaveSetup(selectedAudio);
          this.isLoading = false;
          this.updateLastSavedTime();
        });
    }
    this.clearRegion();
  }

  private clearRegion(): void {
    this.wsRegions.clearRegions();
    this.wsRegions.destroy();
  }

  private addTake() {
    (this.reply.multipleRetakes as string[]).push("");
  }

  private selectRetake(i: number) {
    const audio = this.reply?.multipleRetakes[i]
      ? this.reply?.multipleRetakes[i]
      : "";
    this.basicWaveSetup(audio);
    this.selectedRetakeIndex.emit(i);
    this.retakeIndex = i;
    this.reply.selectedRetakeIndex = i;
  }

  private isSelected(i: number): boolean {
    return this.retakeIndex === i;
  }

  private deleteTake(i: number) {
    (this.reply.multipleRetakes as string[]).splice(i, 1);
    this.replyService
      .deleteRetake(this.reply.projectId, this.reply.id, i)
      .subscribe();
    this.cdr.detectChanges();
  }

  public cancelTrim(): void {
    this.isCutModeEnabled = !this.isCutModeEnabled;
    this.cutContentEnabled.emit(this.isCutModeEnabled);
    this.clearRegion();
  }

  private getSeekParameters(
    position: string,
    videoDuration: string
  ): { time: number; maxTime: number } {
    const time = this.selectedVideoAsset?.startTimeCode
      ? getTimeInSecondsFromFrameRate(
          position,
          Number(this.selectedVideoAsset?.frameRate)
        ) -
        getTimeInSecondsFromFrameRate(
          this.selectedVideoAsset?.startTimeCode,
          Number(this.selectedVideoAsset?.frameRate)
        )
      : getTimeInSecondsFromFrameRate(
          position,
          Number(this.selectedVideoAsset?.frameRate)
        );
    const maxTime = Number(videoDuration);
    return { time: time, maxTime: maxTime };
  }
}
