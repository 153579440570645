import { AfterViewInit, Component, Input } from "@angular/core";
import { Asset } from "@models/asset/asset.model";
import { Project, VoiceType } from "@models/textToSpeech/project.model";
import { FileUploadContext } from "../create-project/create-project.component";

@Component({
  selector: "app-project-review",
  templateUrl: "./project-review.component.html",
  styleUrls: ["./project-review.component.scss"],
})
export class ProjectReviewComponent implements AfterViewInit {
  @Input() project: Project;
  @Input() fileUploadContexts: FileUploadContext[];
  @Input() selectedVideoAsset: Asset;

  public none = "None";
  public projectDetails: any[];

  ngAfterViewInit(): void {
    this.projectDetails = [
      this.getVoiceSettings(),
      this.getTechnicalSettings(),
      this.getFilesAttached(),
    ];
  }

  get isVoiceSelected() {
    return this.project?.voiceSetting === VoiceType.Voicer;
  }

  private getVoiceSettings() {
    return {
      key: "createProject.voice",
      properties: [
        {
          key: "createProject.language",
          value: this.project?.language || this.none,
        },
        {
          key: "createProject.region",
          value: this.project?.region || this.none,
        },
        {
          key: "createProject.voice",
          value: this.project?.voice?.split("/")[0] || this.none,
        },
        {
          key: "createProject.lexicon",
          value: this.project?.lexiconName || this.none,
        },
      ],
    };
  }

  private getTechnicalSettings() {
    const properties = {
      key: "createProject.technicalSettings",
      properties: [
        {
          key: "createProject.templateName",
          value: this.project?.templateName || this.none,
        },
        {
          key: "createProject.startTimecode",
          value: this.project?.startTimeCode || this.none,
        },
        {
          key: "createProject.frameRate",
          value: this.project?.frameRate || this.none,
        },
        {
          key: "createProject.speed",
          value: this.project?.speedRate || this.none,
        },
        {
          key: "createProject.maxAutomatedSpeed",
          value: this.project?.automatedSpeedRate || this.none,
        },
        {
          key: "createProject.automatedMix",
          value: this.project?.isAutomatedMix || this.none,
        },
        {
          key: "createProject.automatedVariableSpeed",
          value: this.project?.isAutomatedSpeedRate || this.none,
        },
      ],
    };
    if (this.isVoiceSelected) {
      properties.properties.push({
        key: "createProject.automatedSoundCorrection",
        value: this.project?.automatedSoundCorrection || "false",
      });
    }
    return properties;
  }

  private getFilesAttached() {
    return {
      key: "createProject.filesAttached",
      properties: this.fileUploadContexts.filter(
        (file) => !file.isDeleted && file.isValidated
      ),
      showStatus: true,
    };
  }

  get startTimeCode(): string {
    return this.project.startTimeCode !== "00:00:00:00"
      ? this.project.startTimeCode
      : this.selectedVideoAsset.startTimeCode
      ? this.selectedVideoAsset.startTimeCode
      : "00:00:00:00";
  }
}
