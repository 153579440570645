import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "../account.service";

@Component({
  selector: "account-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public expire = false;
  public disableForgotPassword = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private translate: TranslateService
  ) {
    this.formGroup = this.formBuilder.group({
      email: ["", Validators.compose([Validators.email, Validators.required])],
    });

    this.expire = (activatedRoute.data as any).value.expire;
  }

  ngOnInit(): void {}

  send() {
    this.disableForgotPassword = true;
    const email = this.formGroup.get("email").value;
    this.accountService.requestResetPassword(email).subscribe(
      (_isSuccess) => {
        if (_isSuccess) {
          this.router.navigate([
            `/account/forgetPassword/${email}/confirmation`,
          ]);
        } else {
          alert(this.translate.instant("forgetPassword.invalidMessage"));
          this.disableForgotPassword = false;
        }
      },
      () => {
        this.disableForgotPassword = false;
      }
    );
  }
}
