import {
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import { environment } from "~/environments/environment";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnChanges {
  /**
   * Total item count; The component apply automatically the ES max size if itemCount is higher.
   */
  @Input() itemCount = 0;
  @Input() itemsPerPage = 0;
  /**
   * Zero based index for the search from parameter
   */
  @Input() from: number;
  @Output() fromChange = new EventEmitter<number>();

  public pages: number[] = [];
  public hasPagesBefore: boolean;
  public hasPagesAfter: boolean;
  public totalPages = 0;
  public currentPage = 1;
  public isPreviousActive: boolean;
  public isNextActive: boolean;

  constructor() {}

  ngOnChanges() {
    let start: number;
    let end: number;
    const maxedItemCount = Math.min(
      environment.maxSearchResult,
      this.itemCount || 0
    );
    this.totalPages = this.itemsPerPage
      ? Math.ceil(maxedItemCount / this.itemsPerPage)
      : 0;
    this.currentPage = Math.floor(this.from / this.itemsPerPage) + 1;

    // paginator start
    if (this.totalPages > 6 && this.totalPages - this.currentPage === 4) {
      start = this.totalPages - 6;
    } else if (this.totalPages > 5 && this.totalPages - this.currentPage < 5) {
      start = this.totalPages - 5;
    } else if (this.currentPage > 3) {
      start = this.currentPage - 2;
    } else {
      start = 1;
    }

    // paginator end
    if (this.totalPages <= 5) {
      end = this.totalPages;
    } else {
      end = start + 4;
    }

    this.pages = [];
    for (let i = start; i <= end; i++) {
      this.pages.push(i);
    }
    this.hasPagesBefore = this.pages[0] > 2;
    this.hasPagesAfter =
      this.pages[this.pages.length - 1] < this.totalPages - 1;
    this.isPreviousActive = this.currentPage > 1;
    this.isNextActive = this.currentPage < this.totalPages;
  }

  public changePage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.fromChange.emit((page - 1) * this.itemsPerPage);
    }
  }

  public previousPage() {
    if (this.currentPage > 1) {
      this.changePage(this.currentPage - 1);
    }
  }

  public nextPage() {
    if (this.currentPage < this.totalPages) {
      this.changePage(this.currentPage + 1);
    }
  }
}
