import { DOCUMENT, registerLocaleData } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import localeFr from "@angular/common/locales/fr";
import { SupportedLanguages } from "../../../../models/user/user.model";

registerLocaleData(localeFr, "fr");

@Injectable({
  providedIn: "root",
})
export class TranslateManagerService {
  availableLang: string[] = Object.values(SupportedLanguages);
  defaultLang = SupportedLanguages.English;

  constructor(
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: any
  ) {}

  public initializeLanguage(): void {
    this.translate.addLangs(this.availableLang);
    this.translate.setDefaultLang(this.defaultLang);
  }
}
