import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthenticationService } from "../authentication.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuard implements CanActivate {
  constructor(private auth: AuthenticationService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth
      .validateSessionOrRedirect()
      .pipe(map((session) => !!session));
  }
}
