import { ShowcaseError } from './showcaseError';

export class WrongOldPassword extends Error implements ShowcaseError {
    public static codeConst = 'WrongPassword';
    status = 400;
    code = WrongOldPassword.codeConst;
    constructor() {
        super(`The old password wrong`);
    }
}
