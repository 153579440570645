import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Asset } from "@models/asset/asset.model";
import { AssetService } from "../asset.service";
import { switchMap, first } from "rxjs/operators";
import { AssetViewService } from "~/app/shared/assetView.service";
import { AssetClasses } from "@models/enum/assetClasses";
import { Location } from "@angular/common";
import { AbstractAssetViewService } from "~/app/shared/abstractAssetView.service";
import { AuthenticationService } from "~/app/shared/authentication.service";
import { AssetView } from "~/app/shared/components/propertiesCard/assetView.model";

@Component({
  selector: "asset-details",
  templateUrl: "./asset-details.component.html",
  styleUrls: ["./asset-details.component.scss"],
})
export class AssetDetailsComponent implements OnInit {
  public asset: Asset;
  public backLabel: string;
  public assetView: AssetView;
  private assetViewService: AbstractAssetViewService;
  public accessToken: string;

  constructor(
    protected route: ActivatedRoute,
    protected location: Location,
    private genericAssetViewService: AssetViewService,
    public router: Router,
    protected authenticationService: AuthenticationService,
    private assetService: AssetService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit(): Promise<void> {
    this.route.params
      .pipe(
        first(),
        switchMap((params) => {
          this.backLabel = params.backLabel;
          return this.assetService.getAsset(params.id);
        })
      )
      .subscribe((asset) => {
        this.processAsset(asset);
        this.accessToken = this.authenticationService.accessToken;
      });
  }

  private processAsset(asset: Asset) {
    this.buildAsset(asset);
  }

  back(): void {
    this.location.back();
  }

  private buildAsset(asset: Asset): void {
    if (typeof asset.duration === "string") {
      asset.duration = asset.duration.substr(0, 8);
    }
    this.asset = asset;
    this.assetViewService = this.genericAssetViewService;
    this.assetView = this.assetViewService.buildAssetView(asset);
  }

  /**
   * Storage Exclusive, Physical Tape, and QcReport never have thumbnail
   * Therefore, we don't want to display the default thumbnail
   */
  public get canDisplayThumbnail() {
    return this.asset.isPlayable;
  }

  get isAssetProxiesGenerated(): boolean {
    return this.asset?.assetProxy?.length > 0 || false;
  }
}
