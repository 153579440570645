import { Inject, Injectable } from "@angular/core";
import { OwlDateTimeIntl } from "ng-pick-datetime";
import { TranslateService } from "@ngx-translate/core";

export const CUSTOM_FORMATS = {
  fullPickerInput: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h12",
  },
};

@Injectable()
export class CustomOwlDateTimeIntl extends OwlDateTimeIntl {
  // The constructor will load the translations for this plugin and set them to the properties of this class.
  // Note: Make sure the translations and the properties matches
  // https://danielykpan.github.io/date-time-picker/
  constructor(protected translate: TranslateService) {
    super();
    this.translate
      .get("date-time-picker")
      .subscribe((list) => Object.assign(this, list));
  }
}
