import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { KeyboardListenerService } from "~/app/shared/keyboard-listener.service";
import { ShortcutAction } from "@models/shortcut/shortcut.model";
import { ShortcutService } from "~/app/shared/shortcut.service";

export class DialogShortcutData {
  title: string;
  confirmLabel: string;
  cancelLabel: string;
  action: ShortcutAction;
}

@Component({
  selector: "shared-dialog-shortcut",
  templateUrl: "./dialog-shortcut.component.html",
  styleUrls: ["./dialog-shortcut.component.scss"],
})
export class DialogShortcutComponent implements OnInit, OnDestroy {
  public name = "dialog-shortcut";
  public currentShortcut = "";
  private keyPressedSubscription: Subscription;

  constructor(
    private keyboardListenerService: KeyboardListenerService,
    private keyboardShortcutService: ShortcutService,
    public dialogRef: MatDialogRef<DialogShortcutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogShortcutData
  ) {}

  ngOnInit() {
    this.currentShortcut = this.data.action.userShortcuts.length
      ? this.data.action.userShortcuts[0].shortcutKeys
      : "";
    this.keyboardShortcutService.destroyShortcutsListener();
    this.keyboardListenerService.setupListeners();
    this.keyPressedSubscription =
      this.keyboardListenerService.keyPressed$.subscribe(
        (key) => (this.currentShortcut = key)
      );
  }

  ngOnDestroy() {
    this.keyboardShortcutService.loadUserShortcuts();
    this.keyboardShortcutService.setupUserShortcuts();
    this.keyPressedSubscription.unsubscribe();
    this.keyboardListenerService.destroyListeners();
  }

  get getKeys() {
    if (this.currentShortcut.length !== 0) {
      return this.currentShortcut.split("+");
    } else {
      return [];
    }
  }

  get emptyShortcut() {
    return this.currentShortcut.length === 0;
  }

  public apply() {
    const result = {
      shortcut: this.currentShortcut.toLocaleLowerCase(),
      action: this.data.action,
    };
    this.dialogRef.close(result);
  }
}
