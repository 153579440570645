import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogConfirmData } from "../dialog-confirm/dialog-confirm.component";

@Component({
  selector: "app-dialog-confirm-replace",
  templateUrl: "./dialog-confirm-replace.component.html",
  styleUrls: ["./dialog-confirm-replace.component.scss"],
})
export class DialogConfirmReplaceComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmReplaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData
  ) {}

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }
}
