import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { FileItem, FileUploader } from "ng2-file-upload";
import { AuthenticationService } from "~/app/shared/authentication.service";
import { environment } from "~/environments/environment";
import { Subject } from "rxjs";
import { ToolsService } from "~/app/shared/tools.service";
import {
  FileUploadEventType,
  UploadAuthorizationBody,
} from "~/models/upload/file.model";
import { Observable } from "rxjs";
import { UploadAuthorization, UploadType } from "~/models/shared/upload";

export interface FileUploadEvent {
  fileItems: FileItem[] | string;
  type: FileUploadEventType;
}

export interface IUploadManager {
  uploadFeedback: Observable<any>;
  uploader: FileUploader;
  list: FileItem[];
  remove(guidName: string): void;
}

/**
 * The upload manager is the instance specific to a given uploading component list.
 */
class UploadManager implements IUploadManager {
  private fileUploader: FileUploader;
  private endPoint: string;
  private uploadFeedbackSource = new Subject<FileUploadEvent>();
  public uploadFeedback = this.uploadFeedbackSource.asObservable();

  constructor(
    category: string,
    accessToken: string,
    private api: ApiService,
    private tools: ToolsService
  ) {
    this.endPoint = environment.phoenixApiUrl + `user-file/${category}`;
    this.fileUploader = new FileUploader({
      url: environment.phoenixApiUrl + `user-file/upload/${category}`,
      authToken: "Bearer " + accessToken,
    });

    this.fileUploader.onBeforeUploadItem = this.onBeforeUploadFile;
    this.fileUploader.onCompleteItem = this.onCompleteFile;
    this.fileUploader.onAfterAddingAll = this.onAfterAddingAll;
  }

  private onBeforeUploadFile = (item: FileItem): void => {
    item.withCredentials = false;
  };

  private onCompleteFile = (item: FileItem): void => {
    this.uploadFeedbackSource.next({
      fileItems: [item],
      type: FileUploadEventType.Uploaded,
    });
  };

  private onAfterAddingAll = (fileItems: FileItem[]): void => {
    fileItems.forEach((fileItem) => {
      // We want the file name to be fully unique, so we prefix with random Uuid.
      const id = this.tools.newUuid();
      fileItem.file.name = id + "/" + fileItem.file.name;
    });

    this.uploadFeedbackSource.next({
      fileItems: fileItems,
      type: FileUploadEventType.Added,
    });
    this.fileUploader.uploadAll();
  };

  public get uploader(): FileUploader {
    return this.fileUploader;
  }

  public get list(): FileItem[] {
    return this.uploader.queue as any;
  }

  public remove(guidName: string): void {
    const fileItem = this.list.find((item) => item.file.name === guidName);
    if (fileItem) {
      if (fileItem.isUploaded) {
        this.uploader.removeFromQueue(fileItem);
        this.delete(fileItem.file.name);
      } else if (fileItem.isUploading) {
        fileItem.cancel();
        this.uploader.removeFromQueue(fileItem);
        this.delete(fileItem.file.name);
      } else {
        // upload has never started
        fileItem.cancel();
        this.uploader.removeFromQueue(fileItem);
        this.deleteNotification(fileItem.file.name);
      }
    } else {
      // Not uploaded during this session, therefore came from edition or draft
      this.delete(guidName);
    }
  }

  /**
   * Deletes existing file from the permanent storage
   */
  private delete(guidName: string): void {
    this.api.delete(`${this.endPoint}/${guidName}`).subscribe(() => {
      this.deleteNotification(guidName);
    });
  }

  private deleteNotification(guid: string): void {
    const index = this.list.findIndex((item) => item.file.name.includes(guid));
    if (index >= 0) {
      // should always be >= 0 ...
      const fileItem = this.list[index];
      this.list.splice(index, 1);
      this.uploadFeedbackSource.next({
        fileItems: [fileItem],
        type: FileUploadEventType.RemovedAsFile,
      });
    } else {
      // file is not (any more) in queue, meaning deletion happens by name
      this.uploadFeedbackSource.next({
        fileItems: guid,
        type: FileUploadEventType.RemovedByName,
      });
    }
  }
}

@Injectable()
export class FileService {
  private managers: { [key: string]: UploadManager } = {};

  constructor(
    private api: ApiService,
    public auth: AuthenticationService,
    public toolsService: ToolsService
  ) {}

  /**
   * Retrieves existing manager for the given key, or a new one otherwise.
   */
  public getManager(referenceId: string, category: string): UploadManager {
    if (!this.managers[referenceId]) {
      this.managers[referenceId] = new UploadManager(
        category,
        this.auth.accessToken,
        this.api,
        this.toolsService
      );
    }
    return this.managers[referenceId];
  }

  /**
   * When the container is destroyed, should call this one
   * This allow to cleanup managers that have no upload in progress, for memory optimization
   */
  public suspendManager(referenceId: string) {
    const manager = this.managers[referenceId];
    if (manager && !manager.list.some((file) => file.isUploading)) {
      delete this.managers[referenceId];
    }
  }

  public createUploadAuthorization(
    uploadData: UploadAuthorizationBody
  ): Observable<UploadAuthorization> {
    return this.api.post("user-file/createUploadAuthorization", uploadData);
  }

  public abortUpload(s3Key: string, uploadId: string): Observable<void> {
    return this.api.post("user-file/abortUpload", {
      s3Key,
      uploadId,
    });
  }

  public finishUpload(
    s3Key: string,
    uploadId: string,
    eTags: string[]
  ): Observable<void> {
    return this.api.post("user-file/finishUpload", {
      s3Key: s3Key,
      uploadId: uploadId,
      eTags: eTags,
    });
  }
}

export { FileItem as FileItem };
export { FileUploader as FileUploader };
