import { Injectable } from "@angular/core";
import { AuthenticationResponse } from "@models/account/account.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserHelperService {
  private userName = new BehaviorSubject("");
  private isCurrentUserAdmin = new BehaviorSubject(false);
  private currentTenantId = new BehaviorSubject("");
  sharedUserName = this.userName.asObservable();
  sharedUserType = this.isCurrentUserAdmin.asObservable();
  sharedTenantId = this.currentTenantId.asObservable();

  constructor() {}

  public static getUserIdFromLocalStorage() {
    const token = localStorage.getItem("session");
    let userId;
    if (!!token) {
      const authResponseFromStorage = JSON.parse(
        token
      ) as AuthenticationResponse;
      if (authResponseFromStorage) {
        userId = authResponseFromStorage.userId;
      }
    }
    return userId;
  }

  setUserName(name: string) {
    this.userName.next(name);
  }

  setUserType(isAdmin: boolean) {
    this.isCurrentUserAdmin.next(isAdmin);
  }
  setTenantId(tenantId: string) {
    this.currentTenantId.next(tenantId);
  }
}
