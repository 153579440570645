import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export class DialogConfirmData {
  title: string;
  message: string;
  confirmLabel: string;
  cancelLabel?: string;
  backLabel?: string;
  replacement?: string | string[];

  /**
   * Time before auto reject, in seconds
   */
  autoRejectTimeout?: number;
}

@Component({
  selector: "app-dialog-confirm-with-alert",
  templateUrl: "./dialog-confirm-with-alert.component.html",
  styleUrls: ["./dialog-confirm-with-alert.component.scss"],
})
export class DialogConfirmWithAlertComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmWithAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData
  ) {}

  ngOnInit() {}
}
