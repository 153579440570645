import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class KeyboardListenerService {
  private keyPressedSubject = new Subject<string>();

  constructor() {
    this.setupListeners();
  }

  keydownHandler = (event: KeyboardEvent) => {
    const keyCombination = this.detectKeyCombination(event);

    if (keyCombination) {
      this.keyPressedSubject.next(keyCombination);
      event.preventDefault();
    }
  };

  public setupListeners() {
    window.addEventListener("keydown", this.keydownHandler);
  }

  public destroyListeners(): void {
    window.removeEventListener("keydown", this.keydownHandler);
  }

  detectKeyCombination(event: KeyboardEvent): string | null {
    const keys: string[] = [];

    if (event.ctrlKey && event.key !== "Control") keys.push("Ctrl");
    if (event.altKey && event.key !== "Alt") {
      keys.push("Alt");
    }
    if (event.shiftKey && event.key !== "Shift") keys.push("Shift");

    if (event.key === " ") {
      keys.push("Space");
    } else if (
      !event.ctrlKey ||
      !event.altKey ||
      !event.shiftKey ||
      event.key !== "Control"
    ) {
      keys.push(event.key);
    }

    const keyCombination = keys.join("+");

    return keyCombination.length > 0 ? keyCombination : null;
  }

  get keyPressed$() {
    return this.keyPressedSubject.asObservable();
  }
}
