import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";

// Pages
import { ProjectsComponent } from "./pages/projects/projects.component";

// Services
import { TextToSpeechRoutingModule } from "~/app/textToSpeech/textToSpeech-routing.module";
import { SharedModule } from "../shared/shared.module";
import { CreateProjectComponent } from "./pages/create-project/create-project.component";
import { TextMaskModule } from "angular2-text-mask";
import { FileService } from "../shared/file.service";
import { DurationFormatterService } from "../shared/duration-formatter.service";

import { WavComponent } from "./pages/wav/wav.component";
import { ProjectDetailsComponent } from "./pages/project-details/project-details.component";
import { ScriptWritingComponent } from "./pages/script-writing/script-writing.component";
import { ScriptWritingDashboardComponent } from "./pages/script-writing-dashboard/script-writing-dashboard.component";
import { TimelineComponent } from "./pages/script-writing-dashboard/timeline/timeline.component";
import { RecordingWorkspaceComponent } from "./pages/recording-workspace/recording-workspace";
import { RecordingComponent } from "./pages/recording/recording.component";
import { MicrophoneSetupGuide } from "./pages/microphone-setup-guide/microphone-setup-guide";
import { ProjectReviewComponent } from "./pages/project-review/project-review.component";

@NgModule({
  imports: [
    TextToSpeechRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    SharedModule,
    MatSlideToggleModule,
    MatStepperModule,
    // CdkStepperModule,
    TextMaskModule,
    MatProgressBarModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatSliderModule,
  ],
  declarations: [
    ProjectsComponent,
    CreateProjectComponent,
    WavComponent,
    ProjectDetailsComponent,
    ScriptWritingComponent,
    ScriptWritingDashboardComponent,
    TimelineComponent,
    RecordingWorkspaceComponent,
    RecordingComponent,
    MicrophoneSetupGuide,
    ProjectReviewComponent,
  ],
  providers: [FileService, DurationFormatterService],
  exports: [],
})
export class TextToSpeechModule {}
