import { appConfig } from "~/environments/app.config";

export function getLanguageRegionList() {
  const languageList = appConfig.project.languages.list.map((key) => key);
  const languageRegionList: any[] = [];
  languageList.sortBy("key");

  // get region list
  const regionList = appConfig.project.regions.list;

  languageList.forEach((language) => {
    const filteredRegionList = regionList.find(
      (region) => region.key.toLowerCase() === language.value.toLowerCase()
    );
    filteredRegionList.value.forEach((regionValue) => {
      // key of language contains the fullName of the language and value has its real value
      // push items to the languageRegionList variable
      languageRegionList.push({
        isChecked: false,
        languageRegionKey:
          language.key + " - " + regionValue.value.toUpperCase(),
        languageRegionValue:
          language.value + "-" + regionValue.value.toUpperCase(),
      });
    });
  });
  return languageRegionList;
}
