import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Asset } from "@models/asset/asset.model";
import { WaveForm } from "@models/shared/waveForm.model";
import { AuthenticationService } from "../../authentication.service";

export interface DialogPlayerData {
  asset: Asset;
  detailsLabel: string;
  closeLabel: string;
}

@Component({
  selector: "app-dialog-player.component",
  templateUrl: "./dialog-player.component.html",
  styleUrls: ["./dialog-player.component.scss"],
})
export class DialogPlayerComponent implements OnInit {
  public asset: Asset;
  private waveForms: WaveForm[];
  public accessToken: string;
  constructor(
    public dialogRef: MatDialogRef<DialogPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogPlayerData,
    protected authenticationService: AuthenticationService
  ) {
    this.accessToken = this.authenticationService.accessToken;
  }

  async ngOnInit(): Promise<void> {
    this.initComponentData();
  }

  public closeToDetails() {
    this.dialogRef.close(true);
  }

  public initComponentData(): void {
    this.asset = this.data.asset;
  }

  get isAssetProxiesGenerated(): boolean {
    return this.asset?.assetProxy?.length > 0 || false;
  }
}
