import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CreateProjectComponent } from "./pages/create-project/create-project.component";
import { ProjectDetailsComponent } from "./pages/project-details/project-details.component";
import { ProjectsComponent } from "./pages/projects/projects.component";
import { ScriptWritingDashboardComponent } from "./pages/script-writing-dashboard/script-writing-dashboard.component";

const routes: Routes = [
  {
    path: "",
    component: ProjectsComponent /*, canActivate, data*/,
  },
  {
    path: "creation",
    component: CreateProjectComponent,
  },
  {
    path: "edition/:id",
    component: ProjectDetailsComponent,
  },
  {
    path: "script-writing",
    component: ScriptWritingDashboardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TextToSpeechRoutingModule {}
