export interface ShortcutAction {
    id: number;
    categorie: ShortcutCategorie;
    action: Action;
    module: Module;
    userShortcuts?: UserShortcut[];
}

export interface UserShortcut {
    id: number;
    actionId: number;
    userId: number;
    shortcutAction: ShortcutAction;
    computerType: ComputerType;
    shortcutKeys: string;
}

export enum ShortcutCategorie {
    VideoPlayer = 'videoPlayer',
    Events = 'events',
    Timeline = 'timeline',
    General = 'general',
    List = 'list',
    Studio = 'studio'
}

export enum Action {
    PlayPauseVideo = 'playPauseVideo',
    SmallJumpBack = 'smallJumpBack',
    SmallJumpForward = 'smallJumpForward',
    BigJumpBack = 'bigJumpBack',
    BigJumpForward = 'bigJumpForward',
    GoToStartVideo = 'goToStartVideo',
    GoToEndVideo = 'goToEndVideo',
    ChangeVideoSpeed = 'changeVideoSpeed',
    NewEvent = 'newEvent',
    EditModeExitEdit = 'editModeExitEdit',
    InTimecode = 'inTimecode',
    OutTimecode = 'outTimecode',
    GoToIn = 'goToIn',
    GoToOut = 'goToOut',
    NextEvent = 'nextEvent',
    PreviousEvent = 'previousEvent',
    GoToTop = 'goToTop',
    GoToBottom = 'goToBottom',
    PlayEvent = 'playEvent',
    Delete = 'delete',
    FindAndReplace = 'findAndReplace',
    UndoAction = 'undoAction',
    RedoAction = 'redoAction',
    ZoomIn = 'zoomIn',
    ZoomOut = 'zoomOut',
    Upload = 'upload',
    Download = 'download',
    ShowShortcuts = 'showShortcuts',
    Save = 'save',
    PlayPauseSelectedEvent = 'playPauseSelectedEvent',
    PlayAllEvents = 'playAllEvents',
    ExitPlaylistMode = 'exitPlaylistMode',
    AudioSoundtrack = 'audioSoundtrack',
    Filters = 'filters',
    OpenCloseStudio = 'openCloseStudio',
    FullscreenStudio = 'fullscreenStudio',
    ExitFullscreenStudio = 'exitFullscreenStudio',
    ActivateContinuousMode = 'activateContinuousMode',
    ExitContinuousMode = 'exitContinuousMode',
    StartStopRecording = 'startStopRecording',
    PlaylistSettings = 'playlistSettings',
    OpenVideoPlayer = 'openVideoPlayer',
    NewEventTcIn = 'newEventTcIn',
}

export enum Module {
    Writing = 'Writing',
    Recording = 'Recording',
    Any = 'Any'
}

export enum ComputerType {
    PC = 'PC',
    Mac = 'Mac'
}
