import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import {
  ReadOnlyReactiveProperty,
  ReactiveProperty,
} from "../shared/rx/reactiveProperty";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { Params } from "@angular/router";

@Injectable()
export class FormPageService {
  private readonly _autoRestore = new ReactiveProperty(false);
  public readonly autoRestore: ReadOnlyReactiveProperty<boolean> =
    this._autoRestore;

  constructor(protected location: Location) {}

  public setAutoRestore(autoRestore: boolean) {
    this._autoRestore.value = autoRestore;
    if (autoRestore) {
      this.location.replaceState(
        this.location.path(false).split("?")[0] + `?restore=true`
      );
    } else {
      const cleanUrl = this.location
        .path(false)
        .replace("restore=true", "")
        .replace(/\?$/, "");
      this.location.replaceState(cleanUrl);
    }
  }

  public shouldAutoRestore(
    queryParams: Observable<Params>
  ): Observable<boolean> {
    return queryParams.pipe(
      first(),
      map((params) => params.restore === "true")
    );
  }
}
