import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

interface KeyValue {
  key: string;
  value: string;
}
@Component({
  selector: "fnx-select-chips",
  templateUrl: "./fnx-select-chips.component.html",
  styleUrls: ["./fnx-select-chips.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FnxSelectChipsComponent implements OnInit, OnDestroy {
  @Input() formControl = new FormControl("");
  @Input() values: any[];
  @Input() label: string;
  @Input() disabled = false;
  @Input() isMultiSelect = true;
  @Input() placeholder: string = null;
  @Input() isSortChips = true;
  @Input() isColoredChip = false;
  @Input() setPlaceHolder = true;
  @Input() showChips = true;
  @Input() shouldBindSelectedValue = false;
  @Output() selectedValue = new EventEmitter();
  @Output() clearPreSelection = new EventEmitter();
  public preSelectPlaceHolder: string;
  public preSelectedValues: string[] = [];
  public searching = false;
  public valuesFilteringCtrl = new FormControl("");
  public filteredValues: ReplaySubject<string[] | KeyValue[]> =
    new ReplaySubject<string[] | KeyValue[]>(1);
  protected _onDestroy = new Subject<void>();
  public selectedValues: any[] = [];

  constructor(
    protected cd: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  public ngOnInit() {
    this.filteredValues.next(this.values?.slice());
    this.valuesFilteringCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterValues();
      });
    if (typeof this.formControl.value !== "string") {
      this.populateChips(this.formControl.value);
    }
    if (Array.isArray(this.formControl.value) && this.setPlaceHolder) {
      this.setPreSelectPlaceHolder();
    }

    this.formControl.valueChanges.subscribe((selectedValue: any) => {
      this.populateChips(selectedValue);
    });
  }

  public onValuesChanges(): void {
    this.filteredValues.next(this.values?.slice());
  }

  private populateChips(values: any[]) {
    if (Array.isArray(values) && values?.length && !this.isSortChips) {
      values.forEach((value: string) => {
        if (!this.selectedValues.includes(value)) {
          this.selectedValues.push(value);
        }
        this.selectedValues = this.selectedValues.filter(
          (v: string) => v && typeof v === "string" && values.includes(v)
        );
      });
    } else if (
      Array.isArray(this.formControl.value) &&
      this.formControl?.value?.length
    ) {
      this.selectedValues = this.formControl.value?.filter(
        (v: string) => v && typeof v === "string"
      );
    } else {
      this.selectedValues = [];
    }
  }

  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterValues() {
    if (!this.values) {
      return;
    }
    // get the search keyword
    let search = this.valuesFilteringCtrl.value;
    if (!search) {
      this.filteredValues.next(this.values.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredValues.next(
      this.values.filter((value: any) => {
        if (value?.key) {
          return value.key.toLowerCase().indexOf(search) > -1;
        } else {
          return value.toLowerCase().indexOf(search) > -1;
        }
      })
    );
  }

  public remove(tag: string): void {
    const tags = (this.formControl.value as any).filter((val: any) => !!val);
    const index = tags.indexOf(tag);
    tags.splice(index, 1);
    if (index >= 0) {
      this.formControl.patchValue(tags);
      this.selectedValues = tags;
    }
  }

  public onSelectionChange(event: any) {
    if (!this.isMultiSelect) {
      this.selectedValue.emit(event.value);
    }
  }

  public clearPreSelectedValues() {
    this.preSelectedValues = [];
    this.preSelectPlaceHolder = undefined;
    this.clearPreSelection.emit();
  }

  public setPreSelectPlaceHolder() {
    this.preSelectedValues = this.formControl.value as any;
    this.formControl.setValue("");
    if (this.preSelectedValues.length > 1) {
      this.preSelectPlaceHolder =
        this.preSelectedValues[0] +
        this.translateService
          .instant("assignPopup.multipleUsers")
          .replace("{0}", this.preSelectedValues.length - 1);
    } else {
      this.preSelectPlaceHolder = this.preSelectedValues[0];
    }
  }

  // It will check this.values is string array or not
  private get isStringArray(): boolean {
    return this.values.every((item) => typeof item === "string");
  }

  // It will check this.values is array of object and object contain key and value property in object or not
  private get isObjectArray(): boolean {
    return this.values.every(
      (item) => typeof item === "object" && "key" in item && "value" in item
    );
  }

  public bindSelectedOption(): string {
    if (this.shouldBindSelectedValue && !!this.formControl.value) {
      if (this.isStringArray) {
        return this.formControl.value;
      }
      if (this.isObjectArray) {
        return this.values.find((item) => item.value == this.formControl.value)
          .key;
      }
    }
    return this.translateService.instant(
      "deliver.deliverForm.destination.specificationsPage.inputsLabels.selectOption"
    ) as string;
  }

  get isColoredChipEnabled(): boolean {
    return this.isColoredChip;
  }
}
