import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AccountService } from "../account.service";
import { tap, mergeMap } from "rxjs/operators";
import {
  strictMinLength,
  isEqualTo,
  hasMixedCase,
  hasNumber,
  hasSpecialChars,
} from "~/app/shared/validators";

@Component({
  selector: "account-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public validToken: any = true;
  public inProcess = false;
  public email: string;
  private token: string;
  public isResetSuccess: boolean;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService
  ) {
    this.formGroup = this.formBuilder.group({
      newPassword: [
        "",
        Validators.compose([
          strictMinLength(8), // I don't found the way to get if this rule fail withe Validators.min(8),
          hasMixedCase,
          hasNumber,
          hasSpecialChars,
        ]),
      ],
      confirmPassword: [
        "",
        Validators.compose([Validators.required, isEqualTo("newPassword")]),
      ],
    });

    // If newPassword changed, validity of confirmation must be updated too.
    this.formGroup.controls.newPassword.valueChanges.subscribe((_) => {
      this.formGroup.controls.confirmPassword.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        tap((params) => (this.token = params["token"])),
        mergeMap((_) => this.accountService.validateToken(this.token))
      )
      .subscribe((isValid) => (this.validToken = isValid));
  }

  isOK(fieldName: string, errorKey: string) {
    return (
      !!this.formGroup.get(fieldName).value &&
      !this.formGroup.get(fieldName).hasError(errorKey)
    );
  }

  send() {
    this.inProcess = true;
    this.accountService
      .resetPassword(this.formGroup.get("newPassword").value, this.token)
      .subscribe(
        (_) => {
          this.inProcess = false;
          this.isResetSuccess = true;
        },
        (e) => {
          this.inProcess = false;
        }
      );
  }
}
