import { Proxy } from "@models/shared/proxy.model";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AuthenticationService } from "../../authentication.service";
import {
  AssetDescription,
  SmtpeController,
  SmpteTimestamp,
} from "./FrameAccurateControls";
import { secondsTimeToFrames } from "~/utils/project";
import { ShortcutService } from "../../shortcut.service";
import { Action } from "@models/shortcut/shortcut.model";
import { Subscription } from "rxjs";
import { getTimeInSecondsFromFrameRate } from "~/utils/project";
import videojs from "video.js";
import "videojs-contrib-eme";
import { environment } from "~/environments/environment";

@Component({
  selector: "video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
})
export class VideoPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("videoContainer", { static: true })
  private videoContainerElement: ElementRef;

  @Input() width = "100%";
  @Input() height = "100%";
  @Input() assetId: string;
  @Input() proxyList: Proxy[];
  @Input() thumbnail: string;
  @Input() startTimecode: string = "00:00:00:00";
  @Input() frameRate: number;
  @Input() isTimeCodeRequired = false;
  @Input() isPictureInPicture = false;
  @Input() eventTcIn: string;
  @Input() eventTcOut: string;

  @Output() playerInit = new EventEmitter<boolean>();
  @Output() isPlayingEvent = new EventEmitter<boolean>();

  private _player: any;
  private source = {};
  private smtpeController: SmtpeController;
  private shortcutTriggeredSubscription: Subscription;
  private _config: any;
  private _timeUpdateListener: () => void;
  private secondsStartTimecode = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private keyboardShortcutService: ShortcutService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.buildSource();
    this.createPlayerInstance();
    this.shortcutTriggeredSubscription =
      this.keyboardShortcutService.shortcuts$.subscribe((shortcut) => {
        if (shortcut === Action.PlayPauseVideo) {
          if (this._player.paused()) {
            this._player.play();
          } else {
            this._player.pause();
          }
        } else if (shortcut === Action.SmallJumpBack) {
          const currentTime = this._player.currentTime();
          this._player.currentTime(currentTime - 1 / this.frameRate);
        } else if (shortcut === Action.SmallJumpForward) {
          const currentTime = this._player.currentTime();
          this._player.currentTime(currentTime + 1 / this.frameRate);
        } else if (shortcut === Action.BigJumpBack) {
          const currentTime = this._player.currentTime();
          this._player.currentTime(currentTime - 2);
        } else if (shortcut === Action.BigJumpForward) {
          const currentTime = this._player.currentTime();
          this._player.currentTime(currentTime + 2);
        } else if (shortcut === Action.GoToStartVideo) {
          this._player.currentTime(0);
        } else if (shortcut === Action.GoToEndVideo) {
          this._player.currentTime(this._player.duration());
        } else if (shortcut === Action.GoToIn) {
          this._player.currentTime(
            this.tcToSecondsNoStartTimecode(this.eventTcIn)
          );
        } else if (shortcut === Action.GoToOut && this.eventTcOut) {
          this._player.currentTime(
            this.tcToSecondsNoStartTimecode(this.eventTcOut)
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.shortcutTriggeredSubscription.unsubscribe();
  }

  public playPauseEvent() {
    this.playInterval(
      this.tcToSecondsNoStartTimecode(this.eventTcIn),
      this.tcToSecondsNoStartTimecode(this.eventTcOut)
    );
  }

  public isFullScreenMode(): boolean {
    if (!this.videoContainerElement.nativeElement.attributes["data-fullscreen"])
      return false;
    return (
      this.videoContainerElement.nativeElement.attributes["data-fullscreen"]
        .value === "true"
    );
  }

  private buildSource() {
    let laUrl =
      environment.phoenixApiUrl + "video/licenseAcquisition?drm-type=widevine";
    if (this.assetId) {
      laUrl += "&assetId=" + this.assetId;
    }
    const dashURL = this.getDashProxyKey();
    this._config = {
      src: dashURL,
      type: "application/dash+xml",
      keySystems: {
        "com.widevine.alpha": {
          url: laUrl,
          audioContentType: 'audio/webm; codecs="vorbis"',
          videoContentType: 'video/webm; codecs="vp9"',
        },
      },
    };
  }
  private customFormatTimeCallback(seconds: any, guide?: any) {
    if (this.startTimecode) {
      seconds = seconds + this.secondsStartTimecode;
    }
    return this.smtpeController.secondToSMPTE(seconds);
  }

  private tcToSecondsNoStartTimecode(tc: any) {
    if (this.startTimecode) {
      return (
        getTimeInSecondsFromFrameRate(tc, this.frameRate) -
        getTimeInSecondsFromFrameRate(this.startTimecode, this.frameRate)
      );
    } else {
      return getTimeInSecondsFromFrameRate(tc, this.frameRate);
    }
  }

  private createPlayerInstance(): void {
    if (this.startTimecode) {
      const assetDescription = this.getConvertedAssetForFrameAccuracy();
      const smpte = new SmpteTimestamp(this.startTimecode, assetDescription);
      const debugSmpte = smpte.toString();
      this.secondsStartTimecode = smpte.toAdjustedTime();
    }
    const poster = this.thumbnail;
    const options = {
      preload: "auto",
      responsive: true,
      fluid: true,
      bigPlayButton: true,
      controls: true,
      controlBar: {
        remainingTimeDisplay: false,
        progressControl: {
          seekBar: {
            playProgressBar: {
              timeTooltip: false,
            },
          },
        },
        skipButtons: {
          forward: environment.seekForward,
          backward: environment.seekBackward,
        },
      },
      poster: poster,
    };
    this._player = videojs(this.videoContainerElement.nativeElement, options);
    this._player.eme();
    this._player.src(this._config);
    if (!this.smtpeController) {
      this.smtpeController = new SmtpeController(
        this._player,
        this.getConvertedAssetForFrameAccuracy()
      );
    }
    videojs.time.setFormatTime(this.customFormatTimeCallback.bind(this));
  }

  public playInterval(start: number, end: number) {
    if (this._timeUpdateListener) {
      this._player.off("timeupdate", this._timeUpdateListener);
      this._player?.off("seeking", this.seekListener);
    }
    this._player.currentTime(start);
    this._player.play();
    this._timeUpdateListener = () => this.timeChangedListener(end);
    this._player.on("timeupdate", this._timeUpdateListener);
  }

  private getConvertedAssetForFrameAccuracy(): AssetDescription {
    const toConvert = {
      name: "asset-name",
      source: this.source,
      framesPerSecond: this.frameRate,
    };

    // name, sourceConfig, framesPerSecond, adjustmentFactor, framesDroppedAtFullMinute
    return new AssetDescription(
      toConvert.name,
      toConvert.source,
      toConvert.framesPerSecond
    );
  }

  private getAuthorizationToken(): string {
    return this.authenticationService.accessToken;
  }

  private timeChangedListener = (end: number) => {
    this._player?.on("seeking", this.seekListener);
    if (this._player.currentTime() >= end) {
      this._player.pause();
      this._player.currentTime(end);
      this.isPlayingEvent.emit(false);
    }
  };

  private seekListener = () => {
    this._player.off("timeupdate", this._timeUpdateListener);
    this._player?.off("seeking", this.seekListener);
    this.isPlayingEvent.emit(false);
  };

  private getDashProxyKey(): string {
    return this.proxyList.find((proxy) => proxy.type.includes("dash")).key;
  }
}
