import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import Recordings from "wavesurfer.js/dist/plugins/record";
import WaveSurfer from "wavesurfer.js";

@Component({
  selector: "microphone-setup-guide",
  templateUrl: "./microphone-setup-guide.html",
  styleUrls: ["./microphone-setup-guide.scss"],
})
export class MicrophoneSetupGuide implements OnInit, AfterViewInit {
  @Output() microphoneSelectionChange = new EventEmitter();
  @Output() exitSetup = new EventEmitter();
  @Input() microphoneDeviceId: string;
  public audioInputDevice = "";
  public microphoneList: { deviceId: string; label: string }[] = [];
  private wavesurfer: any;
  private record: any;
  private selectedMicrophoneId: string = null;
  // no translations
  public audioChecklist: string[] = [
    "setupGuide.audioQualityChecklist.audioList.echoFree",
    "setupGuide.audioQualityChecklist.audioList.noBackgroundNoise",
  ];

  public volumeChecklist: string[] = [
    "setupGuide.audioQualityChecklist.volumeList.one",
    "setupGuide.audioQualityChecklist.volumeList.two",
    "setupGuide.audioQualityChecklist.volumeList.three",
    "setupGuide.audioQualityChecklist.volumeList.four",
  ];

  public materialsChecklist: string[] = [
    "setupGuide.audioQualityChecklist.materialsList.one",
    "setupGuide.audioQualityChecklist.materialsList.two",
    "setupGuide.audioQualityChecklist.materialsList.three",
    "setupGuide.audioQualityChecklist.materialsList.four",
  ];

  constructor(private cdr: ChangeDetectorRef) {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createWaveSurfer();
      this.initializeRecordPlugin();
    }, 1000);
  }

  ngOnInit(): void {
    this.getMicOptions();
  }

  public ngOnDestroy(): void {
    this.record?.stopRecording();
  }

  private getMicOptions(): void {
    Recordings.getAvailableAudioDevices().then((devices) => {
      this.microphoneList = devices;
      setTimeout(() => {
        if (this.microphoneDeviceId) {
          this.audioInputDevice = this.microphoneDeviceId;
          this.cdr.detectChanges();
        } else {
          this.audioInputDevice = this.microphoneList[0].deviceId;
        }
      }, 500);
    });
  }

  public startListening(): void {
    if (this.record.isRecording()) {
      this.record.stopRecording();
    }
    this.showWaves();
  }

  public onMicrophoneSelectionChange(eventValue: string): void {
    this.selectedMicrophoneId = eventValue;
    this.startListening();
    this.microphoneSelectionChange.emit(this.selectedMicrophoneId);
  }

  private showWaves(): void {
    const deviceId = this.selectedMicrophoneId;
    this.createWaveSurfer();
    this.initializeRecordPlugin();
    this.record.startRecording({ deviceId });
  }

  private createWaveSurfer(recordedUrl: string = null): void {
    // Create an instance of WaveSurfer
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
    this.wavesurfer = WaveSurfer.create({
      container: "#waveform",
      waveColor: "#a2cbe0",
      progressColor: "#5DA5D9",
      minPxPerSec: 100,
      barWidth: 4,
      barGap: 4,
      cursorColor: "#3CA8DE",
      height: 107,
      url: recordedUrl,
    });
  }

  private initializeRecordPlugin(): void {
    this.record = this.wavesurfer.registerPlugin(
      Recordings.create({
        scrollingWaveform: false,
        renderRecordedAudio: false,
      })
    );
  }

  private exitMicrophoneSetup() {
    this.record.stopRecording();
    this.exitSetup.emit();
  }
}
