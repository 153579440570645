import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class DurationFormatterService {
  constructor(private translateService: TranslateService) {}

  /** method responsible for displaying duration in human readable format. Examples:
   *              2 hours 33 minutes
   *              57 min 18 seconds
   *              23 seconds
   */

  public getUserReadableMessage(
    durationInMillis: number,
    moduleName: string,
    timeEntryDictionary: string
  ): string {
    const durationMessage = this.translateService.instant(
      `${moduleName}.${timeEntryDictionary}`
    );
    if (!isFinite(durationInMillis)) {
      return durationMessage;
    }
    if (durationInMillis / 1000 / 60 < 1) {
      // time displayed in seconds only
      const timeInSeconds = Math.floor(durationInMillis / 1000);
      let secondsUnit = this.translateService.instant(`${moduleName}.seconds`);
      if (timeInSeconds <= 1) {
        secondsUnit = this.translateService.instant(`${moduleName}.second`);
      }
      return durationMessage + " " + timeInSeconds + " " + secondsUnit;
    } else if (
      1 < durationInMillis / 1000 / 60 &&
      durationInMillis / 1000 / 60 < 59
    ) {
      // time displayed in minutes and seconds
      let minutesUnit = this.translateService.instant(`${moduleName}.minutes`);
      let secondsUnit = this.translateService.instant(`${moduleName}.seconds`);
      const timeInMinutes = Math.floor(durationInMillis / 1000 / 60);
      if (timeInMinutes <= 1) {
        minutesUnit = this.translateService.instant(`${moduleName}.minute`);
      }
      const remainingSeconds = Math.floor(
        (durationInMillis - timeInMinutes * 60 * 1000) / 1000
      );
      if (remainingSeconds <= 1) {
        secondsUnit = this.translateService.instant(`${moduleName}.second`);
      }
      return (
        durationMessage +
        " " +
        timeInMinutes +
        " " +
        minutesUnit +
        " " +
        remainingSeconds +
        " " +
        secondsUnit
      );
    } else {
      // else 59 < estimatedRemainingTime
      // time displayed in hours and minutes
      const timeInHours = Math.floor(durationInMillis / 1000 / 60 / 60);
      const remainingMillis = durationInMillis - timeInHours * 60 * 60 * 1000;
      const remainingMinutes = Math.floor(remainingMillis / (60 * 1000));
      let hoursUnit = this.translateService.instant(`${moduleName}.hours`);
      let minutesUnit = this.translateService.instant(`${moduleName}.minutes`);
      if (timeInHours <= 1) {
        hoursUnit = this.translateService.instant(`${moduleName}.hour`);
      }
      if (remainingMinutes <= 1) {
        minutesUnit = this.translateService.instant(`${moduleName}.minute`);
      }
      return (
        durationMessage +
        " " +
        timeInHours +
        " " +
        hoursUnit +
        " " +
        remainingMinutes +
        " " +
        minutesUnit
      );
    }
  }
}
