import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { matchWordOccurrences } from "~/utils/project";

@Pipe({
  name: "highlightSearch",
})
export class HighlightSearchPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }
    const match = matchWordOccurrences(value, args);
    const regex = RegExp(
      "(?<![-0-9A-Za-zÀ-ÿœēčŭ])" + args + "(?![-0-9A-Za-zÀ-ÿœēčŭ])",
      "g"
    );
    // If there's no match, just return the original value.
    if (!match) {
      return value;
    }

    const replacedValue = value.replaceAll(
      regex,
      "<mark>" + match[0] + "</mark>"
    );
    return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
  }
}
