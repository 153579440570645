import { Observable, of, timer } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Component, OnInit, Inject } from "@angular/core";
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
// import { hasMixedCase, hasNumber, hasSpecialChars, isEqualTo, strictMinLength } from '../../../shared/validators';
import { InvalidPassword } from "@models/shared/errors/InvalidPassword";
import { WrongOldPassword } from "@models/shared/errors/WrongOldPassword";
import { AccountService } from "../account.service";
import {
  strictMinLength,
  hasMixedCase,
  hasNumber,
  hasSpecialChars,
  isEqualTo,
} from "~/app/shared/validators";

@Component({
  selector: "account-dialog-change-password",
  templateUrl: "./dialog-change-password.component.html",
  styleUrls: ["./dialog-change-password.component.scss"],
})
export class DialogChangePasswordComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public answerCode?: string;
  public inProcess = false;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected account: AccountService,
    protected dialogRef: MatDialogRef<DialogChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string }
  ) {
    this.formGroup = this.formBuilder.group({
      oldPassword: ["", Validators.compose([Validators.required])],
      newPassword: [
        "",
        Validators.compose([
          strictMinLength(8),
          hasMixedCase,
          hasNumber,
          hasSpecialChars,
        ]),
      ],
      confirmPassword: [
        "",
        Validators.compose([Validators.required, isEqualTo("newPassword")]),
      ],
    });

    // If newPassword changed, validity of confirmation must be updated too.
    this.formGroup.controls.newPassword.valueChanges.subscribe((_) => {
      this.formGroup.controls.confirmPassword.updateValueAndValidity();
    });
  }

  ngOnInit(): void {}

  changePassword() {
    this.inProcess = true;
    this.account
      .changePassword(
        this.formGroup.get("oldPassword").value,
        this.formGroup.get("newPassword").value
      )
      .subscribe(
        () => {
          this.inProcess = false;
          this.dialogRef.close();
        },
        (e) => {
          this.inProcess = false;
          this.answerCode = e.error.code;
        }
      );
  }

  isOK(fieldName: string, errorKey: string) {
    return (
      !!this.formGroup.get(fieldName).value &&
      !this.formGroup.get(fieldName).hasError(errorKey)
    );
  }

  doesNotFitPolicy(): boolean {
    return this.answerCode === InvalidPassword.codeConst;
  }

  wrongOldPassword(): boolean {
    return this.answerCode === WrongOldPassword.codeConst;
  }

  passwordKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.changePassword();
    }
  }
}
