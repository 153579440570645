import { WaveForm } from "@models/shared/waveForm.model";
import {
  Component,
  ViewChild,
  Output,
  ElementRef,
  Input,
  OnDestroy,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { MatSlider, MatSliderChange } from "@angular/material/slider";
import { FormArray, FormGroup } from "@angular/forms";
import { Project, ProjectReply } from "@models/textToSpeech/project.model";
import { Action, Module } from "@models/shortcut/shortcut.model";
import { ShortcutService } from "~/app/shared/shortcut.service";
import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline";
import ZoomPlugin from "wavesurfer.js/dist/plugins/zoom";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import { Asset } from "@models/asset/asset.model";
import {
  getTimeInSecondsFromFrameRate,
  secondsTimeToFramesStartTimeCode,
} from "~/utils/project";

interface ProjectReplyUI extends ProjectReply {
  div?: any;
  overlapMessage?: string;
}

@Component({
  selector: "timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
})
export class TimelineComponent implements OnDestroy {
  @ViewChild("playingHead", { static: false }) playingHead: any;
  @ViewChild("playingHeadBar", { static: false }) playingHeadBar: any;
  @ViewChild("slider", { static: false }) slider: MatSlider;

  @Input("waveForms") public waveForms: WaveForm[];
  @Input() public replyForm: FormGroup;
  @Input() public asset: Asset;
  @Input() public project: Project;
  @Output() updatedEvent = new EventEmitter();
  @Output() selectEvent = new EventEmitter();

  public max = 500;
  public min = 5;
  public step = 20;
  public sliderValue = 5;
  public isShortcutsTabsActive = false;
  public events: ProjectReplyUI[] = [];
  private shortcutTriggeredSubscription: Subscription;
  private module = Module.Writing;
  private wavesurfer: WaveSurfer;
  private topTimeline: any;
  private wavesurferRegions: any;
  private activeRegion: any;
  private clicked: boolean;
  private zoom: any;

  constructor(private keyboardShortcutService: ShortcutService) {}

  ngOnInit(): void {
    this.shortcutTriggeredSubscription =
      this.keyboardShortcutService.shortcuts$.subscribe((shortcut) => {
        if (shortcut === Action.ZoomIn) {
          this.zoomInOut(this.slider.value + 20);
        } else if (shortcut === Action.ZoomOut) {
          this.zoomInOut(this.slider.value - 20);
        } else if (shortcut === Action.ShowShortcuts) {
          this.isShortcutsTabsActive
            ? (this.isShortcutsTabsActive = false)
            : (this.isShortcutsTabsActive = true);
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.asset) {
      this.topTimeline = TimelinePlugin.create({
        insertPosition: "beforebegin",
        height: 60,
        formatTimeCallback: this.customFormatTimeCallback.bind(this),
        timeInterval: this.timeInterval.bind(this),
        primaryLabelInterval: this.primaryLabelInterval.bind(this),
        secondaryLabelInterval: this.secondaryLabelInterval.bind(this),
        style: {
          fontSize: "20px",
          color: "#7A7A7A",
          marginTop: "-30px",
          background: "#F4F0EC",
        },
        textStyle: {
          marginTop: "35px",
        },
      });
      (this.zoom = ZoomPlugin.create({
        scale: 0.05,
        maxZoom: this.max,
      })),
        (this.wavesurfer = WaveSurfer.create({
          container: "#waveform",
          height: 213.4,
          barHeight: 0.65,
          waveColor: "#8DD0FF",
          progressColor: "#3CA8DE",
          dragToSeek: true,
          cursorColor: "#3CA8DE",
          cursorWidth: 2,
          media: document.querySelector("video"),
          peaks: this.waveForms[0].data as any[],
          duration: Number(this.asset?.duration),
          marginTop: 30,
          plugins: [this.topTimeline, this.zoom],
        }));

      this.wavesurfer.once("decode", () => {
        this.wavesurfer.zoom(this.min);
        this.refreshRegions();
      });
    }
  }

  public ngOnDestroy(): void {
    this.shortcutTriggeredSubscription.unsubscribe();
    this.wavesurferRegions?.unAll();
  }

  public refreshRegions(i?: number) {
    this.activeRegion = null;
    this.events = (this.replyForm.get("events") as FormArray).controls.map(
      (event: any) => event.value
    );
    this.wavesurferRegions?.unAll();
    this.wavesurferRegions?.destroy();
    this.wavesurferRegions = this.wavesurfer.registerPlugin(
      RegionsPlugin.create()
    );
    this.events.forEach((event, index) => {
      const count = index + 1 < 10 ? `0${index + 1}.` : `${index + 1}.`;
      const color =
        event.overlapMessage === "" ? "#CEEBFF99" : "rgba(226, 71, 35, 0.3)";
      const startTimeCode =
        this.project.startTimeCode !== "00:00:00:00"
          ? this.project.startTimeCode.replace(";", ":")
          : this.asset.startTimeCode
          ? this.asset.startTimeCode.replace(";", ":")
          : "00:00:00:00";
      const start = startTimeCode
        ? getTimeInSecondsFromFrameRate(
            event.tcIn,
            Number(this.asset.frameRate)
          ) -
          getTimeInSecondsFromFrameRate(
            startTimeCode,
            Number(this.asset.frameRate)
          )
        : getTimeInSecondsFromFrameRate(
            event.tcIn,
            Number(this.asset.frameRate)
          );
      const end = startTimeCode
        ? getTimeInSecondsFromFrameRate(
            event.tcOut,
            Number(this.asset.frameRate)
          ) -
          getTimeInSecondsFromFrameRate(
            startTimeCode,
            Number(this.asset.frameRate)
          )
        : getTimeInSecondsFromFrameRate(
            event.tcOut,
            Number(this.asset.frameRate)
          );
      this.wavesurferRegions.addRegion({
        id: index + 1,
        start: start,
        end: end,
        content: count + event.ttmlSegmentData,
        color: color,
        resize: true,
      });
    });
    this.addRegionsListener();
    if (i !== undefined) {
      this.selectEventToRecord(i);
    }
  }

  public addRegionsListener() {
    this.wavesurferRegions.on(
      "region-updated",
      this.regionUpdatedListener.bind(this)
    );
    this.wavesurferRegions?.on(
      "region-clicked",
      this.regionClickedListener.bind(this)
    );

    this.wavesurfer.on("zoom", (minPxPerSec) => {
      this.slider.value = `${Math.round(minPxPerSec)}`;
      this.sliderValue = this.slider.value;
    });
  }

  private regionUpdatedListener = (region: any, e: any) => {
    const index = region.id - 1;
    const event = (this.replyForm.get("events") as FormArray).at(index);
    event
      .get("tcIn")
      .setValue(
        secondsTimeToFramesStartTimeCode(region.start, this.asset, this.project)
      );
    event
      .get("tcOut")
      .setValue(
        secondsTimeToFramesStartTimeCode(region.end, this.asset, this.project)
      );
    this.replyForm.updateValueAndValidity();
    this.updatedEvent.emit({ index, reply: event.value });
  };

  private regionClickedListener = (region: any, e: any) => {
    this.replyForm.updateValueAndValidity();
    const index = region.id - 1;
    this.selectEvent.emit(index);
    const event = (this.replyForm.get("events") as FormArray).at(index);
    this.clicked = true;
    if (this.activeRegion) {
      const prevEvent = (this.replyForm.get("events") as FormArray).at(
        this.activeRegion.id - 1
      );
      if (prevEvent.get("overlapMessage").value === "") {
        this.activeRegion.setOptions({ color: "#CEEBFF99" });
      } else {
        this.activeRegion.setOptions({ color: "rgba(226, 71, 35, 0.3)" });
      }
    }
    e.stopPropagation(); // prevent triggering a click on the waveform
    this.activeRegion = region;
    if (event.get("overlapMessage").value === "") {
      region?.setOptions({ color: "#3CA8DE80" });
    } else {
      region?.setOptions({ color: "rgba(226, 71, 35, 0.6)" });
    }
  };

  private addRegionsListenerInOut() {
    this.wavesurferRegions.on("region-in", this.inListener);
    this.wavesurferRegions.on("region-out", this.outListener);
  }

  private inListener = (region: any) => {
    region?.setOptions({ color: "#3CA8DE80" });
    this.activeRegion = region;
  };

  private outListener = (region: any) => {
    if (this.clicked) {
      this.wavesurfer.pause();
      this.clicked = false;
    }

    if (this.activeRegion) {
      region?.setOptions({ color: "#CEEBFF99" });
    }
    this.activeRegion = null;
  };

  private onSliderChange(event: MatSliderChange) {
    this.sliderValue = event.value;
    this.wavesurfer.zoom(this.sliderValue);
  }

  timeInterval(pxPerSec: number) {
    let retval = 1;
    const ratio = !Number.isInteger(Number(this.asset.frameRate))
      ? 1 / 20
      : 1 / Number(this.asset.frameRate);
    if (this.sliderValue >= 400) {
      retval = ratio;
    } else if (this.sliderValue >= 300) {
      retval = ratio * 2;
    } else if (this.sliderValue >= 200) {
      retval = 1;
    } else if (this.sliderValue >= 100) {
      retval = 5;
    } else if (this.sliderValue >= 50) {
      retval = 10;
    } else if (this.sliderValue >= 30) {
      retval = 15;
    } else if (this.sliderValue >= 20) {
      retval = 30;
    } else if (this.sliderValue >= 10) {
      retval = 60;
    } else {
      retval = 240;
    }
    return retval;
  }

  secondaryLabelInterval(pxPerSec: number) {
    // draw one every 10s as an example
    if (
      this.primaryLabelInterval(this.sliderValue) === 120 ||
      this.primaryLabelInterval(this.sliderValue) === 480
    ) {
      return 0;
    } else {
      return this.primaryLabelInterval(this.sliderValue) / 2;
    }
  }

  primaryLabelInterval(pxPerSec: number) {
    if (this.timeInterval(this.sliderValue) * 2 >= 1) {
      return this.timeInterval(this.sliderValue) * 2;
    } else {
      return 1;
    }
  }

  private customFormatTimeCallback(seconds: any, pxPerSec: number) {
    return secondsTimeToFramesStartTimeCode(seconds, this.asset, this.project);
  }

  private showShortcutsTabs() {
    this.isShortcutsTabsActive = true;
  }

  private exitShortcutsSetup() {
    this.isShortcutsTabsActive = false;
  }

  public selectEventToRecord(index: number) {
    const event = (this.replyForm.get("events") as FormArray).at(index);
    const region = this.wavesurferRegions.getRegions()[index];
    if (this.activeRegion) {
      const prevEvent = (this.replyForm.get("events") as FormArray).at(
        this.activeRegion.id - 1
      );
      if (prevEvent?.get("overlapMessage").value === "") {
        this.activeRegion.setOptions({ color: "#CEEBFF99" });
      } else {
        this.activeRegion.setOptions({ color: "rgba(226, 71, 35, 0.3)" });
      }
    }
    this.activeRegion = region;
    if (event?.get("overlapMessage").value === "") {
      region?.setOptions({ color: "#3CA8DE80" });
    } else {
      region?.setOptions({ color: "rgba(226, 71, 35, 0.6)" });
    }
    if (!this.wavesurfer.isPlaying() && !event?.get("isNew").value) {
      this.wavesurfer?.setTime(this.activeRegion?.start);
    }
  }

  zoomInOut(currentValue: number) {
    if (currentValue <= this.max && currentValue >= this.min) {
      if (currentValue !== this.slider.value) {
        this.slider.value = currentValue;
      }
      this.sliderValue = this.slider.value;
      this.wavesurfer.zoom(this.sliderValue);
    }
  }
}
