import { ShowcaseError } from './showcaseError';

export class InvalidPassword extends Error implements ShowcaseError {
    public static codeConst = 'InvalidPassword';
    status = 400;
    code = InvalidPassword.codeConst;
    constructor() {
        super(`The new password is invalid`);
    }
}
