import { getValueByPropertyPath } from './object';

type Comparator<T> = (a: T, b: T) => number;
type Comparable = number | string;

export function compareBy(key: string, reversed = false): (a: any, b: any) => number {
    return scoreComparator(a => a[key], !reversed);
}

export function localCompare(reversed = false): (a: string, b: string) => number {
    return (a, b) => {
        const aVal = (a || '').trim();
        const bVal = (b || '').trim();
        const order = aVal.localeCompare(bVal); // Allow case insensitive + proper accentuated letter handling sort
        return reversed ? -order : order;
    };
}

export function localCompareBy(key: string, reversed = false): (a: any, b: any) => number {
    return (a, b) => {
        const aVal = (a[key] || '').toString().trim();
        const bVal = (b[key] || '').toString().trim();
        const order = aVal.localeCompare(bVal); // Allow case insensitive + proper accentuated letter handling sort
        return reversed ? -order : order;
    };
}

export function compareByPropertyPath<T>(key: string): Comparator<T> {
    return scoreComparator(a => getValueByPropertyPath(a, key), true);
}

export function localCompareByPropertyPath<T>(key: string, reversed = false): Comparator<T> {
    return (a, b) => {
        const aVal = (getValueByPropertyPath(a, key) || '').toString().trim();
        const bVal = (getValueByPropertyPath(b, key) || '').toString().trim();
        const order = aVal.localeCompare(bVal); // Allow case insensitive + proper accentuated letter handling sort
        return reversed ? -order : order;
    };
}

export function sortBy<T>(list: T[], argument: string | ((a: T) => Comparable)): T[] {
    const newList = list.slice(0);

    if (typeof argument === 'string') {
        newList.sort(compareBy(argument));
        return newList;
    }

    newList.sort(scoreComparator(argument, true));
    return newList;
}

/**
 * Returns a comparator function using scoring function
 * @param scoringFunc The scoring function
 * @param lowerToHigher If `true` order the items from the lowest score to the highest one. Otherwise, from the highest to the lowest.
 */
export function scoreComparator<T>(scoringFunc: ((item: T) => Comparable), lowerToHigher = false): Comparator<T> {
    return (a: T, b: T) => {
        const scoreA = scoringFunc(a);
        const scoreB = scoringFunc(b);
        let order = 0;

        if (scoreA < scoreB) {
            order = 1;
        } else if (scoreA > scoreB) {
            order = -1;
        }
        return lowerToHigher ? -order : order;
    };
}

/**
 * Allow to chain multiple comparator, each one called the break equality from the previous one.
 */
export function chainedComparator<T>(...comparators: Comparator<T>[]): Comparator<T> {
    return (a: T, b: T) => {
        let order = 0;
        let i = 0;

        while (!order && comparators[i]) {
            order = comparators[i++](a, b);
        }

        return order;
    };
}
