import { Component, OnInit } from "@angular/core";
import { VersionService } from "../version.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  versionNumber: any;
  versionData: any[];

  constructor(version: VersionService) {
    this.versionData = version.dataArray;
    this.inItVersionNumber();
  }

  ngOnInit() {}

  inItVersionNumber() {
    this.versionData.forEach((element) => {
      const transformedVersionData = this.transformVersionData(element, []);
      transformedVersionData.forEach((e2: { key: string; value: any }) => {
        if (e2.key === "packageVersion") {
          this.versionNumber = e2.value;
        }
      });
    });
  }

  transformVersionData(value: any, args: string[]): any {
    const keys = [];
    for (const key of Object.keys(value)) {
      keys.push({ key: key, value: value[key] });
    }
    return keys;
  }
}
