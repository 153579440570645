import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { LogoutConfirmDialogComponent } from "./logout-confirm-dialog/logout-confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { UserHelperService } from "../userHelper.service";
import { environment } from "~/environments/environment";
import { NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { UserType } from "@models/enum/userType";
import { ManagementService } from "~/app/management/management.service";
import { Tenant } from "@models/tenant/tenant.model";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";
import { ProjectService } from "~/app/textToSpeech/project.service";

interface Headers {
  key: string;
  title: string;
  routerLink: string;
  accessibleTo: string;
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public userName: string;
  public tenantId: string;
  public defaultTenantName: string;
  public tenants: Tenant[];
  public formGroup: UntypedFormGroup;
  public showSelectTenant: boolean = false;

  isAdmin = false;
  headers: Headers[];
  subscription: Subscription;
  url: string;

  constructor(
    private dialog: MatDialog,
    private userHelperService: UserHelperService,
    private adminService: ManagementService,
    private auth: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private projectService: ProjectService,
    private router: Router
  ) {
    this.userHelperService.sharedUserName.subscribe(
      (username) => (this.userName = username)
    );
    this.userHelperService.sharedUserType.subscribe(
      (isAdmin) => (this.isAdmin = isAdmin)
    );
    this.userHelperService.sharedTenantId.subscribe(
      (tenantId) => (this.tenantId = tenantId)
    );
    this.headers = environment.headers.filter(
      (headers) => headers.accessibleTo === UserType.Regular
    );

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.auth.isAuthenticated.subscribe((isAuth) => {
      if (isAuth) {
        this.initFormGroup();
        this.getCurrentUserAccessibleTenants();
      }
    });

    if (!this.userName) {
      this.auth.getSetUserNameFromLocalStorage();
      if (this.userName) {
        this.adminService.getCurrentUserType().subscribe((data) => {
          if (data) {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
          this.userHelperService.setUserType(this.isAdmin);
        });
      }
    }

    if (!this.tenantId) {
      this.auth.getSetTenantIdFromLocalStorage();
    }
  }

  public getCurrentUserAccessibleTenants(): void {
    this.adminService.getTenantsByUser(0).subscribe((response: any) => {
      this.tenants = response;
      this.showSelectTenant = this.tenants.length > 1;
      const isCurrentExist = this.tenants
        .map((tenant) => tenant.id)
        .includes(this.tenantId);
      this.tenantId = isCurrentExist ? this.tenantId : this.tenants[0]?.id;
      if (this.tenantId) {
        this.formGroup.get("tenant").patchValue(this.tenantId);
      }
      this.formGroup.updateValueAndValidity();
      this.cdr.detectChanges();
    });
  }

  logout(): void {
    this.dialog.open(LogoutConfirmDialogComponent, {
      width: "690px",
      height: "430px",
    });
  }

  public initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      tenant: ["", Validators.required],
    });
  }

  IsSpaceBarPressed(e: any) {
    if (e.keyCode === 32) {
      // true, show popup
      this.logout();
    }
  }

  updateSessionTenant() {
    const selectedTenantId = this.formGroup.get("tenant").value;
    this.userHelperService.setTenantId(selectedTenantId);
    const session = this.auth.authenticationResponse;
    session.tenantId = selectedTenantId;
    this.auth.storeAuthenticationResponse(session);
    this.adminService.updateSessionTenantId(selectedTenantId).subscribe(() => {
      this.router.navigate(["/projects"]).then(() => {
        window.location.reload();
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
