import { WaveForm } from "@models/shared/waveForm.model";
import { Injectable } from "@angular/core";
import { ApiService } from "../shared/api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Asset, AssetSearchResponse } from "@models/asset/asset.model";

@Injectable({
  providedIn: "root",
})
export class AssetService {
  constructor(private api: ApiService, private http: HttpClient) {}

  getAll(filters: any): Observable<AssetSearchResponse> {
    return this.api.post("asset/getAll", filters);
  }

  getAsset(id: string): Observable<Asset> {
    return this.api.post("asset/getAsset", id);
  }

  public getTimeLinePeaks(assetId: string): Observable<WaveForm[]> {
    return this.api.get(`asset/getTimeLinePeaks/${assetId}`);
  }

  public deleteAsset(id: string): Observable<any> {
    return this.api.post("asset/manageAssetDeletionById", id);
  }
}
