import { Injectable } from "@angular/core";
import { Asset } from "@models/asset/asset.model";
import { PropertyCard } from "./components/propertiesCard/assetView.model";
import {
  PropertyView,
  DisplayType,
} from "app/shared/components/property-display/propertyView.model";
import { AbstractAssetViewService } from "~/app/shared/abstractAssetView.service";
import { AssetService } from "~/app/asset/asset.service";

@Injectable({ providedIn: "root" })
export class AssetViewService extends AbstractAssetViewService {
  constructor(protected assetService: AssetService) {
    super();
  }

  protected headGroup(asset: Asset): PropertyView[] {
    const group = [];

    group.push(
      new PropertyView("asset.duration", asset.duration, {
        displayType: DisplayType.Duration,
      }),
      new PropertyView("asset.standard", asset.standard),
      new PropertyView("asset.frameRate", asset.frameRate),
      new PropertyView("asset.screenAspectRatio", asset.screenAspectRatio, {
        displayType: DisplayType.Text,
      }),
      new PropertyView("asset.contentAspectRatio", asset.contentAspectRatio, {
        displayType: DisplayType.Text,
      })
    );
    return group;
  }

  private generalGroup(asset: Asset): PropertyCard {
    let uploadedBy = asset.createdBy;
    let contentType: string;
    if (asset.contentType) {
      if (/video/.test(asset.contentType)) {
        contentType = "video";
      } else if (/audio/.test(asset.contentType)) {
        contentType = "audio";
      } else {
        contentType = asset.contentType;
      }
    }

    const fileName = asset.fileName;

    return {
      titleTranslationKey: "asset.detailSection.general",
      propertyGroups: [
        [
          new PropertyView("asset.assetName", asset.title, {
            displayType: DisplayType.Text,
          }),
          new PropertyView("asset.fileName", fileName, {
            displayType: DisplayType.Text,
          }),

          new PropertyView("asset.fileSize", `${asset.fileSize} B`),
          new PropertyView("asset.uploadedBy", uploadedBy, {
            displayType: DisplayType.Text,
          }),
          new PropertyView("asset.createdOn", asset.createdAt, {
            displayType: DisplayType.DateTime,
          }),
          new PropertyView("asset.modifiedBy", asset.modifiedBy),
          new PropertyView("asset.modifiedDate", asset.updatedAt),
          new PropertyView("asset.contentType", contentType),
        ],
      ],
    };
  }

  // do not change this order: generalGroup must be positioned first in propertyCards[]]
  protected cards(asset: Asset): PropertyCard[] {
    const propertyCards: PropertyCard[] = [];

    // generalGroup remains 1st in propertyCards[] but with asset's class that might be 'Deep Storage'
    propertyCards.unshift(this.generalGroup(asset));
    propertyCards.push(this.videoGroup(asset));
    propertyCards.push(this.audioGroup(asset));
    return propertyCards;
  }

  private videoGroup(asset: Asset): PropertyCard {
    return {
      titleTranslationKey: "asset.detailSection.video",
      propertyGroups: [
        [
          new PropertyView(
            "asset.verticalResolution",
            asset.verticalResolution
          ),
          new PropertyView(
            "asset.horizontalResolution",
            asset.horizontalResolution
          ),
          new PropertyView("asset.videoSampleRate", asset.videoSampleRate),
        ],
      ],
    };
  }

  private audioGroup(asset: Asset): PropertyCard {
    return {
      titleTranslationKey: "asset.detailSection.audio",
      propertyGroups: [
        [
          new PropertyView("asset.sampleRate", asset.audioSampleRate),
          new PropertyView("asset.audioFileFormat", asset.audioFileFormat),
        ],
      ],
    };
  }

  public onAction(asset: Asset, action: string) {}
}
