import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AssetRoutingModule } from "./asset-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";

// import components here
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TextMaskModule } from "angular2-text-mask";
import { AssetComponent } from "./asset.component";
import { AssetDetailsComponent } from "./asset-details/asset-details.component";

@NgModule({
  imports: [
    AssetRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCardModule,
    MatInputModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatTableModule,
    MatSlideToggleModule,
    SharedModule,
    MatIconModule,
    TextMaskModule,
    MatAutocompleteModule,
  ],
  declarations: [AssetComponent, AssetDetailsComponent],
  providers: [],
})
export class AssetModule {}
