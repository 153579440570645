import {
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  FormGroup,
} from "@angular/forms";

export enum ValidatorLabels {
  Whitespace = "whitespace",
  NothingSelected = "nothingSelected",
  IsNotEqual = "isNotEqual",
  IsNotUnique = "isNotUnique",
  MinLength = "minLength",
  MixedCase = "mixedCase",
  HasNoNumber = "hasNoNumber",
  NullOrEmpty = "nullOrEmpty",
  SpecialChar = "specialChar",
  InOptions = "inOptions",
  OnlyAlphabets = "hasOnlyAlphabets",
}

export const EmailValidationRegexp =
  /^\s*[-a-zA-Z0-9_+%]+(?:\.[-a-zA-Z0-9_+%]+)*@\w+(?:[-.]\w+)*\.(?:[a-zA-Z]{2,})\s*$/;

export function isEqualTo(reference: string): ValidatorFn {
  return (control: UntypedFormControl) => {
    const isEqual =
      control.parent && control.parent.get(reference).value === control.value;
    return isEqual ? null : { [ValidatorLabels.IsNotEqual]: true };
  };
}

/**
 * Shorthand for Validators.Required && Validators.minLength
 */
export function strictMinLength(count: number): ValidatorFn {
  return (control: UntypedFormControl) => {
    const result = control.value.length >= count;
    return result
      ? null
      : {
          [ValidatorLabels.MinLength]: {
            requiredLength: count,
            actualLength: control.value.length,
          },
        };
  };
}

export function hasMixedCase(
  control: UntypedFormControl
): ValidationErrors | null {
  const hasLower = /[a-z]/.test(control.value);
  const hasUpper = /[A-Z]/.test(control.value);

  if (hasLower && hasUpper) {
    return null;
  }
  return { [ValidatorLabels.MixedCase]: { hasLower, hasUpper } };
}

export function hasSpecialChars(
  control: UntypedFormControl
): ValidationErrors | null {
  const valid = /[^a-zA-Z0-9]+/.test(control.value);
  return valid ? null : { [ValidatorLabels.SpecialChar]: true };
}

export function hasNumber(
  control: UntypedFormControl
): ValidationErrors | null {
  const valid = /[0-9]/.test(control.value);
  return valid ? null : { [ValidatorLabels.HasNoNumber]: true };
}

export function noWhitespaceValidator(control: UntypedFormControl) {
  const isWhitespace = (control.value || "").trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}

export function onlyAlphabets(
  control: UntypedFormControl
): ValidationErrors | null {
  const valid = /^[a-zA-Z \u00C0-\u00FF]*$/.test(control.value);
  return valid ? null : { [ValidatorLabels.OnlyAlphabets]: true };
}
