import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-fnx-tabs",
  templateUrl: "./fnx-tabs.component.html",
  styleUrls: ["./fnx-tabs.component.scss"],
})
export class FnxTabsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
