import { UserType } from "@models/enum/userType";
import { ProjectStatus } from "@models/textToSpeech/project.model";

export const appConfig = {
  production: false,
  error: {
    urlReport: "https://dev-api.difuzego.com/api/error", // might be somewhere else (meaning out of FNX rest api)
    sendReport: false,
    sendGoogleAnalytics: true,
    activatePageRedirection: true,
    whiteList: [
      "of undefined", // Example: "Cannot read property 'trim' of undefined\n at"
    ] /*
        blacklist: [
            'Player API', // Example: "Player API cannot be used after destroy()"
            'http://dev.phoenix.showcase.technicolor.com/phoenix/session' // provided by error.promise.__zone_symbol__value.url
        ]
        */,
  },
  account: {
    daysBeforeExpirationForWarning: 5,
  },
  headers: [
    {
      key: "project",
      title: "header.projects",
      routerLink: "/projects",
      accessibleTo: UserType.Regular,
    },
    {
      key: "videoDropbox",
      title: "header.videoDropbox",
      routerLink: "/videoAssets",
      accessibleTo: UserType.Regular,
    },
    {
      key: "lexicon",
      title: "header.lexicon",
      routerLink: "/lexicons",
      accessibleTo: UserType.Regular,
    },
  ],
  project: {
    nonDropFrameRates: [23, 30],
    uploadAttempts: {
      fileUplodAttempts: 5,
    },
    /* saving the dropdown configuration as key-value pair
        // if someone wants to add new language just add key-value pair to list[].
        // default language is configure using the, default property
        */
    languages: {
      list: [
        {
          key: "French",
          value: "fr",
        },
        {
          key: "English",
          value: "en",
        },
        {
          key: "Spanish",
          value: "es",
        },
        {
          key: "Portuguese",
          value: "pt",
        },
        {
          key: "German",
          value: "de",
        },
        {
          key: "Russian",
          value: "ru",
        },
      ],
      default: "fr",
    },
    /*region will be dependent on the language,
        means there will/could be multiple regions against the language,
        so for the regions list we will be having a key-value pair,
        the only difference will be, values will be an array of another key-value.*/
    regions: {
      list: [
        {
          key: "FR",
          value: [
            {
              key: "CA",
              value: "CA",
            },
            {
              key: "FR",
              value: "FR",
            },
            {
              key: "BE",
              value: "BE",
            },
            {
              key: "CH",
              value: "CH",
            },
          ],
        },
        {
          key: "EN",
          value: [
            {
              key: "CA",
              value: "CA",
            },
            {
              key: "US",
              value: "US",
            },
            {
              key: "GB",
              value: "GB",
            },
            {
              key: "AU",
              value: "AU",
            },
            {
              key: "NZ",
              value: "NZ",
            },
          ],
        },
        {
          key: "ES",
          value: [
            {
              key: "ES",
              value: "ES",
            },
            {
              key: "MX",
              value: "MX",
            },
          ],
        },
        {
          key: "PT",
          value: [
            {
              key: "PT",
              value: "PT",
            },
            {
              key: "BR",
              value: "BR",
            },
          ],
        },
        {
          key: "DE",
          value: [
            {
              key: "AT",
              value: "AT",
            },
            {
              key: "DE",
              value: "DE",
            },
            {
              key: "CH",
              value: "CH",
            },
          ],
        },
        {
          key: "RU",
          value: [
            {
              key: "RU",
              value: "RU",
            },
          ],
        },
      ],
    },
    // here only default speed rate value is configured as
    // the range wll be consistent (0.9 - 1.30)
    speedRate: {
      default: 1,
    },
    maxSearchResult: 10000,
    maximumSpeedRate: {
      default: 1.23,
    },
    lexicon: {
      alphabets: [
        {
          key: "IPA",
          value: "ipa",
        },
        {
          key: "SAPI",
          value: "sapi",
        },
        {
          key: "UPS",
          value: "ups",
        },
      ],
      graphemeExample: "appears in {projectName}",
    },
    wav: {
      searchView: [
        {
          isChecked: false,
          key: "duration",
          value: "Duration",
        },
        {
          isChecked: false,
          key: "processing",
          value: "Processing",
        },
        {
          isChecked: false,
          key: "error",
          value: "Error",
        },
      ],
    },
    maximumFileSize: 10000000000,
    authorizedFileExtensions: [".xml", ".wav"],
    projectNameLength: 150,
    nonEditableStatus: [
      ProjectStatus.SSMLCreation,
      ProjectStatus.SyntheticVoiceGeneration,
      ProjectStatus.AudioTrackCreation,
      ProjectStatus.Mixing,
      ProjectStatus.Assembling,
    ],
    projectWarningSilence: 3000, // in Sec (50min)
    defaultCreatedTimeFilter: "all", // in hours
    defaultProjectSorting: "creationDateTime",
    batchDeletionAllowed: 20, // no of records can be deleted in one go using batch delete feature
  },
  snackBarDurationLong: 3000, // with action, in milliseconds
  snackBarDurationShort: 1000, // without action
  audioPlaybackRates: [1, 1.25, 1.5, 1.75, 2],
  seekForward: 5, // jump x sec forward,
  seekBackward: 5, // jump x sec backward
  playerShortcutKeys: {
    playPauseKey: 32, // key code 32 -> space bar
    jumpBack: 37, // jump <seekBackward> seconds
    jumpForward: 39, //  jump <seekForward> seconds
    stepUp: 39, // right shift + rightArrow, change speedRate -> increase
    stepDown: 37, // left shift + leftArrow, change speedRate -> decrease
  },
  maxSearchResult: 10000,
  progressSpinnerDuration: 1000, // in ms,
  startRecordingAfterDuration: 1500, // in ms
  bitmovinKey: "c5126c9a-d0b5-41a4-b878-623b545dde50",
  maxRetake: 3,
  framesBetweenEvents: 55,
};
