import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from "@angular/core";
import { PropertyCard } from "./assetView.model";

@Component({
  selector: "properties-card",
  templateUrl: "./propertiesCard.component.html",
  styleUrls: ["./propertiesCard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertiesCardComponent {
  @Input() card: PropertyCard;
  @Output() action = new EventEmitter<string>();

  public get visible() {
    return (
      this.card.propertyGroups &&
      this.card.propertyGroups.some((group) => group.some((prop) => prop.value))
    );
  }

  public propagateAction(event: string) {
    this.action.next(event);
  }
}
