import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthenticationGuard } from "../shared/route-guards/authentication.guard";
import { AssetDetailsComponent } from "./asset-details/asset-details.component";
import { AssetComponent } from "./asset.component";

const routes: Routes = [
  {
    path: "",
    component: AssetComponent /*, canActivate, data*/,
  },
  {
    path: "details/:id",
    component: AssetDetailsComponent,
    canActivate: [AuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssetRoutingModule {}
