import { Component, HostListener } from "@angular/core";
import { environment } from "~/environments/environment";
import videojs from "video.js";
import { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import { VideoJSDataService } from "../video-jsdata.service";
// @ts-ignore
import "videojs-playlist";

@Component({
  selector: "app-video-js",
  templateUrl: "./video-js.component.html",
  styleUrls: ["./video-js.component.scss"],
})
export class VideoJSComponent implements OnInit, AfterViewInit, OnDestroy {
  public isVisible = false;
  // index to create unique ID for component
  public idx = "player";
  public audioSource = "";

  private config: any;
  private player: any;
  private playListDetails: any[] = [];
  private cachedTillIndex = 0;
  private capturedSpeedRate = 1;
  private currentItemIndex = 0;
  private isZerothReplyCleared = false;

  // constructor initializes our declared vars
  constructor(private videoJSService: VideoJSDataService) {
    this.videoJSService.sharedFileSource.subscribe((source) =>
      this.switchAudio(source)
    );
    this.videoJSService.isPlayerVisible.subscribe((isVisible) =>
      this.showPlayer(isVisible)
    );
    this.videoJSService.currentItem.subscribe((index) => {
      this.currentItemIndex = index;
    });
    this.videoJSService.playList.subscribe((playlist) => {
      if (playlist) {
        try {
          this.playPlaylist(playlist);
        } catch (error) {}
      }
    });
    this.player = false;

    // video.js configuration
    this.config = {
      controls: true,
      autoplay: false,
      width: 510,
      height: 31,
      playbackRates: environment.audioPlaybackRates,
      controlBar: {
        skipButtons: {
          forward: environment.seekForward,
          backward: environment.seekBackward,
        },
      },
    };
  }

  @HostListener("window:keydown", ["$event"])
  keyEvent(event: any) {
    if (
      event.shiftKey &&
      (event.keyCode === environment.playerShortcutKeys.stepUp ||
        event.keyCode === environment.playerShortcutKeys.stepDown)
    ) {
      if (
        event.target.nodeName === "DIV" ||
        event.target.nodeName === "INPUT"
      ) {
        return;
      }
      this.updateSpeedRate(event);
      return;
    }

    if (
      event.keyCode === environment.playerShortcutKeys.jumpForward ||
      event.keyCode === environment.playerShortcutKeys.jumpBack
    ) {
      if (
        event.target.nodeName === "DIV" ||
        event.target.nodeName === "INPUT"
      ) {
        return;
      }
      this.goForwardGoBackward(event);
    }

    if (
      this.player &&
      this.isVisible &&
      event.keyCode === environment.playerShortcutKeys.playPauseKey
    ) {
      if (
        event.target.nodeName === "DIV" ||
        event.target.nodeName === "INPUT"
      ) {
        return;
      }
      event.preventDefault();
      this.togglePlayPause();
    }
  }

  showPlayer(isVisible: boolean) {
    this.isVisible = isVisible;
    const el = "audio_" + this.idx;
    const element = document.getElementById(el);
    if (element) {
      if (isVisible) {
        element.style.visibility = isVisible ? "visible" : "hidden";
        element.style.display = "block";
      } else {
        if (this.player) {
          if (this.playListDetails && this.playListDetails.length) {
            this.removeReplyStyle(this.player.playlist.currentIndex(), false);
          }
          this.player.pause();
        }
        document
          .getElementById(el)
          .style.setProperty("display", "none", "important");
      }
    }
  }

  ngOnInit(): void {}

  // use ngAfterViewInit to make sure we initialize the videojs element
  // after the component template itself has been rendered
  ngAfterViewInit(): void {
    // temp fix
    setTimeout(() => {
      this.setupPlayer();
    }, 500);
  }

  setupPlayer() {
    // ID with which to access the template's audio element
    const el = "audio_" + this.idx;

    // setup the player via the unique element ID
    this.player = videojs(document.getElementById(el), this.config);

    document
      .getElementsByClassName("vjs-playback-rate")[0]
      .addEventListener("click", () => {
        this.getTheSpeedRateValue();
      });

    document.querySelectorAll(".vjs-menu-item").forEach((element) => {
      element.addEventListener("click", () => {
        this.getTheSpeedRateValue();
      });
    });

    this.player.on("ended", () => {
      const playControlButton: any =
        document.getElementsByClassName("vjs-play-control")[0];
      if (playControlButton) {
        playControlButton.classList.remove("vjs-ended");
      }

      if (
        this.player &&
        this.player.playlist() &&
        this.player.playlist().length > 0
      ) {
        const nextTrackIndex = this.player.playlist.nextIndex();
        if (nextTrackIndex <= this.cachedTillIndex) {
          this.cachedTillIndex = this.cachedTillIndex + 2;
          this.preloadTracks(
            this.playListDetails.slice(nextTrackIndex, this.cachedTillIndex)
          );
        }
      }
      this.player.pause();
    });

    /* will be responsible to change the background of the reply and persist the playback rate
    for the playlist
    */
    this.player.on("playlistitem", () => {
      this.player.play();
      const replyIndex = this.player.playlist.currentIndex();
      this.removeReplyStyle(replyIndex, true);
      this.addReplyStyle(replyIndex);
      if (
        replyIndex > 0 &&
        this.currentItemIndex > 0 &&
        !this.isZerothReplyCleared
      ) {
        // remove style of the 0th position reply
        this.removeReplyStyle(0, false);
        this.isZerothReplyCleared = true;
        console.clear();
      }
      this.player.playbackRate(this.capturedSpeedRate);
    });
  }

  removeButtons() {
    document
      .querySelectorAll(".vjs-seek-button")
      .forEach((uiElement) => uiElement.remove());
  }

  // use ngOnDestroy to detach event handlers and remove the player
  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
      this.player = false;
    }
  }

  // play individual replies
  switchAudio(source?: string) {
    if (this.player) {
      this.player.playbackRate(environment.audioPlaybackRates[0]);
      if (source) {
        this.isVisible = true;
        this.player.pause();
        if (this.playListDetails && this.playListDetails.length) {
          this.removeReplyStyle(this.player.playlist.currentIndex(), false);
        }
        this.audioSource = source;
        this.player.src({ src: this.audioSource, type: "audio/wav" });
        this.player.play();
        this.clearPlayList();
      } else {
        this.player.pause();
      }
    }
  }

  // clear playlist
  clearPlayList() {
    if (this.playListDetails.length) {
      this.playListDetails = [];
      this.player.playlist([]);
    }
  }

  // remove the styling applied to the reply
  removeReplyStyle(replyIndex: number, isPrevious: boolean) {
    replyIndex = isPrevious ? replyIndex - 1 : replyIndex;
    const reply = document.querySelector(`#reply${replyIndex}`);
    if (reply) {
      reply.classList.remove("playback");
    }
  }

  // add the styling to reply
  addReplyStyle(replyIndex: number) {
    document.querySelector(`#reply${replyIndex}`).classList.add("playback");
  }

  playPlaylist(playListData: any) {
    this.capturedSpeedRate = environment.audioPlaybackRates[0];
    // clean up the reply playback style
    const replyWithPlaybackStyle = document.querySelectorAll(`.playback`);
    if (replyWithPlaybackStyle) {
      replyWithPlaybackStyle.forEach((reply) =>
        reply.classList.remove("playback")
      );
    }
    this.player.playbackRate(this.capturedSpeedRate);
    if (this.isVisible) {
      this.player.pause();
    }
    this.clearPlayList();
    this.playListDetails = playListData;
    this.player.playlist(this.playListDetails);
    this.player.playlist.autoadvance(0);
    if (this.currentItemIndex > 0) {
      // play as playlist -> button against each reply
      this.player.playlist.currentItem(this.currentItemIndex);
      this.isZerothReplyCleared = false;
    }
    this.player.play().catch((error: any) => {
      console.log(error);
    });
    this.player.preload(true);
    // reload tracks in advance
    this.cachedTillIndex =
      this.currentItemIndex > 0 ? this.currentItemIndex + 2 : 2;
    this.preloadTracks(
      playListData.slice(this.currentItemIndex, this.cachedTillIndex)
    );
  }

  /* this method is responsible to load the tracks in advance,
     for smooth switching during the playlist playback
  */
  preloadTracks(playListData: any) {
    Promise.all(
      playListData.map((url: any) => {
        return new Promise((resolve) => {
          const audio = new Audio(url.sources[0].src);
          playListData.oncanplay = () => {
            resolve(playListData.sources);
          };
        });
      })
    ).then((data) => {
      data.reduce((promise: any) => {
        return promise.then(() => {
          return new Promise((resolve) => {
            this.player.onended = resolve;
          });
        });
      }, Promise.resolve());
    });
  }

  // shortcut, increase/decrease playbackSpeed
  updateSpeedRate(event: any) {
    if (event) {
      const isPlaying = !this.player.paused();
      if (isPlaying) {
        this.player.playbackRate(
          this.config.playbackRates[this.checkEventKeySourceLocation(event)]
        );
        this.getTheSpeedRateValue();
      }
    }
  }

  checkEventKeySourceLocation(event: any): number {
    if (
      environment.playerShortcutKeys.stepUp ===
      environment.playerShortcutKeys.stepDown
    ) {
      return this.getPlaybackRateIndex(event.location === 2);
    } else {
      if (event.keyCode === environment.playerShortcutKeys.stepUp) {
        return this.getPlaybackRateIndex(true);
      } else {
        return this.getPlaybackRateIndex(false);
      }
    }
  }

  // return the index, used for increasing/decreasing the  playback speed rate using shortcuts
  getPlaybackRateIndex(isStepUp: boolean): number {
    let index = this.config.playbackRates.indexOf(this.player.playbackRate());
    if (isStepUp) {
      if (index <= this.config.playbackRates.length - 1) {
        index += 1;
      }
    } else {
      if (index > 0) {
        index -= 1;
      }
    }
    return index;
  }

  // shortcut, jump <x> seconds
  goForwardGoBackward(event: any) {
    if (
      environment.playerShortcutKeys.jumpForward ===
      environment.playerShortcutKeys.jumpBack
    ) {
      this.changePlayerCurrentTime(event.location === 2);
    } else {
      if (event.keyCode === environment.playerShortcutKeys.jumpForward) {
        this.changePlayerCurrentTime(true);
      } else {
        this.changePlayerCurrentTime(false);
      }
    }
  }

  // change current time, using shortcuts
  changePlayerCurrentTime(isGoForward: boolean) {
    const isPlaying = !this.player.paused();
    if (isPlaying) {
      if (isGoForward) {
        this.player.currentTime(
          this.player.currentTime() + environment.seekForward
        );
      } else {
        this.player.currentTime(
          this.player.currentTime() - environment.seekBackward
        );
      }
    }
  }

  // play/pause track
  togglePlayPause() {
    if (this.player && this.isVisible) {
      !this.player.paused() ? this.player.pause() : this.player.play();
    }
  }

  getTheSpeedRateValue() {
    /* as soon as we change the speed-rate some event internally sets the value back to 1
    adding the timeout will help us to set/change the speed rate here .
    */
    setTimeout(() => {
      const speedRateStringWithX = document.getElementsByClassName(
        "vjs-playback-rate-value"
      )[0].innerHTML;
      const speedRate = +speedRateStringWithX.substring(
        0,
        speedRateStringWithX.length - 1
      );
      this.capturedSpeedRate = speedRate;
    }, 100);
  }
}
