export enum SearchType {
    Similar = 'similar',
    Contains = 'contains',
    BeginsWith = 'beginsWith',
}

export enum Sort {
    CreatedDate = 'createdDate',
    CreatedDateDesc = 'createdDateDesc',
    Alphabetical = 'alphabetical',
}

export enum StatesLibrary {
    All = 'All',
    Archived = 'Archived',
    Deleted = 'Deleted',
    Failed = 'Failed',
    Processing = 'Processing',
}

export enum ArchiveRequestStatus {
    All = 'All',
    Queued = 'Queued',
    Sent = 'Sent',
    InProgress = 'InProgress',
    Completed = 'Completed',
}

export enum Status {
    CreatingSignedUrl = 'creatingSignedUrl',
    New = 'new',
    Uploading = 'uploading',
    Warning = 'warning',
    StartTranscode = 'start-transcode',
    CheckTranscodeStatus = 'check-transcode-status',
    CreateRecord = 'create-record',
    Deleted = 'deleted',
    Canceled = 'canceled',
    ValidateChecksum = 'validate-checksum',
    Success = 'success',
    UploadFailed = 'uploadFailed',
    InProgress = 'inProgress',
    Completed = 'Completed',
}

export const processingStatus = [
    Status.CreateRecord,
    Status.ValidateChecksum,
    Status.StartTranscode,
    Status.CheckTranscodeStatus,
    Status.New,
];
