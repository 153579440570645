import { DecimalPipe } from "@angular/common";
import { PipeTransform, Pipe } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

const scales = ["", "K", "M", "G", "T", "P"]
  .map((letter, i) => ({
    letter,
    factor: Math.pow(1000, i),
  }))
  .reverse();

/**
 * Take a number + unit "1251000000 B" and output the proper formatted number, with prefix: "1.251 GB"
 */
@Pipe({
  name: "unit",
})
export class UnitPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
    private translate: TranslateService
  ) {}

  transform(value: string): string {
    const locale = this.translate ? this.translate.currentLang : "en";
    const [number, unit] = value.split(" ") as [number, string];
    const scale = this.scaleForNumber(number);
    if (!unit) {
      return number.toString();
    }
    return (
      this.decimalPipe.transform(number / scale.factor, "1.0-3", locale) +
      " " +
      scale.letter +
      unit
    );
  }

  scaleForNumber(value: number) {
    if (value < 1) {
      return scales[scales.length - 1];
    }
    return scales.find((scale) => scale.factor < value);
  }
}
