import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { newUuid } from "@utils/newUuid";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable()
export class ToolsService {
  constructor(
    protected snackBar: MatSnackBar,
    protected translate: TranslateService,
    protected router: Router
  ) {}

  newUuid(): string {
    return newUuid();
  }

  translatedSnackBar(
    message: string,
    action?: string,
    config?: any
  ): Observable<MatSnackBarRef<SimpleSnackBar>> {
    const keys = [message];
    if (action) {
      keys.push(action);
    }

    const defaultConfig = {
      duration:
        (action
          ? environment.snackBarDurationLong
          : environment.snackBarDurationShort) * 1000,
    };
    config = { ...defaultConfig, ...config };

    return this.translate.get(keys).pipe(
      map((translated) => {
        const finalMessage = translated[message].replace(
          "{0}",
          config.replaceValue
        );
        const snackBar = this.snackBar.open(
          finalMessage,
          translated[action],
          config
        );
        return snackBar;
      })
    );
  }
}
