import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Pagination, PaginationButton } from "@models/shared/action-list.model";
import { Project, ProjectStatus } from "@models/textToSpeech/project.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-new-pagination",
  templateUrl: "./new-pagination.component.html",
  styleUrls: ["./new-pagination.component.scss"],
})
export class NewPaginationComponent implements OnInit {
  @Input() pageSizeOptions: number[];
  @Input() totalRecords: number;
  @Input() totalPages: number;
  @Input() paginationInfo: Pagination;
  @Input() projectDeletionList: Project[];
  @Input() isFilesDownloadable: boolean;
  @Input() dataSource: any;
  @Input() deletionListOpened: boolean;
  @Output() page = new EventEmitter<Pagination>();
  @Output() bulkDownload = new EventEmitter();
  @Output() batchDelete = new EventEmitter();
  @Output() showProjectDeletionList = new EventEmitter();
  @Output() hideProjectDeletionList = new EventEmitter();
  public pageTitle = "";
  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  get showingData() {
    return (
      this.translate.instant("dashboard.showing") +
      this.paginationInfo.startIndex +
      " - " +
      this.paginationInfo.endIndex +
      " " +
      this.translate.instant("dashboard.of") +
      this.totalRecords
    );
  }

  selectionChange(event: any) {
    this.paginationInfo.from = 0;
    this.paginationInfo.pageSize = event.value;
    this.paginationInfo.startIndex = 1;
    this.paginationInfo.endIndex = event.value;
    this.toggleBtnColors(true, true, false, false);
    this.page.emit(this.paginationInfo);
  }

  goToFirstPage() {
    this.paginationInfo.startIndex = 1;
    this.paginationInfo.endIndex = this.paginationInfo.pageSize;
    this.paginationInfo.from = 0;
    this.toggleBtnColors(true, true, false, false);
    this.page.emit(this.paginationInfo);
  }

  goToLastPage() {
    this.toggleBtnColors(false, false, true, true);
    this.paginationInfo.startIndex =
      (this.totalPages - 1) * this.paginationInfo.pageSize + 1;
    this.paginationInfo.endIndex = this.totalRecords;
    this.paginationInfo.from =
      (this.totalPages - 1) * this.paginationInfo.pageSize;
    this.page.emit(this.paginationInfo);
  }

  goToNextPage() {
    this.paginationInfo.startIndex =
      this.paginationInfo.startIndex + this.paginationInfo.pageSize;
    this.paginationInfo.endIndex =
      this.paginationInfo.startIndex - 1 + this.paginationInfo.pageSize;
    this.paginationInfo.from = this.paginationInfo.startIndex - 1;
    if (this.paginationInfo.endIndex > this.totalRecords) {
      this.paginationInfo.endIndex = this.totalRecords;
    }
    if (this.paginationInfo.endIndex === this.totalRecords) {
      this.toggleBtnColors(false, false, true, true);
    } else {
      this.toggleBtnColors(false, false, false, false);
    }
    this.page.emit(this.paginationInfo);
  }

  goToPreviousPage() {
    this.paginationInfo.startIndex =
      this.paginationInfo.startIndex - this.paginationInfo.pageSize;
    this.paginationInfo.endIndex =
      this.paginationInfo.startIndex - 1 + this.paginationInfo.pageSize;
    this.paginationInfo.from = this.paginationInfo.startIndex - 1;
    if (this.paginationInfo.startIndex === 1) {
      this.toggleBtnColors(true, true, false, false);
    } else {
      this.toggleBtnColors(false, false, false, false);
    }
    this.page.emit(this.paginationInfo);
  }

  toggleBtnColors(first: boolean, back: boolean, next: boolean, last: boolean) {
    this.paginationInfo.isFirstDisabled = first;
    this.paginationInfo.isBackDisabled = back;
    this.paginationInfo.isNextDisabled = next;
    this.paginationInfo.isEndDisabled = last;
  }

  onArrowClick(key: string) {
    if (key === PaginationButton.First) {
      return !this.paginationInfo.isFirstDisabled && this.goToFirstPage();
    } else if (key === PaginationButton.Back) {
      return !this.paginationInfo.isBackDisabled && this.goToPreviousPage();
    } else if (key === PaginationButton.Next) {
      return !this.paginationInfo.isNextDisabled && this.goToNextPage();
    } else if (key === PaginationButton.Last) {
      return !this.paginationInfo.isEndDisabled && this.goToLastPage();
    }
  }

  onBulkDownload() {
    this.bulkDownload.emit();
  }

  onBatchDelete() {
    this.batchDelete.emit();
  }

  get isSelectedProjectDownloadable() {
    return this.projectDeletionList.length === 0
      ? false
      : this.projectDeletionList.some(
          (project) => project.status === ProjectStatus.ReadyToDownload
        );
  }

  onShowProjectDeletionList() {
    this.showProjectDeletionList.emit();
  }

  onHideProjectDeletionList() {
    this.hideProjectDeletionList.emit();
  }
}
