export class AudioAnalyzer {
  private audioContext: AudioContext;
  private analyserNode: AnalyserNode;
  private microphoneNode: MediaStreamAudioSourceNode;
  private dataArray: Uint8Array;
  private intervalId: any;
  private decibelQuality: string;
  private stream: any;

  constructor() {
    // Initialize AudioContext and AnalyserNode
    this.audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    this.analyserNode = this.audioContext.createAnalyser();
    this.analyserNode.fftSize = 256; // You can adjust the FFT size based on your requirements
    this.dataArray = new Uint8Array(this.analyserNode.frequencyBinCount);
    this.decibelQuality = "";
  }

  public listenDecibels() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.stream = stream;
        this.startAudioProcessing(stream);
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  private handleError(error: any) {
    console.error("Error:", error);
  }

  private startAudioProcessing(stream: MediaStream) {
    try {
      this.microphoneNode = this.audioContext.createMediaStreamSource(stream);
      this.microphoneNode.connect(this.analyserNode);
      this.measureDecibels();
    } catch (error) {
      this.handleError(error);
    }
  }

  private measureDecibels() {
    this.intervalId = setInterval(() => {
      this.analyserNode.getByteFrequencyData(this.dataArray);
      const sum = this.dataArray.reduce((acc, value) => acc + value, 0);
      const average = sum / this.dataArray.length;
      const dBFS = 20 * Math.log10(average / 255);
      this.decibelQuality = this.classifyDecibel(dBFS);
    }, 800);
  }

  public stopListening() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    this.decibelQuality = "";
    if (this.microphoneNode) {
      this.microphoneNode.disconnect();
    }

    this.stream?.getTracks().forEach((track: any) => track.stop());
    this.analyserNode.disconnect();

    // Stop the audio context and close the microphone stream
    if (this.audioContext.state === "running") {
      this.audioContext.close().then();
    }
  }

  private classifyDecibel(audioLevel: number): string {
    const isInRange = (value: number, lower: number, upper: number) =>
      value >= lower && value <= upper;
    const isInOpenInterval = (value: number, lower: number, upper: number) =>
      value > lower && value < upper;
    if (isInRange(audioLevel, -20, -12)) {
      return "good"; // Green: All good
    }
    if (
      isInOpenInterval(audioLevel, -12, -7) ||
      isInOpenInterval(audioLevel, -25, -20)
    ) {
      return "ok"; // Yellow: Voice is either too loud or not loud enough
    }
    if (audioLevel <= -25 || audioLevel >= -7) {
      return "bad"; // Red: Voice is barely audible or way too loud
    }
    return "bad"; // default to red color
  }

  public getDecibelQualitySignal(): string {
    return this.decibelQuality;
  }
}
