// import modules
import { AppRoutingModule } from "./app-routing.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms"; // <-- #1 import module
import { TranslateModule } from "@ngx-translate/core"; // https://github.com/ngx-translate/core

// import components
import { AppComponent } from "./app.component";

// translation specific
import { TranslateLoader } from "@ngx-translate/core"; // https://github.com/ngx-translate/core
import { WebpackTranslateLoader } from "../webpack-translate.loader";
import { TranslateManagerService } from "./shared/translateManager.service";
import { SignInComponent } from "./account/sign-in/sign-in.component";
import { SignUpComponent } from "./account/sign-up/sign-up.component";
import { ApiService } from "./shared/api.service";
import { AccountService } from "./account/account.service";
import { ToolsService } from "./shared/tools.service";
import { I18nPluralPipe } from "@angular/common";
import { ForgetPasswordComponent } from "./account/forget-password/forget-password.component";
import { ResetPasswordComponent } from "./account/reset-password/reset-password.component";
import { ForgetPasswordConfirmationComponent } from "./account/forget-password/forget-password-confirmation/forget-password-confirmation.component";
import { ProjectDialogConfirmComponent } from "./textToSpeech/project-dialog-confirm/project-dialog-confirm.component";
import { PasswordExpireWarningComponent } from "./account/password-expire-warning/password-expire-warning.component";
import { DialogChangePasswordComponent } from "./account/dialog-change-password/dialog-change-password.component";
import { SharedModule } from "./shared/shared.module";
import { DiscardProjectConfirmationComponent } from "./textToSpeech/pages/discard-project-confirmation/discard-project-confirmation.component";
import { TextToSpeechModule } from "./textToSpeech/textToSpeech.module";
import { DialogConfirmWithAlertComponent } from "./shared/components/dialog-confirm-with-alert/dialog-confirm-with-alert.component";
import { VersionService } from "./shared/version.service";
import { HttpClientModule } from "@angular/common/http";
import { DialogUploadFileComponent } from "./shared/components/dialog-upload-file/dialog-upload-file.component";
import { DialogConfirmReplaceComponent } from "./shared/components/dialog-confirm-replace/dialog-confirm-replace.component";
import { LogoutConfirmDialogComponent } from "./shared/header/logout-confirm-dialog/logout-confirm-dialog.component";
import { AssetModule } from "./asset/asset.module";
import { DialogPlayerComponent } from "./shared/components/dialog-player/dialog-player.component";
import { DialogConfirmCancelDeleteComponent } from "./shared/components/dialog-confirm-cancel-delete/dialog-confirm-cancel-delete.component";
import { DialogShortcutComponent } from "./shared/components/dialog-shortcut/dialog-shortcut.component";
// import services

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSidenavModule,
    MatDialogModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatSelectModule,
    MatMenuModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    SharedModule,
    TextToSpeechModule,
    AssetModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: () => new WebpackTranslateLoader(),
      },
    }),
  ],
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    ForgetPasswordConfirmationComponent,
    DialogPlayerComponent,
    DialogConfirmCancelDeleteComponent,
    ProjectDialogConfirmComponent,
    PasswordExpireWarningComponent,
    DialogChangePasswordComponent,
    DiscardProjectConfirmationComponent,
    DialogConfirmWithAlertComponent,
    DialogUploadFileComponent,
    DialogConfirmReplaceComponent,
    DialogShortcutComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    TranslateModule,
    ApiService,
    AccountService,
    ToolsService,
    I18nPluralPipe,
    MatSnackBarModule,
    VersionService,
  ],
  exports: [AppComponent],
  entryComponents: [
    DialogPlayerComponent,
    DialogConfirmCancelDeleteComponent,
    DialogChangePasswordComponent,
    ProjectDialogConfirmComponent,
    LogoutConfirmDialogComponent,
    DiscardProjectConfirmationComponent,
    DialogConfirmWithAlertComponent,
    DialogUploadFileComponent,
    DialogConfirmReplaceComponent,
    DialogShortcutComponent,
  ],
})
export class AppModule {
  constructor(
    translateManager: TranslateManagerService // Required for translate initialization
  ) {
    translateManager.initializeLanguage();
  }
}
