import { Asset } from '@models/asset/asset.model';
import { Project } from '@models/textToSpeech/project.model';

export function getTimeInSecondsFromMillis(timeCode: string): number {
    let hours: string, minutes: string, seconds: string, millis: string;

    const splitTimeCode = timeCode.split(':');

    hours = splitTimeCode[0];
    minutes = splitTimeCode[1];
    [seconds, millis] = splitTimeCode[2].split('.');

    const timeInSeconds = Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds) + Number(millis) * 0.001;

    return timeInSeconds;
}

export function getTimeInSecondsFromFrameRate(timeCode: string, frameRate: number): number {
    let hours: string, minutes: string, seconds: string, frames: string;

    [hours, minutes, seconds, frames] = timeCode.split(':');

    const timeInSeconds = Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds) + (parseFloat(frames) * 1) / frameRate;

    return timeInSeconds;
}

export function matchWordOccurrences(ttmlSegmentData: string, searchString: string): string[] {
    let matchedWords: any;

    if (!ttmlSegmentData || !searchString) {
        return matchedWords;
    }

    const regex = RegExp('(?<![-0-9A-Za-zÀ-ÿœēčŭ])' + searchString + '(?![-0-9A-Za-zÀ-ÿœēčŭ])', 'g');
    matchedWords = ttmlSegmentData.match(regex);

    return matchedWords;
}

export async function parseXML(parsedTTML: Document) {
    const pTags = parsedTTML.getElementsByTagName('p');
    const errorLineNumber: number[] = [];
    for (let i = 0; i < pTags.length; i++) {
        const nodeLength = pTags[i].childNodes.length;
        for (let siblings = 0; siblings < nodeLength; siblings++) {
            const node: any = pTags[i].childNodes[siblings];
            if (node.nodeValue && node.nodeValue.indexOf('&') >= 0) {
                errorLineNumber.push(node.lineNumber);
            }
        }
    }
    if (errorLineNumber.length) {
        const lineNumbers = errorLineNumber.length === 1 ? errorLineNumber : [...new Set(errorLineNumber)].join();
        throw new Error('Prohibited character(s) used at line number: ' + lineNumbers);
    }
}

export function splitTextIntoLines(text: string, maxLength: number): string[] {
  const words = text.split(' ');
  const lines: string[] = [];
  let currentLine = '';

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if ((currentLine + ' ' + word).trim().length <= maxLength) {
      currentLine = (currentLine.length > 0 ? currentLine + ' ' : '') + word;
    } else {
      lines.push(currentLine.trim());
      currentLine = word;
    }
  }

  if (currentLine.length > 0) {
    lines.push(currentLine.trim());
  }

  return lines;
}

export function timeToSeconds(timeStr: string): number | string {
    try {
      const timeArr = timeStr.split(':').map(Number);
      if (timeArr.length === 2) {
        const [minutes, seconds] = timeArr;
        const totalSeconds = minutes * 60 + seconds;
        return totalSeconds;
      } else if (timeArr.length === 3) {
        const [hours, minutes, seconds] = timeArr;
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        return totalSeconds;
      } else {
        throw new Error(
          'Invalid time format. Please use "hh:mm:ss" or "mm:ss".'
        );
      }
    } catch (error) {
      return 'Invalid time format. Please use "hh:mm:ss" or "mm:ss".';
    }
  }
export function getTimeCodeDifference(tcIn: string, tcOut: string, frameRate: number): string {
    // Splitting timecodes into components
    const tcInParts: number[] = tcIn.split(':').map(Number);
    const tcOutParts: number[] = tcOut.split(':').map(Number);

    // Calculating the difference for each component
    let framesDiff: number = tcOutParts[3] - tcInParts[3];
    let secondsDiff: number = tcOutParts[2] - tcInParts[2];
    let minutesDiff: number = tcOutParts[1] - tcInParts[1];
    let hoursDiff: number = tcOutParts[0] - tcInParts[0];

    // Handling borrow/carry-over
    if (framesDiff < 0) {
        framesDiff += frameRate; // Assuming 30 frames per second
        secondsDiff--; // Borrow 1 second
    }

    if (secondsDiff < 0) {
        secondsDiff += 60;
        minutesDiff--; // Borrow 1 minute
    }

    if (minutesDiff < 0) {
        minutesDiff += 60;
        hoursDiff--; // Borrow 1 hour
    }

    // Format the output
    const result = `${padWithZero(hoursDiff, 2)}:${padWithZero(minutesDiff, 2)}:${padWithZero(secondsDiff, 2)}:${padWithZero(framesDiff, 2)}`;
    return result;
}

function padWithZero(value: number, width: number): string {
    const paddedValue = value.toString().padStart(width, '0');
    return paddedValue;
}

export function getFrames(timeCode: string, frameRate: number = 30): number {
    const [hours, minutes, seconds, frames] = timeCode.split(':').map(Number);
    const totalFrames = (hours * 3600 + minutes * 60 + seconds) * frameRate + frames;
    return totalFrames;
}

// mm:ss -> hh:mm:ss:ff
export function convertTimeToFrames(time: string, frameRate: number): string {
    const [minutesStr, secondsStr] = time.split(':');
    const minutes = parseInt(minutesStr, 10);
    const seconds = parseInt(secondsStr, 10);

    const totalSeconds = minutes * 60 + seconds;
    const totalFrames = Math.floor(totalSeconds * frameRate);

    const hours = Math.floor(totalFrames / (frameRate * 3600));
    const remainingSeconds = totalFrames % (frameRate * 3600);
    const minutesConverted = Math.floor(remainingSeconds / (frameRate * 60));
    const secondsConverted = Math.floor((remainingSeconds % (frameRate * 60)) / frameRate);
    const frames = Math.floor(totalFrames % frameRate);

    return `${String(hours).padStart(2, '0')}:${String(minutesConverted).padStart(2, '0')}:${String(secondsConverted).padStart(2, '0')}:${String(frames).padStart(2, '0')}`;
}

export function convertTimeToFramesAdjusted(time: string, frameRate: number, startTimecode: number): string {
  const [minutesStr, secondsStr] = time.split(/[:;]/);
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  const playerSeconds = minutes * 60 + seconds;
  const playerFrames = Math.floor(playerSeconds * frameRate);
  const startTimecodeFrames = Math.floor(startTimecode * frameRate);
  const totalFrames = Math.floor(playerFrames + startTimecodeFrames);

  const hours = Math.floor(totalFrames / (frameRate * 3600));
  const remainingSeconds = totalFrames % (frameRate * 3600);
  const minutesConverted = Math.floor(remainingSeconds / (frameRate * 60));
  const secondsConverted = Math.floor((remainingSeconds % (frameRate * 60)) / frameRate);
  const frames = Math.floor(totalFrames % frameRate);

  return `${String(hours).padStart(2, '0')}:${String(minutesConverted).padStart(2, '0')}:${String(secondsConverted).padStart(2, '0')}:${String(frames).padStart(2, '0')}`;
}

// xx.xx -> hh:mm:ss:ff
export function secondsTimeToFrames(totalSeconds: number, frameRate: number): string {
  const totalFrames = Math.floor(totalSeconds * frameRate);
  const hours = Math.floor(totalFrames / (frameRate * 3600));
  const remainingSeconds = totalFrames % (frameRate * 3600);
  const minutesConverted = Math.floor(remainingSeconds / (frameRate * 60));
  const secondsConverted = Math.floor((remainingSeconds % (frameRate * 60)) / frameRate);
  const frames = Math.floor(totalFrames % frameRate);

  return `${String(hours).padStart(2, '0')}:${String(minutesConverted).padStart(2, '0')}:${String(secondsConverted).padStart(2, '0')}:${String(frames).padStart(2, '0')}`;
}

export function secondsTimeToFramesNoFloor(totalSeconds: number, frameRate: number): string {
  const totalFrames = totalSeconds * frameRate;
  const hours = Math.floor(totalFrames / (frameRate * 3600));
  const remainingSeconds = totalFrames % (frameRate * 3600);
  const minutesConverted = Math.floor(remainingSeconds / (frameRate * 60));
  const secondsConverted = Math.floor((remainingSeconds % (frameRate * 60)) / frameRate);
  const frames = Math.floor(totalFrames % frameRate);

  return `${String(hours).padStart(2, '0')}:${String(minutesConverted).padStart(2, '0')}:${String(secondsConverted).padStart(2, '0')}:${String(frames).padStart(2, '0')}`;
}

export function secondsTimeToFramesStartTimeCode(seconds: any, asset: Asset, project: Project) {
  let startTimeCode = project.startTimeCode !== '00:00:00:00'
    ? project.startTimeCode
    : asset.startTimeCode
      ? asset.startTimeCode
      : '00:00:00:00';
  startTimeCode = startTimeCode.replace(';', ':');
  if (startTimeCode) {
    seconds = seconds + getTimeInSecondsFromFrameRate(startTimeCode, Number(asset.frameRate));
  }
  return secondsTimeToFramesNoFloor(seconds, Number(asset.frameRate));
}

export function bufferToWave(abuffer: AudioBuffer, len: number) {
    const numOfChan = abuffer.numberOfChannels;
    const length = len * numOfChan * 2 + 44;
    const buffer = new ArrayBuffer(length);
    const view = new DataView(buffer);
    const channels: any[] = [];
    let i;
    let sample;
    let offset = 0;
    let pos = 0;

    // write WAVE header
    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(abuffer.sampleRate);
    setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2); // block-align
    setUint16(16); // 16-bit (hardcoded in this demo)

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // chunk length

    // write interleaved data
    for (i = 0; i < abuffer.numberOfChannels; i++) {
      channels.push(abuffer.getChannelData(i));
    }

    while (pos < length) {
      for (i = 0; i < numOfChan; i++) {
        // interleave channels
        sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
        sample = 0.5 + sample < 0 ? sample * 32768 : sample * 32767; // scale to 16-bit signed int
        view.setInt16(pos, sample, true); // write 16-bit sample
        pos += 2;
      }
      offset++; // next source sample
    }

    // create Blob
    return new Blob([buffer], { type: 'audio/wav' });

    function setUint16(data: any) {
      view.setUint16(pos, data, true);
      pos += 2;
    }

    function setUint32(data: any) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
}

export function calculateMinuteDifference(startDate: Date, endDate: Date): string {
    if (!startDate || !endDate) {
        return '';
    }
    const differenceInMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    if (differenceInMinutes < 1) {
        return 'scriptWriting.savedJustNow';
    } else {
        const roundedMinutes = Math.floor(differenceInMinutes);
        return `Saved ${roundedMinutes} min${roundedMinutes !== 1 ? 's' : ''} ago`;
    }
}
