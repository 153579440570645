import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-discard-project-confirmation",
  templateUrl: "./discard-project-confirmation.component.html",
  styleUrls: ["./discard-project-confirmation.component.scss"],
})
export class DiscardProjectConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DiscardProjectConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  noClick(): void {
    this.dialogRef.close();
  }
}
