import { Component, Inject } from "@angular/core";
import { ProjectService } from "../project.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-project-dialog-confirm",
  templateUrl: "./project-dialog-confirm.component.html",
  styleUrls: ["./project-dialog-confirm.component.scss"],
})
export class ProjectDialogConfirmComponent {
  disableDeleteButton = false;
  projectNames: string[] = [];
  spinner = false;
  constructor(
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<ProjectDialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.projectData.isBatchDelete) {
      this.projectNames = data.projectData.names.split(",");
    }
  }

  noClick(): void {
    this.dialogRef.close();
  }

  async delete(uniqueProjectName: string): Promise<void> {
    this.spinner = true;
    this.disableDeleteButton = true;
    if (!this.data.projectData.isBatchDelete) {
      this.projectService
        .delete(uniqueProjectName)
        .toPromise()
        .then(() => {
          this.dialogRef.close(this.disableDeleteButton);
        })
        .catch(() => {
          this.spinner = false;
          this.disableDeleteButton = false;
        });
    } else {
      await this.projectService
        .batchDelete(uniqueProjectName)
        .toPromise()
        .then(() => {
          this.dialogRef.close(this.disableDeleteButton);
        })
        .catch(() => {
          this.spinner = false;
          this.disableDeleteButton = false;
        });
    }
  }
}
