import { ReplaySubject, Observable } from "rxjs";

export interface ReadOnlyReactiveProperty<T> extends Observable<T> {
  readonly value: T;
}

/**
 * @author David Racine <dracine@lvlstudio.com>;
 * Partial Javascript implementation of UniRX ReactiveProperty version
 */
export class ReactiveProperty<T>
  extends ReplaySubject<T>
  implements ReadOnlyReactiveProperty<T>
{
  private _value: T;

  get value(): T {
    return this._value;
  }

  set value(v: T) {
    if (this.value === v) {
      return;
    }

    this._value = v;

    if (this.isStopped) {
      return;
    }

    this.next(v);
  }

  constructor(initialValue: T) {
    super(1);
    this.value = initialValue;
  }
}
