import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ListItemAction } from "./action-list-item.model";

@Component({
  selector: "app-action-list-item",
  templateUrl: "./action-list-item.component.html",
  styleUrls: ["./action-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionListItemComponent {
  @Input() actions: ListItemAction;
  @Input() expanded: boolean;
  @Input() showSpinner: boolean;
  @Input() isSelected: boolean;
  @Output() actionSelected = new EventEmitter<ListItemAction>();

  constructor() {}

  onClickAction(e: any, action: ListItemAction): void {
    if (action.icon === "unlink") {
      action.disabled = true;
    }
    e.stopPropagation();

    if (action.callback) {
      action.callback();
    }

    this.actionSelected.emit(action);
  }
}
